<template>
  <div>
    <div v-if="data.color" :style="styleObject" class="item-badge" />
    {{ data.text }}
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class ColorItemTemplate extends Vue {
  @Prop()
  public itemData!: any;

  public data: any = {};
  public styleObject = {};

  public mounted() {
    this.data = this.itemData;
    this.styleObject = { backgroundColor: this.data.color };
  }
}
</script>
<style lang="scss">
.item-badge {
  text-align: center;
  float: left;
  margin-right: 12px;
  color: white;
  width: 18px;
  height: 18px;
  font-size: 19.5px;
  border-radius: 18px;
  margin-top: 2px;
}
</style>
