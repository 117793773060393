<template>
  <div
    :class="{
      'task-error': appointment.appointmentData.taskError,
      'color-1':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 1,
      'color-2':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 2,
      'color-3':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 3,
      'color-4':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 4,
      'color-5':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 5,
      'color-6':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 6,
      'color-7':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 7,
      'color-8':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 8,
      'color-9':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 9,
      'color-10':
        !appointment.appointmentData.taskError && appointment.appointmentData.colorCode === 10
    }"
  >
    <div class="appointment-text">
      {{ appointment.appointmentData.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    appointmentModel: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {}
    }
  },
  data() {
    return {
      appointment: this.appointmentModel,
      appointmentStyle: 'background-color: yellow;'
    };
  }
};
</script>
<style lang="scss">
.task-error {
  background-color: #e80202 !important;
}
.color-1 {
  background-color: #2b6b9c;
}
.color-2 {
  background-color: #5c8fb7;
}
.color-3 {
  background-color: #117010;
}
.color-4 {
  background-color: #469e45;
}
.color-5 {
  background-color: #694a06;
}
.color-6 {
  background-color: #94732c;
}
.color-7 {
  color: #000000;
  background-color: #ffee00;
}
.color-8 {
  color: #000000;
  background-color: #faf5b4;
}
.color-9 {
  background-color: #d000ff;
}
.color-10 {
  background-color: #e15cff;
}
</style>
