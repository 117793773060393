<template>
  <div v-if="isMounted">
    <DxPopup
      :visible="true"
      :drag-enabled="false"
      :hide-on-outside-click="false"
      :show-close-button="false"
      :show-title="true"
      :width="600"
      height="650px"
      :title="title"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm">
            <GenericForm :model="formModel"></GenericForm>
          </div>
          <div class="col-sm">
            <div class="dip-input form-group">
              <label>Mitarbeiterfilter</label>
              <p><small> wird in Übersicht/Gantt-Darstellung ignoriert</small></p>
            </div>
            <DxScrollView height="425px">
              <DxTreeView
                id="treeview"
                :ref="treeViewRef"
                :items="dataFilter.filterEntries"
                :show-check-boxes-mode="'normal'"
                :selection-mode="'multiple'"
                :select-nodes-recursive="true"
                :select-by-click="true"
              >
                <template #item="item">
                  {{ item.data.name }}
                </template>
              </DxTreeView>
            </DxScrollView>
          </div>
        </div>
      </div>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        :options="closeButtonOptions"
      />
    </DxPopup>
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import DxTreeView from 'devextreme-vue/tree-view';
import DxList from 'devextreme-vue/list';
import DxSelectBox from 'devextreme-vue/select-box';
import DxCheckBox from 'devextreme-vue/check-box';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import ToolkitButton from '@/components/buttons/ToolkitButton.vue';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
import ResourcePlanningModel from '@/models/resourcePlanning/ResourcePlanningModel';
import { EmployeeDto } from '@/models/resourcePlanning/dtos/EmployeeDto';
import FilterEntry from '@/models/FilterEntry';
import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import GenericForm from '@/components/genericForm/GenericForm.vue';
import DataFilterDto from '@/models/resourcePlanning/dtos/DataFilterDto';
import ITextValuePair from '@/components/genericForm/ITextValuePair';

@Component({
  components: {
    DxTreeView,
    DxList,
    DxSelectBox,
    DxCheckBox,
    DxPopup,
    GenericForm,
    ToolkitButton,
    DxToolbarItem,
    DxScrollView
  }
})
export default class FilterDialog extends Vue {
  public title = 'Filter';

  public treeViewRef = 'treeView';

  public isMounted = false;
  public model: ModelRoot = this.$model;
  public resourcePlanning: ResourcePlanningModel = this.$model.resourcePlanning;

  public formModel: IGenericFormModel = {
    getData: () => this.getFormData(),
    entries: [
      {
        label: {
          name: 'Von'
        },
        propertyName: 'from',
        type: 'date'
      },
      {
        label: {
          name: 'Bis'
        },
        propertyName: 'to',
        type: 'date'
      },
      {
        label: {
          name: 'Projekt Owner'
        },
        propertyName: 'owner',
        type: 'singleSelection',
        options: {
          items: (): ITextValuePair[] => {
            var departments = this.resourcePlanning.departments.map((d) => {
              return { value: d.departmentId, text: d.name } as ITextValuePair;
            });
            departments.unshift({ value: '', text: 'Alle' } as ITextValuePair);
            return departments;
          }
        }
      }
    ]
  };

  public closeButtonOptions = {
    text: 'Anwenden',
    onClick: () => this.doApplyFilter()
  };

  public dataFilter: DataFilterDto = {} as DataFilterDto;
  private originalDataFilterString = '';

  @Emit('hide')
  public emitHide(): void {
    // empty
  }

  @Emit('filter-changed')
  public emitFilterChanged(): void {
    // empty
  }

  public getFormData(): Record<string, unknown> {
    const formData: Record<string, unknown> = {
      from: this.dataFilter.from,
      to: this.dataFilter.to,
      owner: this.dataFilter.owner
    };
    return formData;
  }

  mounted(): void {
    this.originalDataFilterString = this.resourcePlanning.filterService.getDataFilterString() ?? '';
    this.UpdateItems();
    this.isMounted = true;
  }

  private UpdateItems(): void {
    const employeesWithDepartment: EmployeeDto[] = this.resourcePlanning.employees.filter(
      (e) => e.department != undefined
    );

    const departments = new Map<string, FilterEntry>();
    const departmentShortNames: string[] = [];
    for (const employee of employeesWithDepartment) {
      const departmentFilterEntryId = 'd' + (employee.department?.departmentId ?? 0);
      const departmentShortName: string = employee.department?.shortName ?? 'UNKNOWN-DEPARTMENT';
      if (!departments.has(departmentShortName)) {
        departmentShortNames.push(departmentShortName);
        departments.set(departmentShortName, {
          name: departmentShortName,
          expanded: true,
          id: '' + departmentFilterEntryId,
          items: [],
          selected: false
        });
      }

      const departmentFilterEntry = departments.get(departmentShortName);
      departmentFilterEntry?.items?.push({
        id: 'e' + employee.employeeId,
        name: employee.name,
        expanded: true,
        items: [],
        selected: false
      });
    }

    this.dataFilter.filterEntries = [];
    for (const key of departmentShortNames.sort()) {
      const entry: FilterEntry | undefined = departments.get(key);
      if (entry) {
        this.dataFilter.filterEntries.push(entry);
      }
    }

    const savedDataFilter = this.resourcePlanning.filterService.getDataFilter();
    this.dataFilter.from = savedDataFilter.from;
    this.dataFilter.to = savedDataFilter.to;
    this.dataFilter.owner = savedDataFilter.owner;

    for (const savedEntry of savedDataFilter.filterEntries) {
      const actualEntry = this.dataFilter.filterEntries.find((e) => e.id === savedEntry.id);
      if (actualEntry == undefined) {
        continue;
      }
      actualEntry.selected = savedEntry.selected;
      actualEntry.expanded = savedEntry.expanded;

      if (savedEntry.selected && actualEntry.items.length > 0) {
        for (const child of actualEntry.items) {
          child.selected = true;
        }
      } else {
        for (const savedChild of savedEntry.items) {
          const actualChild = actualEntry.items.find((e) => e.id === savedChild.id);
          if (actualChild == undefined) {
            continue;
          }
          actualChild.selected = savedChild.selected;
          actualChild.expanded = savedChild.expanded;
        }
      }
    }
  }

  private doApplyFilter(): void {
    this.dataFilter.from = (this.formModel.result as Record<string, unknown>).from as Date;
    this.dataFilter.to = (this.formModel.result as Record<string, unknown>).to as Date;
    this.dataFilter.owner = (this.formModel.result as Record<string, unknown>).owner as string;
    this.resourcePlanning.filterService.setDataFilter(this.dataFilter);

    this.emitHide();

    const actualDataFilterString = this.resourcePlanning.filterService.getDataFilterString() ?? '';
    if (this.originalDataFilterString !== actualDataFilterString) {
      this.emitFilterChanged();
    }
  }
}
</script>
<style lang="sass"></style>
