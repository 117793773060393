<template>
  <div class="container">
    <div class="my-2">
      <smart-table
        ref="standbydutySmartTable"
        :config="config"
        :selected.sync="model"
        language="de"
        small
        :reloadDataOnUpdate="true"
        responsive
        striped
        :tableId="department.replace(/ /g, '-')"
      />
    </div>
    <div class="text-right my-2">
      <slot name="slot1"></slot>
      <slot name="slot2"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';
import { SmartTable, Config, Column, Sorting, PagingOptions, Filter } from 'rey-vue-smarttable';
import { StandbyDutyAssignmentStatus } from '@/models/StandbyDutyAssignmentStatus';
import StandbyDutyEmployeeDto from '@/models/StandbyDutyEmployeeDto';
import DropdownOption from '@/models/DropdownOption';
import moment from 'moment';

export default mixins(VModelMixin).extend({
  name: 'standbyduty-table',
  components: { SmartTable },
  props: { department: String },
  data() {
    return {
      employees: [] as DropdownOption[],
      config: new Config(
        (x) => x.id,
        [
          new Column({
            title: 'KW',
            fieldName: 'weekNo',
            fieldType: 'numeric',
            editable: () => false
          }),
          new Column({
            title: 'Start',
            fieldName: 'startDate',
            fieldType: 'string',
            editable: () => false,
            converter: (x) => {
              return moment(x).utcOffset(x).format('DD.MM.YYYY');
            }
          }),
          new Column({
            title: 'Ende',
            fieldName: 'endDate',
            fieldType: 'string',
            editable: () => false,
            converter: (x) => {
              return moment(x).utcOffset(x).format('DD.MM.YYYY');
            }
          }),
          new Column({
            title: 'Status',
            fieldName: 'status',
            fieldType: 'numeric',
            editable: () => false,
            converter: (cellValue) => {
              if (cellValue === StandbyDutyAssignmentStatus.Open) {
                return 'Offen';
              } else if (cellValue === StandbyDutyAssignmentStatus.Reserved) {
                return 'Reserviert';
              } else if (cellValue === StandbyDutyAssignmentStatus.Fixed) {
                return 'Fixiert';
              } else {
                return 'Mitarbeiterwechsel';
              }
            },
            filterConverter: (cellValue) => {
              if (cellValue === 'Offen') {
                return [StandbyDutyAssignmentStatus.Open];
              } else if (cellValue === 'Reserviert') {
                return [StandbyDutyAssignmentStatus.Reserved];
              } else if (cellValue === 'Fixiert') {
                return [StandbyDutyAssignmentStatus.Fixed];
              } else {
                return [StandbyDutyAssignmentStatus.EmployeeChange];
              }
            },
            cellStyling: (cell) => ({
              'text-center': cell.status === StandbyDutyAssignmentStatus.Open,
              'bg-warning text-center': cell.status === StandbyDutyAssignmentStatus.Reserved,
              'bg-success text-white text-center':
                cell.status === StandbyDutyAssignmentStatus.Fixed,
              'bg-warning text-black text-center':
                cell.status === StandbyDutyAssignmentStatus.EmployeeChange
            })
          }),
          new Column({
            title: 'Pikettverantwortlicher',
            fieldName: 'currentEmployeeName',
            fieldType: 'string',
            editable: () => false
          }),
          new Column({
            title: 'Geändert am',
            fieldName: 'updatedAt',
            fieldType: 'string',
            editable: () => false,
            converter: (x) => new Date(x).toLocaleString()
          }),
          new Column({
            title: 'Geändert von',
            fieldName: 'updatedBy',
            fieldType: 'string',
            editable: () => false
          })
        ],
        new Sorting('startDate'),
        new PagingOptions(52)
      ).withEfApiActions(this.$reyDipApi, 'StandbyDutyAssignment')
    };
  },
  created() {
    this.loadEmployees();
    this.addDependentColumns();
    this.config.permanentFilters.push({
      fieldName: 'department',
      fieldType: 'string',
      filterType: 'equals',
      filterQuery: new Array<string>(this.department)
    } as Filter);
  },
  methods: {
    loadEmployees(): void {
      this.$reyDipApi.get('/api/standbydutyassignment/employees').then((res) => {
        res.data.forEach((element: StandbyDutyEmployeeDto) => {
          this.employees.push({
            id: element.graphUserId,
            title: element.displayName
          } as DropdownOption);
        });
      });
    },
    addDependentColumns(): void {
      this.config.columns?.splice(
        this.config.columns.length - 2,
        0,
        new Column({
          title: 'Angefragte Person',
          fieldName: 'requestedEmployeeId',
          fieldType: 'dropdown',
          editable: () => true,
          dropdownOptions: this.employees
        })
      );
    }
  }
});
</script>
<style lang="scss">
.table-active > td,
.table-active:hover {
  background-color: #a8d3e8;
  color: black;
}
.text-white {
  color: #fff !important;
}
</style>
