<template>
  <div>
    <div v-if="visible && formModelLoaded">
      <DxPopup
        :visible="true"
        :drag-enabled="false"
        :hide-on-outside-click="false"
        :show-close-button="false"
        :show-title="true"
        :width="width"
        :height="height"
        :title="title"
      >
        <div>
          <GenericForm :model="formModel"></GenericForm>
        </div>

        <table class="table table-borderless">
          <tbody>
            <tr>
              <td align="left">
                <div class="d-flex flex-row justify-content-start">
                  <div v-if="page.onPrevious != undefined" class="px-1">
                    <ToolkitButton :action="previousButtonAction"></ToolkitButton>
                  </div>
                </div>
              </td>
              <td align="center">
                <div class="d-flex flex-row justify-content-center">
                  <div v-if="page.onYes != undefined" class="px-1">
                    <ToolkitButton :action="yesButtonAction"></ToolkitButton>
                  </div>
                  <div v-if="page.onNo != undefined" class="px-1">
                    <ToolkitButton :action="noButtonAction"></ToolkitButton>
                  </div>
                </div>
              </td>
              <td align="right">
                <div class="d-flex flex-row justify-content-end">
                  <div v-if="page.onCancel != undefined" class="px-1">
                    <ToolkitButton :action="cancelButtonAction"></ToolkitButton>
                  </div>
                  <div v-if="page.onSave != undefined" class="px-1">
                    <ToolkitButton :action="saveButtonAction"></ToolkitButton>
                  </div>
                  <div v-if="page.onNext != undefined" class="px-1">
                    <ToolkitButton :action="nextButtonAction"></ToolkitButton>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </DxPopup>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import GenericForm from '../genericForm/GenericForm.vue';
import { IToolkitCommand } from '../buttons/IToolkitCommand';
import ToolkitButton from '../buttons/ToolkitButton.vue';
import { IGenericWizardModel, IGenericWizardPage } from './IGenericWizardModel';
import { IGenericFormModel } from './IGenericFormModel';

@Component({
  components: {
    DxPopup,
    DxToolbarItem,
    GenericForm,
    ToolkitButton
  }
})
export default class GenericWizardDialog extends Vue {
  @Prop() visible!: boolean;
  @Prop() model!: IGenericWizardModel;

  public pageIndex = 0;
  public page: IGenericWizardPage = {} as IGenericWizardPage;
  public formModel: IGenericFormModel = {} as IGenericFormModel;
  public formModelLoaded = false;
  private data: Record<string, unknown> = {} as Record<string, unknown>;

  @Watch('visible')
  public onVisibleChanged(): void {
    this.formModelLoaded = false;
    if (this.visible) {
      const ret: Record<string, unknown> | Promise<Record<string, unknown>> | undefined =
        this.model.getData();
      if (ret != undefined && (ret as Promise<Record<string, unknown>>).finally != undefined) {
        (ret as Promise<Record<string, unknown>>).then((data) => {
          this.data = data;
          this.updateFormModel(0);
        });
      } else {
        this.data = ret as Record<string, unknown>;
        this.updateFormModel(0);
      }
    } else {
      this.data = {} as Record<string, unknown>;
    }
  }

  public cancelButtonAction: IToolkitCommand = {
    name: 'Abbrechen',
    disabled: () => false,
    group: 'root',
    execute: () => this.onCancel()
  };

  private onCancel(): void | Promise<void> {
    this.model.result = this.formModel.result;
    if (this.page.onCancel) {
      return this.page.onCancel(this.model);
    }
  }

  public saveButtonAction: IToolkitCommand = {
    name: 'Speichern',
    disabled: () => false,
    group: 'root',
    execute: () => this.onSave()
  };

  private onSave(): void | Promise<void> {
    this.model.result = this.formModel.result;
    if (this.page.onSave) {
      return this.page.onSave(this.model);
    }
  }

  public yesButtonAction: IToolkitCommand = {
    name: 'Ja',
    disabled: () => false,
    group: 'root',
    execute: () => this.onYes()
  };

  private onYes(): void | Promise<void> {
    this.model.result = this.formModel.result;
    if (this.page.onYes) {
      return this.page.onYes(this.model);
    }
  }

  public noButtonAction: IToolkitCommand = {
    name: 'Nein',
    disabled: () => false,
    group: 'root',
    execute: () => this.onNo()
  };

  private onNo(): void | Promise<void> {
    this.model.result = this.formModel.result;
    if (this.page.onNo) {
      return this.page.onNo(this.model);
    }
  }

  public previousButtonAction: IToolkitCommand = {
    name: 'Zurück',
    icon: 'fas fa-caret-left',
    iconPosition: 'left',
    disabled: () => false,
    group: 'root',
    execute: () => {
      this.onPrevious();
    }
  };

  private onPrevious(): void | Promise<void> {
    if (this.page.onPrevious) {
      const ret: number | Promise<number> = this.page.onPrevious(this.model, this.pageIndex);
      this.updateFormModel(ret);
    }
  }

  public nextButtonAction: IToolkitCommand = {
    name: 'Weiter',
    icon: 'fas fa-caret-right',
    iconPosition: 'right',
    disabled: () => false,
    group: 'root',
    execute: () => {
      this.onNext();
    }
  };
  private onNext(): void | Promise<void> {
    if (this.page.onNext) {
      const ret: number | Promise<number> = this.page.onNext(this.model, this.pageIndex);
      this.updateFormModel(ret);
    }
  }

  private updateFormModel(ret: number | Promise<number>): void {
    if (ret != undefined && (ret as Promise<number>).finally != undefined) {
      (ret as Promise<number>).then((index) => {
        this.pageIndex = index;
        let formModelData = this.data;
        if (this.formModel.result) {
          formModelData = this.formModel.result as Record<string, unknown>;
          this.model.result = this.formModel.result;
        }

        this.formModel = this.createFormModel(this.pageIndex, formModelData);
        this.formModelLoaded = true;
      });
    } else {
      this.pageIndex = ret as number;
      let formModelData = this.data;
      if (this.formModel.result) {
        formModelData = this.formModel.result as Record<string, unknown>;
        this.model.result = this.formModel.result;
      }
      this.formModel = this.createFormModel(this.pageIndex, formModelData);
      this.formModelLoaded = true;
    }
  }

  private createFormModel(index: number, data: Record<string, unknown>): IGenericFormModel {
    this.page = this.model.pages[index];
    return {
      getData: () => data,
      entries: this.page.entries,
      onCancel: () => {
        if (this.page.onCancel) {
          this.page.onCancel(this.model);
        }
      },
      onSave: () => {
        if (this.page.onSave) {
          this.page.onSave(this.model);
        }
      },
      onPrevious: () => {
        if (this.page.onPrevious) {
          this.page.onPrevious(this.model, this.pageIndex);
        }
      },
      onNext: () => {
        if (this.page.onNext) {
          this.page.onNext(this.model, this.pageIndex);
        }
      },
      onYes: () => {
        if (this.page.onYes) {
          this.page.onYes(this.model);
        }
      },
      onNo: () => {
        if (this.page.onNo) {
          this.page.onNo(this.model);
        }
      },
      options: this.model.options
    } as IGenericFormModel;
  }

  public get title(): string | undefined {
    if (this.page.name) {
      return this.page.name;
    }
    if (this.model.options && this.model.options.title) {
      return this.model.options.title;
    }
    return undefined;
  }

  public get width(): number | undefined {
    if (this.model.options && this.model.options.width) {
      return this.model.options.width;
    }
    return undefined;
  }

  public get height(): number | undefined {
    if (this.model.options && this.model.options.height) {
      return this.model.options.height;
    }
    return undefined;
  }
}
</script>
<style lang="scss">
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}
</style>
