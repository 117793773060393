<template>
  <div>
    <div v-if="resourcePlanning.hasSelectedActivity">
      <GenericForm :model="formModel"></GenericForm>
      <ToolkitButton :action="saveButtonModel"></ToolkitButton>
    </div>
  </div>
</template>
<script lang="ts">
import { IToolkitCommand } from '@/components/buttons/IToolkitCommand';
import ToolkitButton from '@/components/buttons/ToolkitButton.vue';
import GenericForm from '@/components/genericForm/GenericForm.vue';
import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import { ToolkitCommandResultType } from '@/components/sharedModels/ICommand';
import { SubjectDto } from '@/models/resourcePlanning/dtos/SubjectDto';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
import ResourcePlanningModel from '@/models/resourcePlanning/ResourcePlanningModel';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: { ToolkitButton, GenericForm }
})
export default class ActivityDetails extends Vue {
  public model: ModelRoot = this.$model;
  public resourcePlanning: ResourcePlanningModel = this.$model.resourcePlanning;
  public formModel: IGenericFormModel = {
    getData: () => this.getFormData(),
    entries: [
      {
        type: 'text',
        propertyName: 'name',
        disabled: true,
        label: {
          name: 'Name'
        },
        options: {
          width: '100%'
        }
      },
      {
        type: 'date',
        propertyName: 'beginDate',
        label: {
          name: 'Start'
        }
      },
      {
        type: 'date',
        propertyName: 'endDate',
        label: {
          name: 'Ende'
        }
      },
      {
        type: 'text',
        propertyName: 'comment',
        label: {
          name: 'Kommentar'
        },
        options: {
          multiline: true,
          height: 60,
          width: '100%'
        }
      }
    ]
  };

  public saveButtonModel: IToolkitCommand = {
    name: 'Speichern',
    group: 'root',
    execute: () => this.onSave()
  };

  getFormData(): Record<string, unknown> | Promise<Record<string, unknown>> | undefined {
    return this.resourcePlanning.selectedActivity;
  }

  public onSave(): ToolkitCommandResultType {
    if (!this.formModel.result) {
      return;
    }

    let resultSubject: SubjectDto = this.formModel.result as SubjectDto;
    return this.resourcePlanning.subjectService
      .saveSubject({
        origin: this.resourcePlanning.selectedActivity,
        update: resultSubject
      })
      .then(async () => {
        await this.resourcePlanning.timelineModel.reloadTimelineData();
        const plainResult: any = this.formModel.result;
        const mappedTask: { id: string; title: string; start: Date; end: Date; treeId: string } = {
          id: plainResult.subjectId,
          title: plainResult.name,
          start: new Date(plainResult.beginDate),
          end: new Date(plainResult.endDate),
          treeId: plainResult.treeId
        };
        this.$emit('onActivitySaved', mappedTask);
      })
      .catch((error) => {
        console.warn('nidi(error 5) Write Activity to Database failed!', error);
      });
  }
}
</script>
<style lang="scss" scoped>
.readonly-field {
  color: #000000;
  background-color: #eeeeee;
  border: solid 1px #dddddd;
  border-radius: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
