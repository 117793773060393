<template>
  <div id="scheduler">
    <DxScheduler
      ref="timelineScheduler"
      :time-zone="timeZone"
      :min="minAvailableDate"
      :max="maxAvaliableDate"
      maxAppointmentsPerCell="unlimited"
      :views="timelineConfiguration.views"
      :data-source="timelineModel.tasks"
      :current-date="timelineConfiguration.currentDate"
      :groups="groups"
      height="100%"
      :show-all-day-panel="true"
      :first-day-of-week="1"
      :start-day-hour="startDayHour"
      :end-day-hour="endDayHour"
      :cell-duration="cellDurationMinutes"
      :focus-state-enabled="true"
      :cross-scrolling-enabled="true"
      :current-view="currentView"
      data-cell-template="dataCellTemplate"
      date-cell-template="dateCellTemplate"
      resource-cell-template="resourceCellTemplate"
      appointment-template="appointmentTemplate"
      :on-appointment-context-menu="onAppointmentContextMenu"
      @option-changed="onOptionChanged($event)"
      @appointment-updated="onAppointmentUpdated($event)"
      @appointment-click="onAppointmentClicked($event)"
    >
      <DxStateStoring :enabled="true" type="localStorage" storage-key="timeLineStorage" />
      <DxResource
        :data-source="timelineModel.resources"
        :allow-multiple="false"
        :label="resourceLabel"
        :field-expr="taskRessourceIdKey"
      />

      <template #appointmentTemplate="{ data }">
        <AppointmentTemplate :appointment-model="data" />
      </template>

      <template #resourceCellTemplate="{ data: employee }">
        <ResourceCell :resource="employee" />
      </template>

      <template #dataCellTemplate="{ data: cellData }">
        <DataCell :cell-data="cellData" />
      </template>

      <template #dateCellTemplate="{ data: dateCellData }">
        <DateCell :cell-data="dateCellData" />
      </template>
    </DxScheduler>
    <DxContextMenu
      :items="contextMenuItems"
      :width="200"
      :disabled="contextMenuDisabled"
      :target="contextMenuTarget"
      :on-item-click="onContextMenuItemClick"
      item-template="itemTemplateSlot"
    >
      <template #itemTemplateSlot="{ data: itemData }">
        <ColorItemTemplate :item-data="itemData" />
      </template>
    </DxContextMenu>
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { DxStateStoring } from 'devextreme-vue/data-grid';
import { DxScheduler, DxResource, DxView } from 'devextreme-vue/scheduler';
import AppointmentTemplate from './AppointmentTemplate.vue';
import DataCell from './DataCell.vue';
import DateCell from './DateCell.vue';
import ResourceCell from './ResourceCell.vue';
import ITimelineModel from './ITimelineModel';
import ITimelineConfiguration from './ITimelineConfiguration';
import DxContextMenu from 'devextreme-vue/context-menu';
import ColorItemTemplate from './ColorItemTemplate.vue';
import IContextMenuItem from '@/models/resourcePlanning/contexMenu/IContextMenuItem';
import TimelineTaskDto from '@/models/resourcePlanning/timeline/TimelineTaskDto';

@Component({
  components: {
    DxScheduler,
    DxResource,
    DxContextMenu,
    DxStateStoring,
    ColorItemTemplate,
    DataCell,
    DateCell,
    ResourceCell,
    AppointmentTemplate
  }
})
export default class TimelineWidget extends Vue {
  @Prop()
  public selectedTask!: TimelineTaskDto;

  @Prop()
  public widgetId!: string;

  @Prop()
  public timelineModel!: ITimelineModel;

  @Prop()
  public appointmentContextMenuItems!: IContextMenuItem[];

  @Prop()
  public timelineConfiguration!: ITimelineConfiguration;
  public get taskRessourceIdKey(): string | number {
    if (this.timelineConfiguration.taskRessourceIdKey != undefined) {
      return this.timelineConfiguration.taskRessourceIdKey;
    }
    return 'id';
  }

  public contextMenuDisabled = false;
  public contextMenuTarget = '.dx-scheduler-appointment';
  public contextMenuItems: IContextMenuItem[] = [];
  targetedAppointmentData: any = {};

  @Emit('task-changed')
  public emitTaskChanged(event: any): any {
    return event;
  }

  @Emit('task-selected')
  public emitTaskSelected(event: any): any {
    return event;
  }

  @Watch('selectedTask')
  onSelectedTaskUpdated(e: any) {
    setTimeout(() => {
      this.refresh();
      this.repaint();
    }, 100);
  }

  public onAppointmentContextMenu(event: any): void {
    this.contextMenuItems = this.appointmentContextMenuItems;
    this.targetedAppointmentData = event.targetedAppointmentData;
  }

  public onContextMenuItemClick(event: any) {
    event.itemData.onItemClick({ targetedAppointmentData: this.targetedAppointmentData, event });
  }

  public mounted(): void {
    const dxScheduler: DxScheduler = this.$refs['timelineScheduler'] as DxScheduler;
    dxScheduler.instance?.hideAppointmentTooltip();
    this.resetCurrentDate();
  }

  private resetCurrentDate(): void {
    let lastCurrentDateStr: string = this.$toolkitModel.webStorageService.getString(
      this.currentDateKey,
      new Date().toISOString()
    );
    if (lastCurrentDateStr.indexOf('nvalid') > 0 || lastCurrentDateStr.length === 0) {
      lastCurrentDateStr = new Date().toISOString();
    }
    const lastCurrentDate: Date = new Date(lastCurrentDateStr);
    this.timelineConfiguration.currentDate = lastCurrentDate;
  }

  public refresh(): void {
    const dxScheduler: DxScheduler = this.$refs['timelineScheduler'] as DxScheduler;
    dxScheduler.instance?.getDataSource().reload();
  }

  public repaint(): void {
    const dxScheduler: DxScheduler = this.$refs['timelineScheduler'] as DxScheduler;
    dxScheduler.instance?.repaint();
  }

  public get resourceLabel(): string {
    return this.timelineConfiguration.resourceLabel ?? 'Resource';
  }
  public get currentView(): string {
    return this.timelineConfiguration.currentView ?? 'timelineMonth';
  }
  public currentViewIntervalCount(): number {
    return this.timelineConfiguration.views.find((x) => x.name === this.currentView).intervalCount;
  }

  public get timeZone(): string {
    return this.timelineConfiguration.timeZone ?? 'Europe/Zurich';
  }

  public get startDayHour(): number {
    return this.timelineConfiguration.startDayHour ?? 6;
  }

  public get endDayHour(): number {
    return this.timelineConfiguration.startDayHour ?? 18;
  }

  public get cellDurationMinutes(): number {
    return this.timelineConfiguration.cellDurationMinutes ?? 720;
  }

  public get minAvailableDate(): Date {
    if (this.timelineConfiguration.minAvailableDate) {
      return this.timelineConfiguration.minAvailableDate;
    }
    const defaultDate = new Date();
    defaultDate.setMonth(0);
    defaultDate.setDate(1);
    return defaultDate;
  }

  public get maxAvaliableDate(): Date {
    if (this.timelineConfiguration.maxAvailableDate) {
      return this.timelineConfiguration.maxAvailableDate;
    }
    const defaultDate = new Date();
    defaultDate.setMonth(11);
    defaultDate.setDate(31);
    return defaultDate;
  }

  public get groups(): string[] {
    let key;
    if (this.timelineConfiguration.taskRessourceIdKey != undefined) {
      key = this.timelineConfiguration.taskRessourceIdKey;
    } else {
      key = 'id';
    }

    let theGroups: string[] = [key];
    if (this.timelineConfiguration.groups) {
      for (let entry in this.timelineConfiguration.groups) {
        if (theGroups.indexOf(entry) < 0) {
          theGroups.push(entry);
        }
      }
    }
    return theGroups;
  }

  public onOptionChanged(event: {
    name: string;
    value: Date;
    previousValue: Date;
    component: any;
  }): void {
    switch (event.name) {
      case 'currentDate':
        var selectedValue = event.value.getMonth();
        var previousValue = event.previousValue.getMonth();
        var newCurrentDate = new Date(event.previousValue);
        var displayedRange = this.currentViewIntervalCount();

        if (
          Math.abs(selectedValue - previousValue) === displayedRange ||
          Math.abs(selectedValue - previousValue) === 12 - displayedRange
        ) {
          var monthsToShift = event.value > event.previousValue ? 1 : -1;
          newCurrentDate.setMonth(event.previousValue.getMonth() + monthsToShift);
          event.component.option('currentDate', newCurrentDate);
        }
        this.saveCurrentDate(event);
        break;
      case 'currentView':
        this.persistCurrentView(event);
        setTimeout(() => {
          this.refresh();
          this.repaint();
        }, 100);
        break;
      default:
        break;
    }
  }

  private saveCurrentDate(event: any) {
    const currentDate = event.value.toISOString();
    this.$toolkitModel.webStorageService.put(this.currentDateKey, currentDate);
  }

  public get currentDateKey(): string {
    return `TimeLineWidget-${this.widgetId}-currentDate`;
  }

  public persistCurrentView(e: any) {
    this.timelineConfiguration.currentView = e.value;
    localStorage.setItem('timelineCurrentView', e.value);
  }

  public onAppointmentUpdated(event: any): void {
    this.emitTaskChanged(event);
  }

  public async onAppointmentClicked(event: any) {
    this.emitTaskSelected(event);
  }
}
</script>
<style lang="scss">
#scheduler {
  .dx-scheduler-group-header,
  .dx-scheduler-date-table-cell {
    position: relative;
  }

  .dx-scheduler.dx-widget.dx-visibility-change-handler {
    min-height: 400px;
  }

  .dx-scheduler-date-table-other-month {
    &.dx-scheduler-date-table-cell {
      opacity: 1;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .dx-scheduler-date-table-cell {
    .dx-template-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      padding-right: 6px;
    }
  }

  .dx-scheduler-header-panel-container {
    max-width: 100%;
    .dx-scheduler-header-tables-container {
      min-width: calc(100% - 220px) !important;
    }
    table.dx-scheduler-header-panel {
      width: auto !important;
    }
  }
  .dx-scrollable-wrapper {
    .dx-scrollable-container {
      max-width: 100%;
      .dx-scrollable-content {
        max-width: 100%;
        .dx-scheduler-date-table-scrollable-content {
          width: 100% !important;
          .dx-scheduler-date-table-container {
            width: 100% !important;
            .dx-scheduler-date-table {
              width: 100% !important;
              min-height: 620px;
            }
          }
        }
        .dx-scheduler-group-table {
          min-height: 620px;
        }
      }
    }
  }

  /* https://supportcenter.devexpress.com/ticket/details/t576709/scheduler-adjust-resource-header-width */
  .dx-scheduler-cell-sizes-horizontal {
    min-width: 1px !important;
    width: 56px !important;
    max-width: 56px !important;
    font-size: 7pt;
  }
  .dx-scheduler-appointment {
    &.dx-scheduler-appointment-horizontal {
      min-height: 27px;
      max-height: 27px;
    }
    .dx-scheduler-appointment-content {
      padding: 2px 7px;
      border-top: 2px solid #fff;
    }
  }
  .dx-scheduler-group-row {
    min-height: 400px;
    .dx-scheduler-group-header {
      min-width: 220px;
      min-height: 103.4px;
    }
  }
  .dx-scheduler-group-flex-container,
  .dx-scheduler-work-space-vertical-group-table,
  .dx-scheduler-sidebar-scrollable {
    flex: 0 0 auto;
  }
}
</style>
