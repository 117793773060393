import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import { EmployeeDto } from '../dtos/EmployeeDto';
import { AssignmentDto } from '../dtos/AssignmentDto';
import ResourcePlanning from '../ResourcePlanningModel';
import IDialog from './IDialog';
import { SubjectDto } from '../dtos/SubjectDto';
import { NumericRule } from 'devextreme/ui/validation_rules';
import { NgModuleFactoryLoader } from '@angular/core';
import ITextValuePair from '@/components/genericForm/ITextValuePair';

export default class AddTimelineAssignmentDialog implements IDialog {
  public readonly resourcePlanning: ResourcePlanning;

  private employees: EmployeeDto[] = [] as EmployeeDto[];
  private projects: SubjectDto[] = [] as SubjectDto[];

  public model: IGenericFormModel = {
    options: {
      title: 'Buchung hinzufügen'
    },
    getData: () => this.getData(),
    entries: [
      {
        type: 'singleSelection',
        propertyName: 'name',
        label: {
          name: 'Name'
        },
        options: {
          items: () =>
            this.employees.map((e) => {
              return { value: e.name, text: e.name } as ITextValuePair;
            })
        }
      },
      {
        propertyName: 'projectName',
        type: 'singleSelection',
        label: {
          name: 'Projekt'
        },
        options: {
          items: () =>
            this.projects.map((e) => {
              return { value: e.name, text: e.name } as ITextValuePair;
            }),
          width: '100%'
        }
      },
      {
        propertyName: 'comment',
        type: 'text',
        label: {
          name: 'Kommentar'
        },
        options: {
          multiline: true,
          width: '400'
        }
      }
    ],
    onSave: (m) => this.onSave(m),
    onCancel: () => this.hide()
  };

  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }

  public async show(): Promise<void> {
    this.employees = await this.resourcePlanning.employeeService.getEmployees();
    this.projects = await this.resourcePlanning.subjectService.getProjects();
    this.resourcePlanning.dialogModel = this.model;
    this.resourcePlanning.showDialog = true;
  }

  getData(): Record<string, unknown> {
    const dataRecord = {} as Record<string, unknown>;
    dataRecord['name'] = '';
    dataRecord['projectName'] = '';
    dataRecord['comment'] = '';
    return dataRecord;
  }

  private async onSave(model: IGenericFormModel): Promise<void> {
    const formData = model.result as Record<string, unknown>;
    const employee: EmployeeDto | undefined = this.employees.find((p) => p.name === formData.name);
    const project: SubjectDto | undefined = this.projects.find(
      (p) => p.name === formData.projectName
    );

    const activity: SubjectDto | undefined =
      await this.resourcePlanning.subjectService.getProjectActivity(
        project?.subjectId,
        employee?.departmentId
      );
    const activityBeginDate: Date = activity?.beginDate ? new Date(activity.beginDate) : new Date();
    const activityEndDate: Date = activity?.endDate ? new Date(activity.endDate) : new Date();

    const activityId: number = (activity?.subjectId as number) ?? 0;

    const comment = formData.comment as string;

    const start: Date = activityBeginDate;
    const end: Date = activityEndDate;

    const pensum = 100;

    const assignment: AssignmentDto = {
      beginDate: start,
      endDate: end,
      employeeId: employee?.employeeId ?? 0,
      assignmentId: 0,
      subjectId: activityId,
      comment: comment,
      pensum: pensum,
      isInvalid: false
    };

    const addedAssignment: AssignmentDto =
      await this.resourcePlanning.employeeService.postAssignment(assignment);
    //this.resourcePlanning.employeeService.addAssignmentToGannt(addedAssignment);
    this.hide();
    await this.resourcePlanning.timelineModel.reloadTimelineData();
    this.resourcePlanning.timelineModel.eventRefereshTimeline.notify(true);
  }

  public hide(): void {
    this.resourcePlanning.showDialog = false;
  }
}
