<template>
  <content-card title="Archiv Alle MA" cardIcon="fas fa-archive">
    <template v-slot:body>
      <vacation-table pageSection="ExecutiveArchive" />
    </template>
  </content-card>
</template>
<script lang="ts">
import Vue from 'vue';
import { ContentCard } from 'rey-smartmes-ui';
import VacationTable from '@/components/VacationTable.vue';

export default Vue.extend({
  name: 'executive-archive',
  components: { ContentCard, VacationTable }
});
</script>
