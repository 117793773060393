export function encodeBase64Url(plain: string): string {
  return window.btoa(plain).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/m, '');
}

export function decodeBase64Url(base64UrlEndoced: string): string {
  let input = base64UrlEndoced;
  input = input.replace(/-/g, '+').replace(/_/g, '/');

  // Pad out with standard base64 required padding characters
  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('Illegal base64url string length.');
    }
    input += new Array(5 - pad).join('=');
  }

  return input;
}
