<template>
  <div>
    <DxDateBox
      type="date"
      :disabled="model.disabled"
      :use-mask-behavior="true"
      display-format="dd.MM.yyyy"
      :width="getString(model.options, 'width', '200')"
      :value.sync="content.value"
    ></DxDateBox>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GenericFormItem } from '../genericForm/GenericFormItem';
import { IValueContainer } from '../genericForm/ValueContainer';
import { getString } from '../genericForm/OptionsAccessors';
import DxDateBox from 'devextreme-vue/date-box';

@Component({
  components: {
    DxDateBox
  }
})
export default class DateBox extends Vue {
  @Prop()
  public model!: GenericFormItem;
  @Prop()
  public content!: IValueContainer;

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    return getString(options, name, defaultValue);
  }
}
</script>
