import Logger from '@/services/Logger';
import { AxiosInstance } from 'axios';
import { AssignmentDto } from '../dtos/AssignmentDto';
import { EmployeeDto } from '../dtos/EmployeeDto';
import { SubjectDto } from '../dtos/SubjectDto';
import { UpdateSubjectDto } from '../dtos/UpdateSubjectDto';
import { UpdateSubjectResponseDto } from '../dtos/UpdateSubjectResponseDto';
import GanttTaskDto from '../gantt/GanttTaskDto';
import ResourcePlanningModel from '../ResourcePlanningModel';
import { mapAssignmentToGanttTask, mapSubjectToGanttTask } from '../util/Mapper';

export class SubjectService {
  private readonly resourcePlanning: ResourcePlanningModel;
  private readonly axios: AxiosInstance;

  private readonly nullEmployee: EmployeeDto = {
    employeeId: 0,
    name: '',
    shortName: '',
    email: ''
  };

  constructor(resourcePlanning: ResourcePlanningModel) {
    this.resourcePlanning = resourcePlanning;
    this.axios = resourcePlanning.axios;
  }

  public postTask(newProject: GanttTaskDto): Promise<GanttTaskDto> {
    return this.axios.post(`/api/resourceplanning/task`, newProject).then((result) => {
      return result.data;
    });
  }

  public deleteTask(taskToBeDeleted: GanttTaskDto): Promise<GanttTaskDto[]> {
    return this.axios.delete(`/api/resourceplanning/task/${taskToBeDeleted.id}`).then((result) => {
      const deletedTasks: GanttTaskDto[] = result.data;
      deletedTasks.forEach((task) => {
        this.removeTaskFromGanttData(task.id);
      });
      this.notifySelectionChanged(undefined, true);
      return deletedTasks;
    });
  }

  public async importFromFavro(): Promise<void> {
    await this.axios.get(`/api/resourceplanning/import/favro/cards`);
    await this.resourcePlanning.ganttModel.reloadGanttData();
  }

  public async getProjects(): Promise<SubjectDto[]> {
    const projects: SubjectDto[] = await this.axios
      .get(`/api/resourceplanning/projects`)
      .then((result) => {
        return result.data;
      });
    return projects;
  }

  public async getProjectActivity(
    projectSubjectId: number | undefined,
    departmentId: number | undefined
  ): Promise<SubjectDto | undefined> {
    const activity: SubjectDto = await this.axios
      .get(`/api/resourceplanning/departmentActivity/${projectSubjectId}/${departmentId}`)
      .then((result) => {
        return result.data;
      });
    return activity;
  }

  public async getSubject(subjectId: string | number): Promise<SubjectDto> {
    const subject: SubjectDto = await this.axios
      .get(`/api/resourceplanning/subject/${subjectId}`)
      .then((result) => {
        return result.data;
      });
    this.mapSubjectToGanttTask(subject);
    return subject;
  }

  public saveSubject(updateRequest: UpdateSubjectDto): Promise<void> {
    if (updateRequest.update.subjectId <= 0) {
      return Promise.reject();
    }

    const prjLeader = this.resourcePlanning.employees.find(
      (e) => e.name === updateRequest.update.projectLeader?.name
    );
    updateRequest.update.projectLeaderId = prjLeader?.employeeId;
    updateRequest.update.projectLeader = undefined;

    return this.putSubject(updateRequest)
      .then((response: void | UpdateSubjectResponseDto) => {
        this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);
      })
      .catch((error: any) => {
        console.error('nidi(error 4) write subject to backend FAILED', error);
        setTimeout(() => {
          if (this.resourcePlanning.ganttModel.ganttData != undefined) {
            const ganttTask = this.resourcePlanning.ganttModel.getGanttDataTask(
              `${updateRequest.origin.subjectId}`
            ) as GanttTaskDto;
            if (ganttTask != undefined) {
              ganttTask.title = updateRequest.origin.title as string;
              ganttTask.start = updateRequest.origin.start as Date;
              ganttTask.end = updateRequest.origin.end as Date;
              ganttTask.progress = updateRequest.origin.progress as number;
              this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);
            }
          }
        }, 0);
      });
  }

  public putSubject(updateRequest: UpdateSubjectDto): Promise<void | UpdateSubjectResponseDto> {
    return this.axios
      .put(`/api/resourceplanning/subject/${updateRequest.update.subjectId}`, updateRequest)
      .then((result) => {
        const response: UpdateSubjectResponseDto = result.data;
        this.updateGanttTasksAndSelectedProjectsBySubjects(response);
        this.notifySelectionChanged(
          this.resourcePlanning.ganttModel.selectedGanttTask.id,
          updateRequest.origin.beginDate !== updateRequest.update.beginDate
        );
        return response;
      })
      .catch(() => {
        this.mapSubjectToGanttTask(updateRequest.origin);
        this.notifySelectionChanged(this.resourcePlanning.ganttModel.selectedGanttTask.id, true);
      });
  }

  private mapSubjectToGanttTask(subject: SubjectDto) {
    const task = this.resourcePlanning.ganttModel.getGanttDataTask(
      `${subject.subjectId}`
    ) as GanttTaskDto;
    if (task != undefined) {
      mapSubjectToGanttTask(subject, task);
      this.resourcePlanning.ganttModel.setGanttDataTask(task);
    }
  }

  private notifyUpdateTask(task: GanttTaskDto): void {
    this.resourcePlanning.ganttModel.eventUpdateGanttTask.notify({
      task
    });
  }

  private notifySelectionChanged(taskId: string | undefined, refreshGantt?: boolean): void {
    const ganttTask = this.resourcePlanning.ganttModel.getGanttDataTask(taskId);
    if (ganttTask != undefined) {
      this.resourcePlanning.ganttModel.eventGanttTaskSelectionChanged.notify({
        hasSelection: true,
        selection: ganttTask as GanttTaskDto,
        refreshGantt
      });
    } else {
      this.resourcePlanning.ganttModel.eventGanttTaskSelectionChanged.notify({
        hasSelection: false,
        selection: {} as GanttTaskDto,
        refreshGantt
      });
    }
  }

  private updateGanttTasksAndSelectedProjectsBySubjects(response: UpdateSubjectResponseDto): void {
    response.updatedSubjects.forEach((subject) => {
      const id = `${subject.subjectId}`;
      const ganttTask = this.resourcePlanning.ganttModel.getGanttDataTask(id);
      if (ganttTask != null) {
        mapSubjectToGanttTask(subject, ganttTask);
      }
    });
    response.updatedAssignments.forEach((assignment) => {
      const id = `${assignment.subjectId}-${assignment.assignmentId}`;
      const ganttTask = this.resourcePlanning.ganttModel.getGanttDataTask(id);
      if (ganttTask != null) {
        mapAssignmentToGanttTask(assignment, ganttTask);
      }
    });
  }

  public deleteAssignment(assignmentId: string): Promise<AssignmentDto> {
    return this.axios.delete(`/api/resourceplanning/assignment/${assignmentId}`).then((result) => {
      const deletedAssignment: AssignmentDto = result.data as AssignmentDto;
      try {
        const taskId = `${deletedAssignment.subjectId}-${deletedAssignment.assignmentId}`;
        this.removeTaskFromGanttData(taskId);
        this.notifySelectionChanged(assignmentId, true);
      } catch (error) {
        console.log('Assignment deletion error: ', error);
      }
      return deletedAssignment;
    });
  }

  private notifyRefreshGantt() {
    this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);
  }

  private removeTaskFromGanttData(taskId: string): void {
    if (!this.resourcePlanning.ganttModel.ganttData) {
      return;
    }
    this.resourcePlanning.ganttModel.ganttData.tasks =
      this.resourcePlanning.ganttModel.ganttData.tasks.filter((value) => value.id !== taskId);
  }

  public newPoject(): GanttTaskDto {
    const now = new Date();
    const twoDaysLater = new Date();
    twoDaysLater.setDate(now.getDate() + 2);
    const newProject: GanttTaskDto = {
      id: '0',
      title: '',
      start: now,
      end: twoDaysLater,
      progress: 0,
      subjectType: 1,
      hasHW: false,
      hasSW: false,
      hasWE: false,
      hasIBS: false,
      hasSER: false,
      hasDIG: false,
      departmentId: 0
    };
    return newProject;
  }

  public convertTimeStringToDate(task: GanttTaskDto): void {
    const isStartString = typeof task.start === 'string' || task.start instanceof String;
    if (isStartString) {
      task.start = new Date(task.start);
    }

    const isEndString = typeof task.end === 'string' || task.end instanceof String;
    if (isEndString) {
      task.end = new Date(task.end);
    }
  }

  public readTaskFromBackend(subjectId: string): Promise<GanttTaskDto> {
    return this.axios.get(`/api/resourceplanning/task/${subjectId}`).then((result) => result.data);
  }
}
