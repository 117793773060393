import { render, staticRenderFns } from "./GanttChart.vue?vue&type=template&id=5fb8ed91"
import script from "./GanttChart.vue?vue&type=script&lang=ts"
export * from "./GanttChart.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_css-loader@6.11.0_webpack@5.75.0__ejs@3.1.10_loda_xtmbv6llpvjscl74zilthpewd4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports