<template>
  <div class="container-fluid">
    <form @submit.prevent="send">
      <div class="row" v-for="item of model.entries" :key="item.propertyName">
        <div class="col-12">
          <div class="dip-input form-group">
            <label v-if="item.label != null && item.type !== 'boolean'" :for="item.propertyName">{{
              `${item.label.name} ${getUnit(item.options)} ${item.required === true ? '*' : ''}`
            }}</label>

            <div v-if="item.type === 'comment'">
              {{ item.getComment() }}
            </div>
            <div v-if="item.type === 'text' && !getBoolean(item.options, 'multiline', false)">
              <TextBox :model="item" :content="getProperty(item.propertyName)"></TextBox>
            </div>
            <div v-if="item.type === 'text' && getBoolean(item.options, 'multiline', false)">
              <TextArea :model="item" :content="getProperty(item.propertyName)"></TextArea>
            </div>
            <div v-if="item.type === 'integer' || item.type === 'decimal'">
              <NumberBox :model="item" :content="getProperty(item.propertyName)"></NumberBox>
            </div>
            <div v-if="item.type === 'boolean'">
              <CheckBox :model="item" :content="getProperty(item.propertyName)"></CheckBox>
            </div>
            <div v-if="item.type === 'date'">
              <DateBox :model="item" :content="getProperty(item.propertyName)"></DateBox>
            </div>
            <div v-if="item.type === 'tags'">
              <TagBox :model="item" :content="getProperty(item.propertyName)"></TagBox>
            </div>
            <div v-if="item.type === 'singleSelection'">
              <DropDownBox :model="item" :content="getProperty(item.propertyName)"></DropDownBox>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DxNumberBox from 'devextreme-vue/number-box';
import INumberFormat from './INumberFormat';
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import IGenericFormItem from './IGenericFormItem';
import DxTagBox from 'devextreme-vue/tag-box';
import ToolkitButton from '@/components/buttons/ToolkitButton.vue';
import { IGenericFormModel } from './IGenericFormModel';
import DropDownBox from '../input/DropDownBox.vue';
import { ISingleSelectionModel } from './ISingleSelectionModel';
import clone from '@/models/resourcePlanning/util/Clone';
import TextBox from '../input/TextBox.vue';
import { IValueContainer, ValueContainer } from './ValueContainer';
import NumberBox from '../input/NumberBox.vue';
import TextArea from '../input/TextArea.vue';
import DateBox from '../input/DateBox.vue';
import CheckBox from '../input/CheckBox.vue';
import TagBox from '../input/TagBox.vue';

@Component({
  components: {
    TextBox,
    TextArea,
    NumberBox,
    DateBox,
    CheckBox,
    TagBox,
    DxTextBox,
    DxTextArea,
    DxNumberBox,
    DxDateBox,
    DxTagBox,
    DxCheckBox,
    DropDownBox,
    ToolkitButton
  }
})
export default class GenericForm extends Vue {
  @Prop()
  public model!: IGenericFormModel;

  private editData: Record<string, unknown> = {} as Record<string, unknown>;

  public createSingleSelectionModel(item: IGenericFormItem, value: string): ISingleSelectionModel {
    return {
      item,
      value
    };
  }

  public getProperty(propertyName: string): IValueContainer {
    const path: string[] = propertyName.split('.');
    const index = 0;
    return this.getPropertyAtLevel(path, index, this.editData);
  }

  private getPropertyAtLevel(
    path: string[],
    index: number,
    source: Record<string, unknown>
  ): IValueContainer {
    if (!source) {
      return {
        value: {}
      };
    }
    const name = path[index];
    const value = source[name];
    if (index === path.length - 1) {
      return new ValueContainer(
        () => {
          return source[name];
        },
        (val) => {
          source[name] = val;
        }
      );
    }
    return this.getPropertyAtLevel(path, index + 1, value as Record<string, unknown>);
  }

  public getNumber(options: Record<string, unknown>, name: string, defaultValue: number): number {
    if (options == undefined) {
      return defaultValue;
    }
    const value: number | unknown = options[name];
    if (value == undefined) {
      return defaultValue;
    }
    return value as number;
  }

  public getNumberFormat(item: IGenericFormItem): INumberFormat {
    const opt: any = item.options;
    if (item.type == 'integer') {
      return {
        type: 'fixedPoint'
      };
    } else {
      return {
        type: 'decimal',
        precision: this.getNumber(opt, 'precision', 2)
      };
    }
  }

  public getBoolean(
    options: Record<string, unknown>,
    name: string,
    defaultValue: boolean
  ): boolean {
    if (options == undefined) {
      return defaultValue;
    }
    const value: boolean | unknown = options[name];
    if (value == undefined) {
      return defaultValue;
    }
    return value as boolean;
  }

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    if (options == undefined) {
      return defaultValue;
    }
    const value: string | unknown = options[name];
    if (value == undefined) {
      return defaultValue;
    }
    return value as string;
  }

  public getUnit(options: Record<string, unknown>): string {
    if (options == undefined) {
      return '';
    }
    const value: string | unknown = options['unit'];
    if (value == undefined) {
      return '';
    }
    return `[${value as string}]`;
  }

  public mounted(): void {
    this.loadData();
  }

  @Watch('model')
  private onModelChanged(): void {
    this.loadData();
  }

  public loadData(): void {
    const modelData = this.model.getData();
    if (modelData != null && modelData.finally != undefined) {
      (modelData as Promise<Record<string, unknown>>).then((data: Record<string, unknown>) => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        this.editData = clone<Record<string, unknown>>(data);
        this.model.result = this.editData;
      });
    } else {
      this.editData = clone(modelData) as Record<string, unknown>;
      this.model.result = this.editData;
    }
  }
}
</script>
<style lang="scss">
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}
</style>
