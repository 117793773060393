import { AxiosInstance } from 'axios';
import { SubjectDto } from './dtos/SubjectDto';
import { encodeBase64Url } from './util/Base64';
import ITaskSelectionChangeEvent from './events/ITaskSelectionChangeEvent';
import IUpdateTaskEvent from './events/IUpdateTaskEvent';
import GanttTaskDto from './gantt/GanttTaskDto';
import { ResourcePlanningGanttDto } from './gantt/ResourcePlanningGanttDto';
import ResourcePlanningModel from './ResourcePlanningModel';
import Subject from './util/Subject';

export default class GanttModel {
  public readonly axios: AxiosInstance;
  public readonly resourcePlanning: ResourcePlanningModel;
  constructor(resourcePlanning: ResourcePlanningModel) {
    this.resourcePlanning = resourcePlanning;
    this.axios = resourcePlanning.axios;
  }

  public ganttData: ResourcePlanningGanttDto | undefined = {} as ResourcePlanningGanttDto;

  public hasGanttDataSelection = false;
  public selectedGanttTask: GanttTaskDto = {} as GanttTaskDto;

  /**
   * Notify a Gantt-Chart refresh request.
   */
  public readonly eventRefereshGantt: Subject<boolean> = new Subject();

  public readonly eventUpdateGanttTask: Subject<IUpdateTaskEvent> = new Subject();

  /**
   * Notify that the project or assignment selection has changed.
   */
  public readonly eventGanttTaskSelectionChanged: Subject<ITaskSelectionChangeEvent> =
    new Subject();

  public setGanttDataSelection(selectedGanttTask: GanttTaskDto | undefined): void {
    if (selectedGanttTask == undefined) {
      this.selectedGanttTask = {} as GanttTaskDto;
      this.hasGanttDataSelection = false;
    } else {
      this.selectedGanttTask = selectedGanttTask;
      this.hasGanttDataSelection = true;
    }
    const taskSelectionChangeEvent: ITaskSelectionChangeEvent = {
      hasSelection: this.hasGanttDataSelection,
      selection: this.selectedGanttTask
    };
    this.eventGanttTaskSelectionChanged.notify(taskSelectionChangeEvent);
  }

  public getSelectedGanttTask(): Promise<Record<string, unknown>> | undefined {
    if (this.selectedGanttTask == undefined) {
      return undefined;
    }

    return this.resourcePlanning.subjectService
      .readTaskFromBackend(this.selectedGanttTask.id as string)
      .then((serverTask) => {
        const selectedTaskRecord = this.selectedGanttTask;
        for (const key in Object.keys(selectedTaskRecord)) {
          selectedTaskRecord[key] = serverTask[key];
        }
        // Update with newest data before editing...
        this.setGanttDataTask(serverTask);
        return selectedTaskRecord;
      }) as Promise<Record<string, unknown>>;
  }

  public setGanttDataTask(update: GanttTaskDto): void {
    if (update == undefined) {
      return;
    }
    if (!this.ganttData) {
      return undefined;
    }
    if (!this.ganttData.tasks) {
      return undefined;
    }

    const updateRecord = update as Record<string, unknown>;
    const ganttTask: GanttTaskDto = this.ganttData.tasks.find(
      (t) => t.id === update.id
    ) as GanttTaskDto;

    if (ganttTask) {
      for (const key in Object.keys(updateRecord)) {
        ganttTask[key] = updateRecord[key];
      }
    }
  }

  public getGanttDataTask(id: string | undefined): GanttTaskDto | undefined {
    if (id == undefined) {
      return undefined;
    }
    if (!this.ganttData) {
      return undefined;
    }
    if (!this.ganttData.tasks) {
      return undefined;
    }
    for (let i = 0; i < this.ganttData.tasks.length; i++) {
      const task = this.ganttData.tasks[i];
      if (task.id === id) {
        return task;
      }
    }
    return undefined;
  }

  public loadGanttData(): Promise<ResourcePlanningGanttDto | undefined> {
    const dataFilter = this.resourcePlanning.filterService.getDataFilter();
    dataFilter.filterEntries = []; // ignore emplyee filter for gantt chart
    const dataFilterString = JSON.stringify(dataFilter);
    const dataFilterStringBase64Url = encodeBase64Url(dataFilterString);

    return this.axios
      .get(`/api/resourceplanning/gantt?filter=${dataFilterStringBase64Url}`)
      .then((res) => {
        this.ganttData = res.data;
        return this.ganttData;
      });
  }

  public reloadGanttData(): Promise<void> {
    const dataFilter = this.resourcePlanning.filterService.getDataFilter();
    dataFilter.filterEntries = []; // ignore emplyee filter for gantt chart
    const dataFilterString = JSON.stringify(dataFilter);
    const dataFilterStringBase64Url = encodeBase64Url(dataFilterString);

    return this.axios
      .get(`/api/resourceplanning/gantt?filter=${dataFilterStringBase64Url}`)
      .then((res: any) => {
        this.ganttData = res.data;
        this.eventRefereshGantt.notify(true);
        this.setGanttDataSelection(this.selectedGanttTask);
      });
  }

  // public setGanttDataTaskBySubject(subject: SubjectDto): void {
  //   const stringId = `${subject.subjectId}`;
  //   const task = this.resourcePlanning.getGanttDataTask(stringId);
  //   if (!task) {
  //     return;
  //   }
  //   const update: GanttTaskDto = {
  //     id: stringId,
  //     start: subject.beginDate as Date,
  //     end: subject.endDate as Date,
  //     subjectType: subject.subjectType as number,
  //     title: subject.name,
  //     isAssignment: false,
  //     parentId: `${subject.parentSubjectId}`,
  //     treeId: subject.treeId,
  //     progress: task?.progress
  //   };
  //   this.setGanttDataTask(update);
  // }
}
