import { AssignmentDto } from '../dtos/AssignmentDto';
import { SubjectDto } from '../dtos/SubjectDto';
import GanttTaskDto from '../gantt/GanttTaskDto';

export function mapGanttTaskToAssignment(ganttTask: GanttTaskDto, assignment: AssignmentDto): void {
  assignment.beginDate = new Date(ganttTask.start);
  assignment.endDate = new Date(ganttTask.end);
}

export function mapGanttTaskToSubject(ganttTask: GanttTaskDto, subject: SubjectDto): void {
  subject.beginDate = ganttTask.start as Date;
  subject.endDate = ganttTask.end as Date;
  subject.name = ganttTask.title;
}

export function mapSubjectToGanttTask(subject: SubjectDto, ganttTask: GanttTaskDto): void {
  ganttTask.start = subject.beginDate as Date;
  ganttTask.end = subject.endDate as Date;
  ganttTask.title = subject.name;
}

export function mapAssignmentToGanttTask(assignment: AssignmentDto, ganttTask: GanttTaskDto): void {
  ganttTask.start = new Date(assignment.beginDate);
  ganttTask.end = new Date(assignment.endDate as Date);
}
