<template>
  <div>
    <DxNumberBox
      :disabled="model.disabled"
      :show-spin-buttons="getBoolean(model.options, 'showSpinnerBtn', true)"
      :show-clear-button="getBoolean(model.options, 'showClearBtn', false)"
      :min="getNumber(model.options, 'min', 0)"
      :max="getNumber(model.options, 'max', 100)"
      :width="getString(model.options, 'width', '200')"
      :format="getNumberFormat(model)"
      :value.sync="content.value"
    ></DxNumberBox>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxNumberBox from 'devextreme-vue/number-box';
import { GenericFormItem } from '../genericForm/GenericFormItem';
import { IValueContainer } from '../genericForm/ValueContainer';
import IGenericFormItem from '../genericForm/IGenericFormItem';
import INumberFormat from '../genericForm/INumberFormat';
import { getString, getNumber, getNumberFormat, getBoolean } from '../genericForm/OptionsAccessors';

@Component({
  components: {
    DxNumberBox
  }
})
export default class NumberBox extends Vue {
  @Prop()
  public model!: GenericFormItem;
  @Prop()
  public content!: IValueContainer;

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    return getString(options, name, defaultValue);
  }

  public getBoolean(
    options: Record<string, unknown>,
    name: string,
    defaultValue: boolean
  ): boolean {
    return getBoolean(options, name, defaultValue);
  }

  public getNumber(options: Record<string, unknown>, name: string, defaultValue: number): number {
    return getNumber(options, name, defaultValue);
  }

  public getNumberFormat(model: IGenericFormItem): INumberFormat {
    return getNumberFormat(model);
  }
}
</script>
