import { IToolkitMenuModel } from '@/components/buttons/IToolkitMenuModel';
import ResourcePlanning from '../ResourcePlanningModel';

export default class AssignmentsMenu {
  public readonly resourcePlanning: ResourcePlanning;

  public model: IToolkitMenuModel = {
    command: {
      id: 'Assignments',
      name: 'Buchungen',
      icon: 'fas fa-user-clock',
      execute: () => undefined
    },
    getEntries: () =>
      Promise.resolve([
        {
          id: 'AssignUser',
          name: 'Teammitglied zuweisen',
          icon: 'fas fa-user-plus',
          group: 'root',
          disabled: () =>
            this.resourcePlanning.ganttModel.selectedGanttTask == undefined ||
            this.resourcePlanning.ganttModel.selectedGanttTask.id == undefined,
          execute: () => {
            this.resourcePlanning.dialogAssignEmployee.show();
          }
        },
        {
          id: 'RemoveAssignment',
          name: 'Teammitglied entfernen',
          icon: 'fas fa-user-minus',
          group: 'root',
          disabled: () =>
            this.resourcePlanning.ganttModel.selectedGanttTask == undefined ||
            this.resourcePlanning.ganttModel.selectedGanttTask.id == undefined ||
            this.resourcePlanning.ganttModel.selectedGanttTask.subjectType !== 1000,
          execute: () => {
            if (
              this.resourcePlanning.ganttModel.selectedGanttTask == undefined ||
              this.resourcePlanning.ganttModel.selectedGanttTask.id == undefined ||
              this.resourcePlanning.ganttModel.selectedGanttTask.subjectType !== 1000
            ) {
              return;
            }
            this.resourcePlanning.dialogDeleteAssignment.show();
          }
        }
      ])
  };

  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }
}
