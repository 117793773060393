import IGenericFormItem from './IGenericFormItem';
import INumberFormat from './INumberFormat';

export function getNumber(
  options: Record<string, unknown>,
  name: string,
  defaultValue: number
): number {
  if (options == undefined) {
    return defaultValue;
  }
  const value: number | unknown = options[name];
  if (value == undefined) {
    return defaultValue;
  }
  return value as number;
}

export function getNumberFormat(item: IGenericFormItem): INumberFormat {
  const opt: any = item.options;
  if (item.type == 'integer') {
    return {
      type: 'fixedPoint'
    };
  } else {
    return {
      type: 'decimal',
      precision: getNumber(opt, 'precision', 2)
    };
  }
}

export function getBoolean(
  options: Record<string, unknown>,
  name: string,
  defaultValue: boolean
): boolean {
  if (options == undefined) {
    return defaultValue;
  }
  const value: boolean | unknown = options[name];
  if (value == undefined) {
    return defaultValue;
  }
  return value as boolean;
}

export function getString(
  options: Record<string, unknown>,
  name: string,
  defaultValue: string
): string {
  if (options == undefined) {
    return defaultValue;
  }
  const value: string | unknown = options[name];
  if (value == undefined) {
    return defaultValue;
  }
  return value as string;
}

export function getUnit(options: Record<string, unknown>): string {
  if (options == undefined) {
    return '';
  }
  const value: string | unknown = options['unit'];
  if (value == undefined) {
    return '';
  }
  return `[${value as string}]`;
}
