import ITask from '@/views/resourcePlanning/timeline2/ITask';
import ITimelineConfiguration from '@/views/resourcePlanning/timeline2/ITimelineConfiguration';
import ITimelineModel from '@/views/resourcePlanning/timeline2/ITimelineModel';
import { AxiosInstance } from 'axios';
import { AssignmentDto } from './dtos/AssignmentDto';
import IAssignmentChangeEvent from './events/IAssignmentChangeEvent';
import ResourcePlanningModel from './ResourcePlanningModel';
import TimelineTaskDto from './timeline/TimelineTaskDto';
import { encodeBase64Url } from './util/Base64';
import clone from './util/Clone';
import Observable from './util/Observable';
import Subject from './util/Subject';

export default class TimelineModel {
  public readonly axios: AxiosInstance;
  public readonly resourcePlanning: ResourcePlanningModel;
  constructor(resourcePlanning: ResourcePlanningModel) {
    this.resourcePlanning = resourcePlanning;
    this.axios = resourcePlanning.axios;
  }

  public timelineData: ITimelineModel = {
    resources: [],
    tasks: []
  };

  public from: Observable<Date> = new Observable<Date>(new Date(new Date().getFullYear(), 1, 1));
  public to: Observable<Date> = new Observable<Date>(new Date(new Date().getFullYear(), 12, 31));

  public timelineConfiguration: ITimelineConfiguration = {
    currentDate: new Date(2021, 5, 5),
    minAvailableDate: new Date(2021, 1, 1),
    maxAvailableDate: new Date(2023, 12, 31),
    cellDurationMinutes: 60,
    currentView: localStorage.getItem('timelineCurrentView') ?? '1 Month',
    views: [
      {
        name: '1 Monat',
        type: 'timelineMonth',
        intervalCount: 1
      },
      {
        name: '2 Monate',
        type: 'timelineMonth',
        intervalCount: 2
      },
      {
        name: '3 Monate',
        type: 'timelineMonth',
        intervalCount: 3
      }
    ]
  };

  public readonly eventRefereshTimeline: Subject<boolean> = new Subject();

  public async updateAssignment(assignmentChangeEvent: IAssignmentChangeEvent): Promise<void> {
    if (assignmentChangeEvent.from.employeeId === assignmentChangeEvent.to.employeeId) {
      return;
    }

    await this.reloadTimelineData();
    for (const task of this.timelineData.tasks) {
      if (task.assignmentId === assignmentChangeEvent.from.assignmentId) {
        task.id = assignmentChangeEvent.to.employeeId;
        task.employeeId = assignmentChangeEvent.to.employeeId;
        break;
      }
    }

    this.resourcePlanning.eventAssignmentChanged.notify(assignmentChangeEvent);
  }

  public getTimelineTaskForAssignmentId(assignmentId: number): TimelineTaskDto | undefined {
    for (const task of this.timelineData.tasks) {
      if (task.assignmentId === assignmentId) {
        return task as TimelineTaskDto;
      }
    }
  }

  public async duplicateAssignment(assignmentId: number): Promise<void> {
    await this.axios.get(`/api/resourceplanning/assignment/${assignmentId}?duplicate=true`);
    await this.reloadTimelineData();
    this.eventRefereshTimeline.notify(true);
  }

  public async removeAssignment(assignmentId: number): Promise<void> {
    await this.axios.delete(`/api/resourceplanning/assignment/${assignmentId}`);
    await this.reloadTimelineData();
    this.eventRefereshTimeline.notify(true);
  }

  public async setAssignmentColor(assignmentId: number, colorCode: number): Promise<void> {
    const response = await this.axios.get(`/api/resourceplanning/assignment/${assignmentId}`);
    const originalAssignment = response.data as AssignmentDto;
    const updatedAssignment = clone(originalAssignment);
    updatedAssignment.colorCode = colorCode;
    await this.axios.put(`/api/resourceplanning/assignment/${assignmentId}`, {
      origin: originalAssignment,
      update: updatedAssignment
    });
    const task: ITask | undefined = this.timelineData.tasks.find(
      (t) => t.assignmentId === assignmentId
    );
    if (task) {
      task.colorCode = colorCode;
    }
    await this.reloadTimelineData();
    this.eventRefereshTimeline.notify(true);
  }

  public reloadTimelineData(): Promise<ITimelineModel> {
    const dataFilter = this.resourcePlanning.filterService.getDataFilter();
    const dataFilterString = JSON.stringify(dataFilter);
    const dataFilterStringBase64Url = encodeBase64Url(dataFilterString);
    return this.axios
      .get(`/api/resourceplanning/EmployeeTimeline?filter=${dataFilterStringBase64Url}`)
      .then((res) => {
        this.timelineData = res.data;
        return this.timelineData;
      });
  }

  public loadTimelineData(from: Date, to: Date): Promise<ITimelineModel> {
    const dataFilter = this.resourcePlanning.filterService.getDataFilter();
    const dataFilterString = JSON.stringify(dataFilter);
    const dataFilterStringBase64Url = encodeBase64Url(dataFilterString);

    return this.axios
      .get(`/api/resourceplanning/EmployeeTimeline?filter=${dataFilterStringBase64Url}`)
      .then((res) => {
        this.timelineData = res.data;
        return this.timelineData;
      });
  }
}
