<template>
  <div>
    <div v-if="resourcePlanning.hasSelectedProject && resourcePlanning.hasSelectedAssignment">
      <GenericForm :model="formModel"></GenericForm>
      <ToolkitButton :action="saveButtonModel"></ToolkitButton>
    </div>
  </div>
</template>
<script lang="ts">
import { IToolkitCommand } from '@/components/buttons/IToolkitCommand';
import ToolkitButton from '@/components/buttons/ToolkitButton.vue';
import GenericForm from '@/components/genericForm/GenericForm.vue';
import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import ITextValuePair from '@/components/genericForm/ITextValuePair';
import { ToolkitCommandResultType } from '@/components/sharedModels/ICommand';
import { AssignmentDto } from '@/models/resourcePlanning/dtos/AssignmentDto';
import { EmployeeDto } from '@/models/resourcePlanning/dtos/EmployeeDto';
import { UpdateAssignmentDto } from '@/models/resourcePlanning/dtos/UpdateAssignmentDto';
import IAssignmentChangeEvent from '@/models/resourcePlanning/events/IAssignmentChangeEvent';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
import ResourcePlanningModel from '@/models/resourcePlanning/ResourcePlanningModel';
import clone from '@/models/resourcePlanning/util/Clone';
import DxDateBox from 'devextreme-vue/date-box';
import DxNumberBox from 'devextreme-vue/number-box';
import DxTextBox from 'devextreme-vue/text-box';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: { GenericForm, ToolkitButton, DxTextBox, DxDateBox, DxNumberBox }
})
export default class AssignmentDetails extends Vue {
  public assignmentLoaded = false;

  public beginDate: Date = {} as Date;
  public endDate: Date = {} as Date;

  // TODO mani: anderweitig verplant berechnen
  public otherWorkload = '';

  public model: ModelRoot = this.$model;
  public resourcePlanning: ResourcePlanningModel = this.$model.resourcePlanning;

  public formModel: IGenericFormModel = {
    getData: () => this.getFormData(),
    entries: [
      {
        type: 'text',
        propertyName: 'projectName',
        disabled: true,
        label: {
          name: this.resourcePlanning.hasSelectedSubproject ? 'Subprojekt' : 'Projekt'
        },
        options: {
          width: '100%'
        }
      },
      {
        type: 'singleSelection',
        propertyName: 'employeeName',
        label: {
          name: 'Mitarbeiter'
        },
        options: {
          items: () =>
            this.resourcePlanning.employees.map((e) => {
              return { value: e.name, text: e.name } as ITextValuePair;
            }),
          width: '100%'
        }
      },
      {
        type: 'text',
        propertyName: 'activityName',
        disabled: true,
        label: {
          name: 'Abteilung'
        },
        options: {
          width: '100%'
        }
      },
      {
        type: 'date',
        propertyName: 'beginDate',
        label: {
          name: 'Start'
        }
      },
      {
        type: 'date',
        propertyName: 'endDate',
        label: {
          name: 'Ende'
        }
      },
      {
        type: 'integer',
        propertyName: 'pensum',
        label: {
          name: 'Pensum'
        },
        options: {
          width: '100%',
          unit: '%',
          min: 0,
          max: 100
        }
      },
      {
        type: 'decimal',
        propertyName: 'hours',
        label: {
          name: 'Stunden'
        },
        options: {
          width: '100%',
          unit: 'h',
          min: 0,
          max: 100000
        }
      },
      {
        type: 'text',
        propertyName: 'comment',
        label: {
          name: 'Kommentar'
        },
        options: {
          multiline: true,
          height: 60,
          width: '100%'
        }
      }
    ]
  };

  public saveButtonModel: IToolkitCommand = {
    name: 'Speichern',
    group: 'root',
    execute: async () => await this.onSave()
  };

  getFormData(): Record<string, unknown> | Promise<Record<string, unknown>> | undefined {
    let formData: Record<string, unknown> = this.resourcePlanning.selectedAssignment;
    let projectName = this.resourcePlanning.hasSelectedSubproject
      ? this.resourcePlanning.selectedSubproject.name
      : this.resourcePlanning.selectedProject.name;
    formData['projectName'] = projectName;
    formData['employeeName'] = this.resourcePlanning.selectedAssignment.employee?.name;
    formData['activityName'] = this.resourcePlanning.selectedAssignment.subject?.name;
    return formData;
  }

  public async onSave(): Promise<ToolkitCommandResultType> {
    if (!this.formModel.result) {
      return;
    }
    const formResult = this.formModel.result as Record<string, unknown>;
    const employeeName: string = (formResult['employeeName'] as string) ?? '';
    const employee: EmployeeDto | undefined = this.resourcePlanning.getEmployeeByName(employeeName);

    const originAssignment = clone(this.resourcePlanning.selectedAssignment);
    const updatedAssignment: AssignmentDto = clone(this.formModel.result as AssignmentDto);
    if (employee != undefined) {
      updatedAssignment.employeeId = employee.employeeId;
      updatedAssignment.employee = undefined;
    }

    const updateRequest: UpdateAssignmentDto = {
      origin: originAssignment,
      update: updatedAssignment
    };

    await this.resourcePlanning.employeeService
      .putAssignment(updateRequest)
      .then(async () => {
        const assignmentChangeEvent: IAssignmentChangeEvent = {
          from: originAssignment,
          to: updatedAssignment
        };
        await this.resourcePlanning.timelineModel.updateAssignment(assignmentChangeEvent);
        this.resourcePlanning.timelineModel.eventRefereshTimeline.notify(true);
        this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);

        const plainResult: any = this.formModel.result;
        const mappedTask: { id: string; title: string; start: Date; end: Date; treeId: string } = {
          id: plainResult.subjectId + '-' + plainResult.assignmentId,
          title: plainResult.employeeName,
          start: new Date(plainResult.beginDate),
          end: new Date(plainResult.endDate),
          treeId: plainResult.treeId
        };
        this.$emit('onAssignmentSaved', mappedTask);
      })
      .catch((error) => {
        console.warn('nidi(error 6) Write Project to Database failed!', error);
      });
  }
}
</script>
<style lang="scss" scoped>
.readonly-field {
  color: #000000;
  background-color: #eeeeee;
  border: solid 1px #dddddd;
  border-radius: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
