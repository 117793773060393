import { IToolkitMenuModel } from '@/components/buttons/IToolkitMenuModel';
import { ToolkitCommandResultType } from '@/components/sharedModels/ICommand';
import ResourcePlanning from '../ResourcePlanningModel';

export default class FavroMenu {
  public readonly resourcePlanning: ResourcePlanning;

  public model: IToolkitMenuModel = {
    command: {
      id: 'FavroIntegration',
      name: 'Favro Import',
      icon: 'fas fa-file-import',
      group: 'root',
      execute: () => this.importFavroCards()
    },
    getEntries: () => undefined
  };

  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }

  private importFavroCards(): ToolkitCommandResultType {
    return this.resourcePlanning.subjectService.importFromFavro();
  }
}
