import DataFilterDto from '../dtos/DataFilterDto';
import ResourcePlanningModel from '../ResourcePlanningModel';

export class FilterService {
  private readonly resourcePlanning: ResourcePlanningModel;
  constructor(resourcePlanning: ResourcePlanningModel) {
    this.resourcePlanning = resourcePlanning;
  }

  public getDataFilterString(): string | null {
    const storedDataFilter: string | null =
      this.resourcePlanning.webStorageService.get('dataFilter');
    return storedDataFilter;
  }

  public getDataFilter(): DataFilterDto {
    const storedDataFilter: string | null = this.getDataFilterString();
    if (storedDataFilter != null) {
      const dataFilter: DataFilterDto = JSON.parse(storedDataFilter) as DataFilterDto;
      return dataFilter;
    } else {
      return {
        from: new Date(new Date().getFullYear(), 0, 1),
        to: new Date(new Date().getFullYear(), 11, 31),
        owner: undefined,
        filterEntries: []
      };
    }
  }

  public setDataFilter(dataFilter: DataFilterDto): void {
    const dataFilterString = JSON.stringify(dataFilter);
    this.resourcePlanning.webStorageService.put('dataFilter', dataFilterString);
  }
}
