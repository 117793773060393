<template>
  <div>
    <DxTextBox
      :disabled="model.disabled"
      :width="getString(model.options, 'width', '100%')"
      :value.sync="content.value"
    ></DxTextBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxTextBox from 'devextreme-vue/text-box';
import { GenericFormItem } from '../genericForm/GenericFormItem';
import { IValueContainer } from '../genericForm/ValueContainer';
import { getString } from '../genericForm/OptionsAccessors';

@Component({
  components: {
    DxTextBox
  }
})
export default class TextBox extends Vue {
  @Prop()
  public model!: GenericFormItem;
  @Prop()
  public content!: IValueContainer;

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    return getString(options, name, defaultValue);
  }
}
</script>
