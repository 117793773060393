<template>
  <div :class="workWeekHandler(cellData)">
    <p v-if="isMidOfWeek(cellData.date)" class="week">KW<br />{{ getWorkWeek(cellData.date) }}</p>
    <p class="day">{{ cellData.date.getDate() }}.</p>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DateCell extends Vue {
  @Prop()
  public cellData: any;

  public workWeekHandler(cellData: { date: Date }) {
    var classString = 'date-cell-' + this.getWorkWeek(cellData.date);
    if (cellData.date.getDay() === 1) {
      classString += ' first-of-week';
    } else if (this.isMidOfWeek(cellData.date)) {
      classString += ' mid-of-week';
    } else if (cellData.date.getDay() === 0) {
      classString += ' last-of-week';
    }
    return classString;
  }

  public isMidOfWeek(date: Date) {
    return date.getDay() === 4;
  }

  public getWorkWeek(date: Date) {
    var dateTime: any = new Date(date.valueOf());
    var dayn = (date.getDay() + 6) % 7;
    dateTime.setDate(dateTime.getDate() - dayn + 3);
    var firstThursday = dateTime.valueOf();
    dateTime.setMonth(0, 1);
    if (dateTime.getDay() !== 4) {
      dateTime.setMonth(0, 1 + ((4 - dateTime.getDay() + 7) % 7));
    }
    const weekInMs = 604800000;
    return 1 + Math.ceil((firstThursday - dateTime) / weekInMs);
  }
}
</script>

<style lang="scss">
#scheduler {
  .dx-scheduler-header-panel-cell {
    // selects ALL headerCells
    &:has([class^='date-cell-']) {
      border-top: 1px solid rgba(255, 145, 145, 0.5) !important;
      align-items: self-end;
    }
    &:has(.first-of-week) {
      border-left: 1px solid rgba(255, 145, 145, 0.5) !important;
    }
    p.week {
      margin-bottom: 5px;
      line-height: 10px;
      color: rgba(255, 45, 45, 0.8);
    }
    p.day {
      margin-bottom: 0px;
    }
  }
}
</style>
