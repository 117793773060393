<template>
  <div class="container">
    <div class="my-2">
      <smart-table
        :config="config"
        :selected.sync="model"
        ref="smartTable"
        language="de"
        @rowupdated="$emit('rowupdated')"
        small
        :reloadDataOnUpdate="true"
        :readonly="
          pageSection == 'VacationsArchive' ||
          pageSection == 'ExecutiveArchive' ||
          pageSection === 'VacationOverviewAll'
        "
        responsive
        striped
        dialogTitle="Neuer Ferienantrag"
        :tableId="pageSection"
      />
    </div>
    <div class="text-right my-2">
      <slot name="slot1"></slot>
      <slot name="slot2"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import mixins from 'vue-typed-mixins';
import VModelMixin from '@/mixins/VModelMixin';
import { SmartTable, Config, Column, Sorting, PagingOptions, hasValue } from 'rey-vue-smarttable';
import { VacationStatus } from '@/models/VacationStatus';
import Vacation from '@/models/Vacation';

export default mixins(VModelMixin).extend({
  name: 'vacation-table',
  components: { SmartTable },
  props: { pageSection: String },
  data() {
    return {
      config: new Config((x) => x.id, undefined, undefined, new PagingOptions(50)).withEfApiActions(
        this.$reyDipApi,
        this.pageSection
      )
    };
  },
  created() {
    this.config.columns = this.getColumns();
    this.config.initialSorting = this.getSorting();
  },
  methods: {
    getColumns(): Column[] {
      return [
        new Column({
          title: 'Status',
          fieldName: 'status',
          fieldType: 'string',
          editable: () => false,
          converter: (cellValue) => {
            if (cellValue === VacationStatus.Requested) {
              return 'Angefragt';
            } else if (cellValue === VacationStatus.Approved) {
              return 'Angenommen';
            } else {
              return 'Abgelehnt';
            }
          },
          filterConverter: (cellValue) => {
            if (cellValue === 'Angefragt') {
              return [VacationStatus.Requested];
            } else if (cellValue === 'Angenommen') {
              return [VacationStatus.Approved];
            } else {
              return [VacationStatus.Declined];
            }
          },
          hidden: this.pageSection === 'ApprovedVacations',
          hiddenInAddDialog: true,
          cellStyling: (cell) => ({
            'bg-warning text-center': cell.status === VacationStatus.Requested,
            'bg-success text-white text-center': cell.status === VacationStatus.Approved,
            'bg-danger text-white text-center': cell.status === VacationStatus.Declined
          })
        }),
        new Column({
          title: 'Mitarbeiter',
          fieldName: 'username',
          fieldType: 'string',
          editable: () => false,
          hidden:
            this.pageSection === 'ApprovedVacations' ||
            this.pageSection === 'VacationsOverview' ||
            this.pageSection === 'VacationsArchive',
          hiddenInAddDialog: true
        }),
        new Column({
          title: 'Von',
          fieldName: 'begin',
          fieldType: 'date',
          editable: () =>
            this.pageSection === 'ApprovedVacations' || this.pageSection === 'VacationsOverview',
          validator: () => this.validate()
        }),
        new Column({
          title: 'Bis u. mit',
          fieldName: 'end',
          fieldType: 'date',
          editable: () =>
            this.pageSection === 'ApprovedVacations' || this.pageSection === 'VacationsOverview',
          validator: () => this.validate()
        }),
        new Column({
          title: 'Arbeitstage',
          fieldName: 'workingDays',
          fieldType: 'numeric',
          editable: () => false,
          hidden: false,
          hiddenInAddDialog: true
        }),
        new Column({
          title: 'Grund',
          fieldName: 'reason',
          fieldType: 'string',
          editable: () =>
            this.pageSection === 'ApprovedVacations' || this.pageSection === 'VacationsOverview',
          validator: hasValue
        }),
        new Column({
          title: 'Vorgesetzter',
          fieldName: 'managerName',
          fieldType: 'string',
          editable: () => false,
          hidden: this.pageSection === 'VacationDecisions',
          hiddenInAddDialog: true
        }),
        new Column({
          title: 'Bemerkung Vorgesetzter',
          fieldName: 'remarkManager',
          fieldType: 'string',
          editable: () =>
            this.pageSection === 'VacationDecisions' || this.pageSection === 'VacationOverviewTeam',
          hiddenInAddDialog: true
        }),
        new Column({
          title: 'Geändert am',
          fieldName: 'updatedAt',
          fieldType: 'date',
          editable: () => false,
          hiddenInAddDialog: true
        })
      ];
    },
    getSorting() {
      switch (this.pageSection) {
        case 'VacationsOverview':
          return new Sorting('updatedAt', 'descending');
        case 'ApprovedVacations':
          return new Sorting('begin');
        case 'VacationDecisions':
          return new Sorting('begin');
        default:
          return new Sorting('begin', 'descending');
      }
    },
    validate() {
      if (this.model !== undefined) {
        this.buildObjectForUpdate();
      }
      return {
        isValid: this.validateObject(
          this.model === undefined ? this.getValueOnCreate() : this.model
        ),
        errorMessages: []
      };
    },
    buildObjectForUpdate() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const editedCell: any = (this.$refs.smartTable as any).$refs.smartTableTable.$children.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (x: any) => x.$refs.editor
      )[0].model;
      this.model[editedCell.fieldName] = editedCell.value;
    },
    validateObject(vacationToValidate: Vacation): boolean {
      const start = new Date(vacationToValidate.begin);
      const end = new Date(vacationToValidate.end);

      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return false;
      }

      if (end < start || start < new Date(Date.now())) {
        return false;
      }

      return true;
    },
    getValueOnCreate() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (this.$refs.smartTable as any).$refs.smartTableForm.model;
    }
  }
});
</script>
<style lang="scss">
.table-active > td,
.table-active:hover {
  background-color: #a8d3e8;
  color: black;
}
.text-white {
  color: #fff !important;
}
</style>
