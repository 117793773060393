<template>
  <div :class="markingHandler(cellData)">
    {{ cellData.text }}
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class DataCell extends Vue {
  @Prop()
  public cellData: any;

  markingHandler(cellData: any) {
    const classObject: any = {};
    classObject['weekend'] = this.isWeekEnd(cellData.startDate);
    classObject['darkened'] = this.isTueAndThu(cellData.startDate);
    classObject['endOfMonth'] = this.isLastDayOfMonth(cellData.startDate);
    return classObject;
  }
  private isWeekEnd(date: Date) {
    const day = date.getDay();
    return day === 0 || day === 6;
  }
  private isTueAndThu(date: Date) {
    const day = date.getDay();
    return day === 2 || day === 4;
  }
  private isLastDayOfMonth(date: Date) {
    const oneDayInMs = 1000 * 60 * 60 * 24;
    return new Date(date.getTime() + oneDayInMs).getDate() === 1;
  }
}
</script>
<style>
.day-cell {
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.weekend {
  background-color: rgba(255, 145, 145, 0.2);
}
.darkened {
  background-color: #f5f5f5;
}
.endOfMonth {
  border-right: 3px solid #999;
}

.employee-weekend-1,
.employee-weekend-2 {
  background-image: repeating-linear-gradient(
    135deg,
    rgb(109, 108, 107),
    rgba(61, 60, 60, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
}

.dx-theme-material .name h2 {
  font-size: 10px;
}
.dx-scheduler-cell-sizes-horizontal,
.dx-scheduler-header-panel-cell {
  width: 40px !important;
  max-width: 40px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}

.dx-scheduler-header-panel {
  width: unset;
}
</style>
