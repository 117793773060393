export default class LockNode {
  public readonly pathSplitter = '/';
  public readonly name: string;
  private _isLocked = false;
  private parent: LockNode | undefined = undefined;
  private readonly children: LockNode[];

  constructor(name: string) {
    this.name = name;
    this.children = [];
  }

  public get path(): string {
    if (this.parent == undefined) {
      return '/';
    }

    let path = '';
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let searchGroup: LockNode = this;
    while (searchGroup.parent != undefined) {
      path = `/${searchGroup.name}${path}`;
      searchGroup = searchGroup.parent;
    }
    return path;
  }

  public get isRoot(): boolean {
    return this.parent == undefined;
  }

  public get level(): number {
    let theLevel = 0;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let searchGroup: LockNode = this;
    while (searchGroup.parent != undefined) {
      searchGroup = searchGroup.parent;
      theLevel++;
    }
    return theLevel;
  }

  public get isLocked(): boolean {
    if (this._isLocked) return true;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let searchGroup: LockNode = this;
    while (searchGroup.parent != undefined) {
      searchGroup = searchGroup.parent;
      if (searchGroup._isLocked) return true;
    }

    for (let index = 0; index < this.children.length; index++) {
      const child = this.children[index];
      if (child.isChildActive()) return true;
    }
    return this._isLocked;
  }

  private isChildActive(): boolean {
    if (this._isLocked) return true;
    for (let index = 0; index < this.children.length; index++) {
      const child = this.children[index];
      if (child.isChildActive()) return true;
    }
    return this._isLocked;
  }

  public lock(): void {
    this._isLocked = true;
  }

  public unlock(): void {
    this._isLocked = false;
  }

  public resolve(path: string): LockNode {
    if (path === '/') {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let searchGroup: LockNode = this;
      while (searchGroup.parent != undefined) {
        searchGroup = searchGroup.parent;
      }
      return searchGroup;
    }

    if (path.startsWith(this.pathSplitter)) path = path.substring(1);
    if (path.endsWith(this.pathSplitter)) path = path.substring(0, path.length - 1);
    const splittedPath = path.split(this.pathSplitter);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let root: LockNode = this;
    while (root.parent != undefined) {
      root = root.parent;
    }

    return root.resolveByIndex(splittedPath, 0);
  }

  private resolveByIndex(path: string[], index: number): LockNode {
    const section = path[index];

    if ((section == undefined || section.length == 0) && path.length > index + 1) {
      return this.resolveByIndex(path, index + 1);
    }
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if (child.name === section) {
        if (index == path.length - 1) {
          return child;
        } else {
          return child.resolveByIndex(path, index + 1);
        }
      }
    }

    const newActionGroup = new LockNode(section);
    this.addGroup(newActionGroup);
    if (index == path.length - 1) {
      return newActionGroup;
    } else {
      return newActionGroup.resolveByIndex(path, index + 1);
    }
  }

  private addGroup(group: LockNode): LockNode {
    group.parent = this;
    this.children.push(group);
    return this;
  }
}
