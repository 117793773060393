<template>
  <sidebar
    :sidebar-groups="sidebarGroups"
    logo="/logo.png"
    :folded="false"
    application-name="Rey DIP"
  >
    <topbar :breadcrumbs-from-route="true">
      <div class="c-header-nav">
        <div class="c-header-nav-item">
          <a class="c-header-nav-link" href="/AnleitungReyDIP.pdf" target="_blank">
            <i class="fa fa-question-circle fa-lg mr-2" aria-hidden="true"></i>
          </a>
        </div>
        <div class="c-header-nav-item dropdown show">
          <a
            class="c-header-nav-link"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="true"
          >
            <i class="fa fa-user fa-lg mr-2" aria-hidden="true"></i>
            <span>{{ `${fullname}` }}</span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right dropdown-menu-lg pt-0"
            style="
              position: absolute;
              transform: translate3d(-177px, -16px, 0px);
              top: 0px;
              left: 0px;
              will-change: transform;
            "
            x-placement="top-end"
          >
            <div class="dropdown-header bg-light">
              <strong>{{ `${username}` }}</strong>
            </div>
            <button type="button" class="dropdown-item" @click="$adal.logout()">
              <i class="fa fa-sign-out-alt mr-2" aria-hidden="true"></i>&nbsp; Abmelden
            </button>
          </div>
        </div>
      </div>
    </topbar>
    <main class="c-main">
      <router-view />
    </main>
  </sidebar>
</template>
<script lang="ts">
import Component from 'vue-class-component';
import { Sidebar, SidebarGroup, SidebarEntry, Topbar } from 'rey-smartmes-ui';
import AuthRoleMixin from '@/mixins/AuthRoleMixin';

@Component({
  components: { Sidebar, Topbar }
})
export default class App extends AuthRoleMixin {
  public sidebarGroups = [
    new SidebarGroup('FERIENPLANUNG', [
      new SidebarEntry('Meine Ferien', 'fas fa-file-medical', '/vacations'),
      new SidebarEntry('Archiv', 'fas fa-archive', '/employee-archive')
    ]),
    new SidebarGroup('PIKETTPLANUNG', [
      new SidebarEntry('Automation', 'fas fa-microchip', '/standbyduty/automation'),
      new SidebarEntry('Rey Digital', 'fas fa-code', '/standbyduty/software-service')
    ]),
    new SidebarGroup('RESSOURCENPLANUNG', [
      new SidebarEntry('Übersicht', 'fas fa-project-diagram', '/resourceplanning/overview'),
      new SidebarEntry('Timeline', 'fas fa-user-clock', '/resourceplanning/timeline')
    ])
  ];

  public created(): void {
    if (this.isExecutive) {
      this.sidebarGroups.push(
        new SidebarGroup('REYDIP VORGESETZTER', [
          new SidebarEntry('Ferienanträge freigeben', 'fas fa-file-signature', '/decisions'),
          new SidebarEntry('Ferienübersicht Team', 'fas fa-users', '/vacation-overview-team'),
          new SidebarEntry('Ferienübersicht Alle', 'fas fa-list', '/vacation-overview-all'),
          new SidebarEntry('Archiv Alle MA', 'fas fa-archive', '/executive-archive')
        ])
      );
    }
  }

  public get fullname(): string {
    return this.isAuthenticated.profile.name || '';
  }
  public get username(): string {
    return this.isAuthenticated.userName || '';
  }
}
</script>
<style lang="scss">
/* Don't remove this comment! */
</style>
