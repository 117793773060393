import Vue from 'vue';

export default class AuthRoleMixin extends Vue {
  public get isExecutive(): boolean {
    return (
      this.isAuthenticated.profile.roles !== undefined &&
      this.isAuthenticated.profile.roles.length === 1 &&
      this.isAuthenticated.profile.roles[0] === 'Executive'
    );
  }
}
