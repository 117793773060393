<template>
  <div>
    <GenericFormDialog
      :visible="resourcePlanning.showDialog"
      :model="resourcePlanning.dialogModel"
    ></GenericFormDialog>
    <div v-if="resourcePlanning.showFilterDialog">
      <FilterDialog @hide="onHideFilterDialog()" @filter-changed="onFilterChanged()"></FilterDialog>
    </div>
    <GenericWizardDialog
      :visible="resourcePlanning.showWizard"
      :model="resourcePlanning.wizardModel"
    ></GenericWizardDialog>

    <div class="container">
      <div class="row">
        <div
          :class="
            showAssignmentDetails ||
            showActivityDetails ||
            showSubprojectDetails ||
            showProjectDetails
              ? 'col-9'
              : 'col-12'
          "
        >
          <content-card title="Timeline" cardIcon="fas fa-user-clock">
            <template v-slot:body>
              <div
                v-if="
                  showAssignmentDetails ||
                  showActivityDetails ||
                  showSubprojectDetails ||
                  showProjectDetails
                "
                class="expandIcon"
                @click="onTimelineWidgetTaskChanged"
              >
                <em class="fas fa-angle-double-right"></em>
              </div>
              <div>
                <ToolkitMenubar>
                  <ToolkitMenu :model="resourcePlanning.menuFilter.model"></ToolkitMenu>
                  <ToolkitMenu :model="resourcePlanning.menuProject.model"></ToolkitMenu>
                  <ToolkitMenu :model="resourcePlanning.menuCreateAssignment.model"></ToolkitMenu>
                  <ToolkitMenu :model="resourcePlanning.menuFavro.model"></ToolkitMenu>
                </ToolkitMenubar>
                <TimelineWidget
                  widgetId="timelineWidget"
                  ref="timelineWidget"
                  :selectedTask="selectedTask"
                  :timelineModel="resourcePlanning.timelineModel.timelineData"
                  :timelineConfiguration="resourcePlanning.timelineModel.timelineConfiguration"
                  :appointmentContextMenuItems="appointmentContextMenuItems"
                  @task-changed="onTimelineWidgetTaskChanged($event)"
                  @task-selected="onTimelineWidgetTaskSelected($event)"
                ></TimelineWidget>
              </div>
            </template>
          </content-card>
        </div>
        <div
          v-if="
            showAssignmentDetails ||
            showActivityDetails ||
            showSubprojectDetails ||
            showProjectDetails
          "
          class="col-3"
        >
          <content-card v-if="showAssignmentDetails" title="Buchung" cardIcon="fas fa-list">
            <template v-slot:body>
              <AssignmentDetails></AssignmentDetails>
            </template>
          </content-card>
          <content-card v-if="showActivityDetails" title="Abteilung" cardIcon="fas fa-list">
            <template v-slot:body>
              <ActivityDetails></ActivityDetails>
            </template>
          </content-card>
          <content-card v-if="showSubprojectDetails" title="Teilprojekt" cardIcon="fas fa-list">
            <template v-slot:body>
              <SubprojectDetails></SubprojectDetails>
            </template>
          </content-card>
          <content-card v-if="showProjectDetails" title="Projekt" cardIcon="fas fa-list">
            <template v-slot:body>
              <ProjectDetails></ProjectDetails>
            </template>
          </content-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ContentCard } from 'rey-smartmes-ui';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
import ResourcePlanningModel from '@/models/resourcePlanning/ResourcePlanningModel';
import TimelineWidget from './timeline2/TimelineWidget.vue';
import Observer from '@/models/resourcePlanning/util/Observer';
import TimelineTaskDto from '@/models/resourcePlanning/timeline/TimelineTaskDto';
import { AssignmentDto } from '@/models/resourcePlanning/dtos/AssignmentDto';
import ITimelineTaskSelectionChangeEvent from '@/models/resourcePlanning/events/IAssignmentSelectionChangeEvent';
import GenericFormDialog from '@/components/genericForm/GenericFormDialog.vue';
import GenericWizardDialog from '@/components/genericForm/GenericWizardDialog.vue';
import ToolkitMenubar from '@/components/buttons/ToolkitMenubar.vue';
import ToolkitMenu from '@/components/buttons/ToolkitMenu.vue';
import AssignmentDetails from './AssignmentDetails.vue';
import SubprojectDetails from './SubprojectDetails.vue';
import ProjectDetails from './ProjectDetails.vue';
import clone from '@/models/resourcePlanning/util/Clone';
import { UpdateAssignmentDto } from '@/models/resourcePlanning/dtos/UpdateAssignmentDto';
import IAssignmentChangeEvent from '@/models/resourcePlanning/events/IAssignmentChangeEvent';
import IContextMenuItem from '@/models/resourcePlanning/contexMenu/IContextMenuItem';
import ActivityDetails from './ActivityDetails.vue';
import FilterDialog from './FilterDialog.vue';

@Component({
  components: {
    ContentCard,
    TimelineWidget,
    GenericFormDialog,
    GenericWizardDialog,
    ToolkitMenubar,
    ToolkitMenu,
    AssignmentDetails,
    ActivityDetails,
    SubprojectDetails,
    ProjectDetails,
    FilterDialog
  }
})
export default class ResourceTimeline extends Vue {
  public model: ModelRoot = this.$model;
  public resourcePlanning: ResourcePlanningModel = this.$model.resourcePlanning;

  public showAssignmentDetails = false;
  public showActivityDetails = false;
  public showSubprojectDetails = false;
  public showProjectDetails = false;

  public selectedTask: TimelineTaskDto = {} as TimelineTaskDto;

  public appointmentContextMenuItems: IContextMenuItem[] = [
    {
      text: 'Duplizieren',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuDuplicateAssinment(event.targetedAppointmentData.assignmentId)
    },
    {
      text: 'Löschen',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuRemoveAssinment(event.targetedAppointmentData.assignmentId)
    },
    {
      text: '1',
      color: '#2b6b9c',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 1)
    },
    {
      text: '2',
      color: '#5c8fb7',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 2)
    },
    {
      text: '3',
      color: '#117010',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 3)
    },
    {
      text: '4',
      color: '#469e45',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 4)
    },
    {
      text: '5',
      color: '#694a06',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 5)
    },
    {
      text: '6',
      color: '#94732c',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 6)
    },
    {
      text: '7',
      color: '#ffee00',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 7)
    },
    {
      text: '8',
      color: '#faf5b4',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 8)
    },
    {
      text: '9',
      color: '#d000ff',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 9)
    },
    {
      text: '10',
      color: '#e15cff',
      onItemClick: async (event: any): Promise<void> =>
        this.onContextMenuSelectColor(event.targetedAppointmentData.assignmentId, 10)
    }
  ];

  public onHideFilterDialog(): void {
    this.resourcePlanning.showFilterDialog = false;
  }

  public async onFilterChanged(): Promise<void> {
    await this.resourcePlanning.timelineModel.reloadTimelineData();
  }

  public async onContextMenuDuplicateAssinment(assignmentId: number): Promise<void> {
    await this.resourcePlanning.timelineModel.duplicateAssignment(assignmentId);
  }

  public async onContextMenuRemoveAssinment(assignmentId: number): Promise<void> {
    await this.resourcePlanning.timelineModel.removeAssignment(assignmentId);
  }

  public async onContextMenuSelectColor(assignmentId: number, colorCode: number): Promise<void> {
    await this.resourcePlanning.timelineModel.setAssignmentColor(assignmentId, colorCode);
  }

  public assignmentSelectionChangedEventHandler: Observer<ITimelineTaskSelectionChangeEvent> = {
    update: (event) => {
      this.onAssignmentSelectionChanged(event);
    }
  };

  public refreshTimelineEventHandler: Observer<boolean> = {
    update: () => {
      const timeLineWidget: TimelineWidget = this.$refs['timelineWidget'] as TimelineWidget;
      timeLineWidget.refresh();
    }
  };

  public async mounted(): Promise<void> {
    this.resourcePlanning.eventAssignmentSelectionChanged.addObserver(
      this.assignmentSelectionChangedEventHandler
    );

    this.resourcePlanning.timelineModel.eventRefereshTimeline.addObserver(
      this.refreshTimelineEventHandler
    );

    await this.resourcePlanning.timelineModel
      .loadTimelineData(new Date(2021, 1, 1), new Date(2023, 1, 1))
      .catch((e) => {
        console.error('nidi(error 14) load Timeline Data failed!', e);
      });
    await this.resourcePlanning.loadEmployees().catch((e) => {
      console.error('nidi(error 15) load Employees failed!', e);
    });

    await this.resourcePlanning.loadDepartments().catch((e) => console.error(e));
  }

  public beforeDestroy(): void {
    this.resourcePlanning.eventAssignmentSelectionChanged.removeObserver(
      this.assignmentSelectionChangedEventHandler
    );

    this.resourcePlanning.timelineModel.eventRefereshTimeline.removeObserver(
      this.refreshTimelineEventHandler
    );
  }

  public async onTimelineWidgetTaskChanged(event: any): Promise<void> {
    // close side-boxes
    if (event.target.className) {
      this.selectedTask = {} as TimelineTaskDto;
      this.showAssignmentDetails = false;
      this.showActivityDetails = false;
      this.showSubprojectDetails = false;
      this.showProjectDetails = false;
      return;
    }

    this.selectedTask = event.appointmentData as TimelineTaskDto;
    if (!this.resourcePlanning.hasSelectedAssignment) {
      await this.resourcePlanning.selectAssignment(this.selectedTask.assignmentId);
    }

    const activeAssignmentClone: AssignmentDto = clone(this.resourcePlanning.selectedAssignment);
    activeAssignmentClone.employeeId = this.selectedTask.id;
    activeAssignmentClone.employee = undefined;
    activeAssignmentClone.beginDate = this.selectedTask.startDate;
    activeAssignmentClone.endDate = this.selectedTask.endDate;

    const updateRequest: UpdateAssignmentDto = {
      origin: this.resourcePlanning.selectedAssignment,
      update: activeAssignmentClone
    };

    await this.resourcePlanning.updateBackendAssignment(updateRequest);
    await this.resourcePlanning.selectAssignment(
      this.resourcePlanning.selectedAssignment.assignmentId
    );
  }

  public onTimelineWidgetTaskSelected(event: any): void {
    this.selectedTask = event.appointmentData as TimelineTaskDto;
    this.resourcePlanning.selectAssignment(this.selectedTask.assignmentId);
  }

  private onAssignmentSelectionChanged(event: ITimelineTaskSelectionChangeEvent | undefined): void {
    this.showAssignmentDetails = false;
    this.showActivityDetails = false;
    this.showSubprojectDetails = false;
    this.showProjectDetails = false;

    if (event == undefined) {
      return;
    }

    if (event.hasSelectedAssignment) {
      this.showAssignmentDetails = true;
      this.showActivityDetails = event.hasSelectedActivity;
      this.showSubprojectDetails = event.hasSelectedSubproject;
      this.showProjectDetails = event.hasSelectedProject;
    }
  }
}
</script>
