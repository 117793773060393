import { IToolkitMenuModel } from '@/components/buttons/IToolkitMenuModel';
import ResourcePlanning from '../ResourcePlanningModel';

export default class CreateAssignmentMenu {
  public readonly resourcePlanning: ResourcePlanning;

  public model: IToolkitMenuModel = {
    command: {
      id: 'Assignments',
      name: 'Buchungen',
      icon: 'fas fa-user-clock',
      execute: () => undefined
    },
    getEntries: () =>
      Promise.resolve([
        {
          id: 'AddEmployee',
          name: 'Buchung hinzufügen',
          icon: 'fas fa-user-plus',
          group: 'root',
          disabled: () => false,
          execute: () => {
            this.resourcePlanning.dialogAddTimelineAssignment.show();
          }
        },
        {
          id: 'RemoveEmployee',
          name: 'Buchung entfernen',
          icon: 'fas fa-user-minus',
          group: 'root',
          disabled: () =>
            this.resourcePlanning.selectedAssignment == undefined ||
            this.resourcePlanning.selectedAssignment.assignmentId == undefined,
          execute: () => {
            if (
              this.resourcePlanning.selectedAssignment == undefined ||
              this.resourcePlanning.selectedAssignment.assignmentId == undefined
            ) {
              return;
            }
            this.resourcePlanning.dialogDeleteTimelineAssignment.show();
          }
        }
      ])
  };

  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }
}
