export interface IValueContainer {
  get value(): unknown;
  set value(val: unknown);
}

export class ValueContainer implements IValueContainer {
  private getter: () => unknown;
  private setter: (value: unknown) => void;
  constructor(getter: () => unknown, setter: (value: unknown) => void) {
    this.getter = getter;
    this.setter = setter;
  }
  get value(): unknown {
    if (!this.getter) {
      return undefined;
    }
    return this.getter();
  }
  set value(val: unknown) {
    if (!this.setter) {
      return;
    }
    this.setter(val);
  }

  public setSetter(setter: (value: unknown) => void): ValueContainer {
    this.setter = setter;
    return this;
  }

  public setGetter(getter: () => unknown): ValueContainer {
    this.getter = getter;
    return this;
  }
}
