import { AxiosInstance } from 'axios';
import { AssignmentDto } from '../dtos/AssignmentDto';
import { EmployeeDto } from '../dtos/EmployeeDto';
import { UpdateAssignmentDto } from '../dtos/UpdateAssignmentDto';
import GanttTaskDto from '../gantt/GanttTaskDto';
import ResourcePlanningModel from '../ResourcePlanningModel';
import TimelineTaskDto from '../timeline/TimelineTaskDto';
import { mapAssignmentToGanttTask } from '../util/Mapper';

export class EmployeeService {
  private readonly resourcePlanning: ResourcePlanningModel;
  private readonly axios: AxiosInstance;
  constructor(resourcePlanning: ResourcePlanningModel) {
    this.resourcePlanning = resourcePlanning;
    this.axios = resourcePlanning.axios;
  }

  private readonly nullEmployee: EmployeeDto = {
    employeeId: 0,
    name: '',
    shortName: '',
    email: ''
  };

  public getEmployees(): Promise<EmployeeDto[]> {
    return this.axios
      .get(`/api/resourceplanning/employees`)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.warn('Get employees failed!', error);
      });
  }

  public getEmployee(id: string | undefined): Promise<EmployeeDto> {
    if (id == undefined) {
      return Promise.resolve(this.nullEmployee);
    }
    return this.axios
      .get(`/api/resourceplanning/employee/${id}`)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.warn(`Get employee ${id} failed!`, error);
      });
  }

  public postAssignment(assignment: AssignmentDto): Promise<AssignmentDto> {
    return this.axios
      .post(`/api/resourceplanning/assignment`, assignment)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.warn(`Create assignment failed!`, error);
      });
  }

  public async saveAssignment(updateRequest: UpdateAssignmentDto): Promise<AssignmentDto> {
    if (updateRequest.update.assignmentId <= 0) {
      return Promise.reject();
    }

    return this.putAssignment(updateRequest).then((assignment) => {
      if (this.resourcePlanning.selectedAssignment?.assignmentId === assignment.assignmentId) {
        this.resourcePlanning.selectedAssignment = assignment;
        this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);
      }
      return assignment;
    });
  }

  public async putAssignment(
    update: UpdateAssignmentDto,
    raiseUpdateChartEvent = true
  ): Promise<AssignmentDto> {
    try {
      const result = await this.axios.put(
        `/api/resourceplanning/assignment/${update.origin.assignmentId}`,
        update
      );
      const assignment: AssignmentDto = result.data;
      if (assignment.assignmentId === this.resourcePlanning.selectedAssignment?.assignmentId) {
        await this.resourcePlanning.selectAssignment(assignment.assignmentId);

        this.updateTimelineTasks(assignment, raiseUpdateChartEvent);
        this.updateGanttTasks(assignment);
        this.notifyGanttTaskSelectionChanged(this.resourcePlanning.ganttModel.selectedGanttTask.id);
      }

      return assignment;
    } catch (error) {
      console.warn(`nidi(101error) Update assignment failed!`, error);
      return Promise.reject();
    }
  }

  private updateTimelineTasks(assignment: AssignmentDto, raiseUpdateChartEvent = true): void {
    const timelineTask: TimelineTaskDto | undefined =
      this.resourcePlanning.timelineModel.getTimelineTaskForAssignmentId(assignment.assignmentId);
    if (timelineTask != undefined) {
      timelineTask.startDate = new Date(assignment.beginDate);
      timelineTask.endDate = new Date(assignment.endDate);
      timelineTask.taskError = assignment.isInvalid;
      if (raiseUpdateChartEvent) {
        this.resourcePlanning.timelineModel.eventRefereshTimeline.notify(true);
      }
    }
  }

  private updateGanttTasks(assignment: AssignmentDto): void {
    const id = `${assignment.subjectId}-${assignment.assignmentId}`;
    const ganttTask = this.resourcePlanning.ganttModel.getGanttDataTask(id);
    if (ganttTask != null) {
      mapAssignmentToGanttTask(assignment, ganttTask);
    }
  }

  public getAssignmentsBySubjectId(subjectId: string): Promise<AssignmentDto[]> {
    return this.axios
      .get(`/api/resourceplanning/assignments/subject/${subjectId}`)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.warn(`Get assignments for task ${subjectId} failed!`, error);
      });
  }

  public getAssignment(assignmentId: string | number): Promise<AssignmentDto> {
    return this.axios
      .get(`/api/resourceplanning/assignment/${assignmentId}`)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        console.warn(`(nidi(error 3) Get assignment ${assignmentId} failed!`, error);
      });
  }

  public getAssignmentId(task: GanttTaskDto): string {
    const assignmentIdIndex = task.id.indexOf('-') + 1;
    return task.id.substring(assignmentIdIndex);
  }

  public addAssignmentToGannt(assignment: AssignmentDto): void {
    if (!this.resourcePlanning.ganttModel.ganttData) {
      return;
    }

    const assignmentTask: GanttTaskDto = {
      id: `${assignment.subjectId}-${assignment.assignmentId}`,
      title: assignment.employee?.shortName as string,
      start: assignment.beginDate,
      end: assignment.endDate,
      subjectType: 1000,
      progress: 0,
      color: '#994d00',
      isAssignment: true,
      parentId: `${assignment.subjectId}`,
      treeId: assignment.subject?.treeId
    };
    for (let i = 0; i < this.resourcePlanning.ganttModel.ganttData?.tasks.length; i++) {
      const task = this.resourcePlanning.ganttModel.ganttData?.tasks[i];
      if (task.id === `${assignment.subjectId}`) {
        this.resourcePlanning.ganttModel.ganttData.tasks.splice(i, 0, assignmentTask);
        break;
      }
    }
  }

  private notifyGanttTaskSelectionChanged(taskId?: string): void {
    const ganttTask = this.resourcePlanning.ganttModel.getGanttDataTask(taskId);
    if (ganttTask != undefined) {
      this.resourcePlanning.ganttModel.eventGanttTaskSelectionChanged.notify({
        hasSelection: true,
        selection: ganttTask as GanttTaskDto
      });
    } else {
      this.resourcePlanning.ganttModel.eventGanttTaskSelectionChanged.notify({
        hasSelection: false,
        selection: {} as GanttTaskDto
      });
    }
  }
}
