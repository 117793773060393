<template>
  <div>
    <div v-if="visible">
      <DxPopup
        :visible="true"
        :drag-enabled="false"
        :hide-on-outside-click="false"
        :show-close-button="false"
        :show-title="true"
        :width="width"
        :height="height"
        :title="title"
      >
        <div>
          <generic-form :model="model"></generic-form>
        </div>

        <table class="table table-borderless">
          <tbody>
            <tr>
              <td align="left">
                <div class="d-flex flex-row justify-content-start"></div>
              </td>
              <td align="center">
                <div class="d-flex flex-row justify-content-center">
                  <div v-if="model.onYes != undefined" class="px-1">
                    <ToolkitButton :action="yesButtonAction"></ToolkitButton>
                  </div>
                  <div v-if="model.onNo != undefined" class="px-1">
                    <ToolkitButton :action="noButtonAction"></ToolkitButton>
                  </div>
                </div>
              </td>
              <td align="right">
                <div class="d-flex flex-row justify-content-end">
                  <div v-if="model.onCancel != undefined" class="px-1">
                    <ToolkitButton :action="cancelButtonAction"></ToolkitButton>
                  </div>
                  <div v-if="model.onSave != undefined" class="px-1">
                    <ToolkitButton :action="saveButtonAction"></ToolkitButton>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </DxPopup>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';

import GenericForm from '../genericForm/GenericForm.vue';
import { IToolkitCommand } from '../buttons/IToolkitCommand';
import ToolkitButton from '../buttons/ToolkitButton.vue';
import { IGenericFormModel } from './IGenericFormModel';

@Component({
  components: {
    DxPopup,
    DxToolbarItem,
    GenericForm,
    ToolkitButton
  }
})
export default class GenericFormDialog extends Vue {
  @Prop() visible!: boolean;
  @Prop() model!: IGenericFormModel;

  public cancelButtonAction: IToolkitCommand = {
    name: 'Abbrechen',
    disabled: () => false,
    group: 'root',
    execute: () => this.onCancel()
  };

  private onCancel(): void | Promise<void> {
    if (this.model.onCancel) {
      return this.model.onCancel(this.model);
    }
  }

  public saveButtonAction: IToolkitCommand = {
    name: 'Speichern',
    disabled: () => false,
    group: 'root',
    execute: () => this.onSave()
  };

  private onSave(): void | Promise<void> {
    if (this.model.onSave) {
      return this.model.onSave(this.model);
    }
  }

  public yesButtonAction: IToolkitCommand = {
    name: 'Ja',
    disabled: () => false,
    group: 'root',
    execute: () => this.onYes()
  };

  private onYes(): void | Promise<void> {
    if (this.model.onYes) {
      return this.model.onYes(this.model);
    }
  }

  public noButtonAction: IToolkitCommand = {
    name: 'Nein',
    disabled: () => false,
    group: 'root',
    execute: () => this.onNo()
  };

  private onNo(): void | Promise<void> {
    if (this.model.onNo) {
      return this.model.onNo(this.model);
    }
  }

  public get title(): string | undefined {
    if (this.model.options && this.model.options.title) {
      return this.model.options.title;
    }
    return undefined;
  }

  public get width(): number | undefined {
    if (this.model.options && this.model.options.width) {
      return this.model.options.width;
    }
    return undefined;
  }

  public get height(): number | undefined {
    if (this.model.options && this.model.options.height) {
      return this.model.options.height;
    }
    return undefined;
  }
}
</script>
<style lang="scss">
.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}
</style>
