import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VacationPlanning from '../views/VacationPlanning.vue';
import EmployeeArchive from '../views/EmployeeArchive.vue';
import ExecutiveArchive from '../views/ExecutiveArchive.vue';
import VacationDecisions from '../views/VacationDecisions.vue';
import VacationOverviewTeam from '../views/VacationOverviewTeam.vue';
import VacationOverviewAll from '../views/VacationOverviewAll.vue';
import StandbyDutyAutomation from '../views/StandbyDutyAutomation.vue';
import StandbyDutySoftwareService from '../views/StandbyDutySoftwareService.vue';
import ResourcePlanning from '../views/resourcePlanning/ResourcePlanning.vue';
import ResourceTimeline from '../views/resourcePlanning/ResourceTimeline.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    alias: '/',
    path: '/vacations',
    name: 'Vacations',
    meta: {
      requireAuth: true,
      breadcrumbs: ['Ferienplanung', 'Meine Ferien']
    },
    props: true,
    component: VacationPlanning
  },
  {
    path: '/employee-archive',
    name: 'employee-archive',
    meta: {
      requireAuth: true,
      breadcrumbs: ['Ferienplanung', 'Archiv']
    },
    props: true,
    component: EmployeeArchive
  },
  {
    path: '/decisions',
    name: 'Decisions',
    meta: {
      requireAuth: true,
      allowedRoles: ['Executive'],
      breadcrumbs: ['ReyDIP Vorgesetzter', 'Ferienanträge freigeben']
    },
    props: true,
    component: VacationDecisions
  },
  {
    path: '/executive-archive',
    name: 'executive-archive',
    meta: {
      requireAuth: true,
      allowedRoles: ['Executive'],
      breadcrumbs: ['ReyDIP Vorgesetzter', 'Ferienarchiv Alle MA']
    },
    props: true,
    component: ExecutiveArchive
  },
  {
    path: '/vacation-overview-team',
    name: 'vacation-overview-team',
    meta: {
      requireAuth: true,
      allowedRoles: ['Executive'],
      breadcrumbs: ['ReyDIP Vorgesetzter', 'Ferienübersicht Team']
    },
    props: true,
    component: VacationOverviewTeam
  },
  {
    path: '/vacation-overview-all',
    name: 'vacation-overview-all',
    meta: {
      requireAuth: true,
      allowedRoles: ['Executive'],
      breadcrumbs: ['ReyDIP Vorgesetzter', 'Ferienübersicht Alle']
    },
    props: true,
    component: VacationOverviewAll
  },
  {
    path: '/standbyduty/software-service',
    meta: {
      requireAuth: true,
      breadcrumbs: ['Pikettplanung', 'Software Service']
    },
    props: true,
    component: StandbyDutySoftwareService
  },
  {
    path: '/standbyduty/automation',
    meta: {
      requireAuth: true,
      breadcrumbs: ['Pikettplanung', 'Automation']
    },
    props: true,
    component: StandbyDutyAutomation
  },
  {
    path: '/resourceplanning/overview',
    meta: {
      requireAuth: true,
      breadcrumbs: ['Ressourcenplanung', 'Übersicht']
    },
    props: true,
    component: ResourcePlanning
  },
  {
    path: '/resourceplanning/timeline',
    meta: {
      requireAuth: true,
      breadcrumbs: ['Ressourcenplanung', 'Timeline']
    },
    props: true,
    component: ResourceTimeline
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const allowedRoles = to && to.meta ? to.meta.allowedRoles || null : null;
  if (allowedRoles != null && allowedRoles.length > 0) {
    const roles = Vue.prototype.$adal.user.profile.roles;

    if (roles === undefined || roles.includes(allowedRoles)) {
      next({ path: '/' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
