import { AxiosInstance } from 'axios';
import NewProjectWizard from './dialogs/NewProjectWizard';
import { SubjectService } from './services/SubjectService';
import ProjectMenu from './menus/ProjectMenu';
import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import { IGenericWizardModel } from '@/components/genericForm/IGenericWizardModel';
import Subject from './util/Subject';
import EditProjectDialog from './dialogs/EditProjectDialog';
import EditActivityDialog from './dialogs/EditActivityDialog';
import DeleteProjectDialog from './dialogs/DeleteProjectDialog';
import DeleteActivityDialog from './dialogs/DeleteActivityDialog';
import AssignmentsMenu from './menus/AssignmentsMenu';
import AssignEmployeeDialog from './dialogs/AssignEmployeeDialog';
import DeleteAssignmentDialog from './dialogs/DeleteAssignmentDialog';
import { EmployeeService } from './services/EmployeeService';
import { AssignmentDto } from './dtos/AssignmentDto';
import { SubjectDto } from './dtos/SubjectDto';
import { UpdateAssignmentDto } from './dtos/UpdateAssignmentDto';
import { EmployeeDto } from './dtos/EmployeeDto';
import FavroMenu from './menus/FavroMenu';

import IAssignmentSelectionChangeEvent from './events/IAssignmentSelectionChangeEvent';
import AssignmentInfoDto from './dtos/AssignmentInfoDto';
import GanttModel from './GanttModel';
import TimelineModel from './TimelineModel';
import IAssignmentChangeEvent from './events/IAssignmentChangeEvent';
import CreateAssignmentMenu from './menus/CreateAssignmentMenu';
import AddTimelineAssignmentDialog from './dialogs/AddTimelineAssignmentDialog';
import DeleteTimelineAssignmentDialog from './dialogs/DeleteTimelineAssignmentDialog';
import FilterMenu from './menus/FilterMenu';
import { FilterService } from './services/FilterService';
import { WebStorageService } from './services/WebStorageService';
import { DepartmentDto } from './dtos/DepartmentDto';

export default class ResourcePlanningModel {
  public readonly axios: AxiosInstance;

  public readonly ganttModel: GanttModel;
  public readonly timelineModel: TimelineModel;

  public employees: EmployeeDto[] = [] as EmployeeDto[];

  public departments: DepartmentDto[] = [] as DepartmentDto[];

  public selectedAssignment: AssignmentDto = {} as AssignmentDto;
  public hasSelectedAssignment = false;

  public selectedSubproject: SubjectDto = {} as SubjectDto;
  public hasSelectedSubproject = false;

  public selectedProject: SubjectDto = {} as SubjectDto;
  public hasSelectedProject = false;

  public selectedActivity: SubjectDto = {} as SubjectDto;
  public hasSelectedActivity = false;

  public showDialog = false;
  public dialogModel: IGenericFormModel = {} as IGenericFormModel;

  public showWizard = false;
  public wizardModel: IGenericWizardModel = {} as IGenericWizardModel;

  public showFilterDialog = false;

  public readonly eventAssignmentSelectionChanged: Subject<IAssignmentSelectionChangeEvent> =
    new Subject();

  public readonly eventAssignmentChanged: Subject<IAssignmentChangeEvent> = new Subject();

  public readonly subjectService: SubjectService;
  public readonly employeeService: EmployeeService;
  public readonly filterService: FilterService;
  public readonly webStorageService: WebStorageService;

  public readonly menuProject: ProjectMenu;
  public readonly menuAssignments: AssignmentsMenu;
  public readonly menuCreateAssignment: CreateAssignmentMenu;
  public readonly menuFavro: FavroMenu;
  public readonly menuFilter: FilterMenu;

  public readonly wizardNewProject: NewProjectWizard;
  public readonly dialogEditProject: EditProjectDialog;
  public readonly dialogEditActivity: EditActivityDialog;
  public readonly dialogDeleteProject: DeleteProjectDialog;
  public readonly dialogDeleteActivity: DeleteActivityDialog;
  public readonly dialogAssignEmployee: AssignEmployeeDialog;
  public readonly dialogDeleteAssignment: DeleteAssignmentDialog;
  public readonly dialogAddTimelineAssignment: AddTimelineAssignmentDialog;
  public readonly dialogDeleteTimelineAssignment: DeleteTimelineAssignmentDialog;

  constructor(axios: AxiosInstance, webStorageService: WebStorageService) {
    this.axios = axios;
    this.webStorageService = webStorageService;

    this.ganttModel = new GanttModel(this);
    this.timelineModel = new TimelineModel(this);

    this.subjectService = new SubjectService(this);
    this.employeeService = new EmployeeService(this);
    this.filterService = new FilterService(this);

    this.menuProject = new ProjectMenu(this);
    this.menuAssignments = new AssignmentsMenu(this);
    this.menuCreateAssignment = new CreateAssignmentMenu(this);
    this.menuFavro = new FavroMenu(this);
    this.menuFilter = new FilterMenu(this);

    this.wizardNewProject = new NewProjectWizard(this);

    this.dialogEditProject = new EditProjectDialog(this);
    this.dialogEditActivity = new EditActivityDialog(this);
    this.dialogDeleteProject = new DeleteProjectDialog(this);
    this.dialogDeleteActivity = new DeleteActivityDialog(this);
    this.dialogAssignEmployee = new AssignEmployeeDialog(this);
    this.dialogDeleteAssignment = new DeleteAssignmentDialog(this);
    this.dialogAddTimelineAssignment = new AddTimelineAssignmentDialog(this);
    this.dialogDeleteTimelineAssignment = new DeleteTimelineAssignmentDialog(this);
  }

  public getEmployeeByName(name: string): EmployeeDto | undefined {
    for (const employee of this.employees) {
      if (employee.name === name) {
        return employee;
      }
    }
    return undefined;
  }

  public async updateBackendAssignment(updateRequest: UpdateAssignmentDto): Promise<AssignmentDto> {
    if (updateRequest.update.assignmentId <= 0) {
      return Promise.reject();
    }
    if (!this.ganttModel.ganttData) {
      return Promise.reject();
    }

    const assignment = await this.employeeService.putAssignment(updateRequest);
    if (this.selectedAssignment?.assignmentId === assignment.assignmentId) {
      this.selectedAssignment = assignment;
    }
    return assignment;
  }

  public loadEmployees(): Promise<EmployeeDto[]> {
    return this.axios.get(`/api/resourceplanning/employees`).then((res) => {
      if (res.data == undefined) {
        this.employees = [] as EmployeeDto[];
      } else {
        this.employees = res.data;
      }
      return this.employees;
    });
  }

  public loadDepartments(): Promise<DepartmentDto[]> {
    return this.axios.get(`/api/resourceplanning/departments`).then((res) => {
      if (res.data == undefined) {
        this.departments = [] as DepartmentDto[];
      } else {
        this.departments = res.data;
      }
      return this.departments;
    });
  }

  public async selectAssignment(assignmentId?: number): Promise<void> {
    this.clearSelection();

    if (assignmentId == undefined) {
      this.eventAssignmentSelectionChanged.notify({
        selectedAssignment: this.selectedAssignment,
        hasSelectedAssignment: this.hasSelectedAssignment,
        selectedActivity: this.selectedActivity,
        hasSelectedActivity: this.hasSelectedActivity,
        selectedSubproject: this.selectedSubproject,
        hasSelectedSubproject: this.hasSelectedSubproject,
        selectedProject: this.selectedProject,
        hasSelectedProject: this.hasSelectedProject
      });
      return;
    }
    const assignmentInfo = await this.loadAssignmentInfo(assignmentId ?? -1);
    if (assignmentInfo != undefined) {
      this.selectedAssignment = assignmentInfo.assignment;
      this.hasSelectedAssignment = assignmentInfo.assignment != undefined;

      this.selectedActivity = assignmentInfo.activity ?? ({} as SubjectDto);
      this.hasSelectedActivity = assignmentInfo.activity != undefined;

      this.selectedProject = assignmentInfo.project ?? ({} as SubjectDto);
      this.hasSelectedProject = assignmentInfo.project != undefined;

      this.selectedSubproject = assignmentInfo.subproject ?? ({} as SubjectDto);
      this.hasSelectedSubproject = assignmentInfo.subproject != undefined;
    }

    this.eventAssignmentSelectionChanged.notify({
      selectedAssignment: this.selectedAssignment,
      hasSelectedAssignment: this.hasSelectedAssignment,
      selectedActivity: this.selectedActivity,
      hasSelectedActivity: this.hasSelectedActivity,
      selectedSubproject: this.selectedSubproject,
      hasSelectedSubproject: this.hasSelectedSubproject,
      selectedProject: this.selectedProject,
      hasSelectedProject: this.hasSelectedProject
    });
  }

  public clearSelection(): void {
    this.selectedAssignment = {} as AssignmentDto;
    this.hasSelectedAssignment = false;

    this.selectedActivity = {} as SubjectDto;
    this.hasSelectedActivity = false;

    this.selectedSubproject = {} as SubjectDto;
    this.hasSelectedSubproject = false;

    this.selectedProject = {} as SubjectDto;
    this.hasSelectedProject = false;
  }

  public loadAssignmentInfo(assignmentId: number): Promise<AssignmentInfoDto | undefined> {
    return this.axios.get(`/api/resourceplanning/AssignmentInfo/${assignmentId}`).then((res) => {
      return res.data as AssignmentInfoDto;
    });
  }
}
