<template>
  <div>
    <DxTagBox
      :disabled="model.disabled"
      :width="getString(model.options, 'width', '200')"
      :search-enabled="true"
      :items="model.options.tags()"
      :value.sync="content.value"
    ></DxTagBox>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxTagBox from 'devextreme-vue/tag-box';
import { GenericFormItem } from '../genericForm/GenericFormItem';
import { IValueContainer } from '../genericForm/ValueContainer';
import { getString } from '../genericForm/OptionsAccessors';

@Component({
  components: {
    DxTagBox
  }
})
export default class TagBox extends Vue {
  @Prop()
  public model!: GenericFormItem;
  @Prop()
  public content!: IValueContainer;

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    return getString(options, name, defaultValue);
  }
}
</script>
