<template>
  <div>
    <GenericFormDialog
      :visible="resourcePlanning.showDialog"
      :model="resourcePlanning.dialogModel"
    ></GenericFormDialog>
    <div v-if="resourcePlanning.showFilterDialog">
      <FilterDialog @hide="onHideFilterDialog()" @filter-changed="onFilterChanged()"></FilterDialog>
    </div>
    <GenericWizardDialog
      :visible="resourcePlanning.showWizard"
      :model="resourcePlanning.wizardModel"
    ></GenericWizardDialog>

    <div class="container">
      <div class="row">
        <div
          :class="
            showAssignmentDetails ||
            showActivityDetails ||
            showSubprojectDetails ||
            showProjectDetails
              ? 'col-9'
              : 'col-12'
          "
        >
          <ContentCard title="Ressourcenplanung" cardIcon="fas fa-list">
            <template v-slot:body>
              <div
                v-if="
                  showAssignmentDetails ||
                  showActivityDetails ||
                  showSubprojectDetails ||
                  showProjectDetails
                "
                class="expandIcon"
                @click="clearSelection"
              >
                <em class="fas fa-angle-double-right"></em>
              </div>
              <div>
                <ToolkitMenubar>
                  <ToolkitMenu :model="resourcePlanning.menuFilter.model"></ToolkitMenu>
                  <ToolkitMenu :model="resourcePlanning.menuProject.model"></ToolkitMenu>
                  <ToolkitMenu :model="resourcePlanning.menuAssignments.model"></ToolkitMenu>
                  <ToolkitMenu :model="resourcePlanning.menuFavro.model"></ToolkitMenu>
                </ToolkitMenubar>
                <ResourcePlanningGantt
                  ref="gantt"
                  :data="resourcePlanning.ganttModel.ganttData"
                ></ResourcePlanningGantt>
              </div>
            </template>
          </ContentCard>
        </div>
        <div
          class="col-3"
          v-if="
            showAssignmentDetails ||
            showActivityDetails ||
            showSubprojectDetails ||
            showProjectDetails
          "
        >
          <ContentCard
            v-if="showAssignmentDetails"
            title="Buchung"
            cardIcon="fas fa-list"
            class="ressource-detail-card"
            id="assignmentDetailsCard"
          >
            <template v-slot:body>
              <div
                class="collapse-overlay"
                @click="toggleCardBody('assignmentDetailsCard', $event)"
              ></div>
              <AssignmentDetails @onAssignmentSaved="updateTask"></AssignmentDetails>
            </template>
          </ContentCard>
          <ContentCard
            v-if="showActivityDetails"
            title="Abteilung"
            cardIcon="fas fa-list"
            class="ressource-detail-card"
            id="activityDetailsCard"
          >
            <template v-slot:body>
              <div
                class="collapse-overlay"
                @click="toggleCardBody('activityDetailsCard', $event)"
              ></div>
              <ActivityDetails @onActivitySaved="updateTask"></ActivityDetails>
            </template>
          </ContentCard>
          <ContentCard
            v-if="showSubprojectDetails"
            title="Teilprojekt"
            cardIcon="fas fa-list"
            class="ressource-detail-card"
            id="subprojectDetailCard"
          >
            <template v-slot:body>
              <div
                class="collapse-overlay"
                @click="toggleCardBody('subprojectDetailCard', $event)"
              ></div>
              <SubprojectDetails @onSubProjectSaved="updateTask"></SubprojectDetails>
            </template>
          </ContentCard>
          <ContentCard
            v-if="showProjectDetails"
            title="Projekt"
            cardIcon="fas fa-list"
            class="ressource-detail-card"
            id="projectDetailCard"
          >
            <template v-slot:body>
              <div
                class="collapse-overlay"
                @click="toggleCardBody('projectDetailCard', $event)"
              ></div>
              <ProjectDetails @onProjectSaved="updateTask"></ProjectDetails>
            </template>
          </ContentCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ContentCard } from 'rey-smartmes-ui';
import ResourcePlanningGantt from '@/views/resourcePlanning/ResourcePlanningGantt.vue';
import ToolkitMenubar from '@/components/buttons/ToolkitMenubar.vue';
import ToolkitMenu from '@/components/buttons/ToolkitMenu.vue';
import GenericFormDialog from '@/components/genericForm/GenericFormDialog.vue';
import GenericWizardDialog from '@/components/genericForm/GenericWizardDialog.vue';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
import ResourcePlanningModel from '@/models/resourcePlanning/ResourcePlanningModel';
import ITaskSelectionChangeEvent from '@/models/resourcePlanning/events/ITaskSelectionChangeEvent';
import Observer from '@/models/resourcePlanning/util/Observer';
import GanttTaskDto from '@/models/resourcePlanning/gantt/GanttTaskDto';
import ProjectDetails from './ProjectDetails.vue';
import SubprojectDetails from './SubprojectDetails.vue';
import ActivityDetails from './ActivityDetails.vue';
import AssignmentDetails from './AssignmentDetails.vue';
import { AssignmentDto } from '@/models/resourcePlanning/dtos/AssignmentDto';
import { SubjectDto } from '@/models/resourcePlanning/dtos/SubjectDto';
import FilterDialog from './FilterDialog.vue';
import Gantt from 'devextreme/ui/gantt';

@Component({
  components: {
    ContentCard,
    ToolkitMenubar,
    ToolkitMenu,
    ResourcePlanningGantt,
    GenericFormDialog,
    GenericWizardDialog,
    ProjectDetails,
    SubprojectDetails,
    ActivityDetails,
    AssignmentDetails,
    FilterDialog
  }
})
export default class ResourcePlanning extends Vue {
  public model: ModelRoot = this.$model;
  public resourcePlanning: ResourcePlanningModel = this.$model.resourcePlanning;

  public showProjectDetails = false;
  public showSubprojectDetails = false;
  public showActivityDetails = false;
  public showAssignmentDetails = false;

  public selectedProjectTask: GanttTaskDto = {} as GanttTaskDto;
  public selectedSubprojectTask: GanttTaskDto = {} as GanttTaskDto;
  public selectedActivityTask: GanttTaskDto = {} as GanttTaskDto;
  public selectedAssignmentTask: GanttTaskDto = {} as GanttTaskDto;
  public assignmentParentProjectTask: GanttTaskDto = {} as GanttTaskDto;

  public selectionChangedEventHandler: Observer<ITaskSelectionChangeEvent> = {
    update: async (event) => {
      await this.setSelection(event);
    }
  };

  public toggleCardBody(id: string, e: any) {
    let el = document.getElementById(id);
    el?.getElementsByClassName('card-body')[0].classList.toggle('collapsed');
  }

  public get ganttRef() {
    const thisGanttRef: any = this.$refs['gantt'];
    if (thisGanttRef) {
      return thisGanttRef.$refs['resourcePlanningGantt'];
    } else {
      return null;
    }
  }

  public updateTask(result: GanttTaskDto) {
    if (this.ganttRef) {
      const childGanttInstance: Gantt = this.ganttRef.instance;
      childGanttInstance.updateTask(result.id, result);
    }
  }

  private async setSelection(event: ITaskSelectionChangeEvent | undefined): Promise<void> {
    this.clearSelection(false);
    if (event == undefined) {
      return;
    }

    let isAssignmentSelected = false;
    let isActivitySelected = false;
    let isSubprojectSelected = false;
    let isProjectSelected = false;

    isAssignmentSelected = event.hasSelection && event.selection.subjectType === 1000;
    if (isAssignmentSelected) {
      this.selectedAssignmentTask = event.selection;
      this.showAssignmentDetails = true;
      this.showActivityDetails = true;
      this.searchSelectedProjects(event.selection);
    }

    isActivitySelected = event.hasSelection && event.selection.subjectType === 3;
    if (isActivitySelected) {
      this.selectedActivityTask = event.selection;
      this.showActivityDetails = true;
      this.searchSelectedProjects(event.selection);
    }

    isSubprojectSelected = event.hasSelection && event.selection.subjectType === 2;
    if (isSubprojectSelected) {
      this.selectedSubprojectTask = event.selection;
      this.showSubprojectDetails = true;
      this.searchSelectedProjects(event.selection);
    }

    isProjectSelected = event.hasSelection && event.selection.subjectType === 1;
    if (isProjectSelected) {
      this.selectedProjectTask = event.selection;
      this.showProjectDetails = true;
    }

    if (this.showAssignmentDetails) {
      const assignmentId = this.resourcePlanning.employeeService.getAssignmentId(
        this.selectedAssignmentTask
      );

      const assignment: AssignmentDto = await this.resourcePlanning.employeeService.getAssignment(
        assignmentId
      );
      this.resourcePlanning.selectedAssignment = assignment;
      this.resourcePlanning.hasSelectedAssignment = true;
    }

    if (this.showActivityDetails && this.selectedActivityTask.id) {
      const activity: SubjectDto = await this.resourcePlanning.subjectService.getSubject(
        this.selectedActivityTask.id
      );
      this.resourcePlanning.selectedActivity = activity;
      this.resourcePlanning.hasSelectedActivity = true;
    }

    if (this.showSubprojectDetails) {
      const subproject: SubjectDto = await this.resourcePlanning.subjectService.getSubject(
        this.selectedSubprojectTask.id
      );
      this.resourcePlanning.selectedSubproject = subproject;
      this.resourcePlanning.hasSelectedSubproject = true;
    }

    if (this.showProjectDetails) {
      const project: SubjectDto = await this.resourcePlanning.subjectService.getSubject(
        this.selectedProjectTask.id
      );
      this.resourcePlanning.selectedProject = project;
      this.resourcePlanning.hasSelectedProject = true;
    }

    if (event.refreshGantt) {
      this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);
    }
  }

  public clearSelection(e: any): void {
    this.selectedProjectTask = {} as GanttTaskDto;
    this.selectedSubprojectTask = {} as GanttTaskDto;
    this.selectedActivityTask = {} as GanttTaskDto;
    this.selectedAssignmentTask = {} as GanttTaskDto;
    this.assignmentParentProjectTask = {} as GanttTaskDto;
    this.resourcePlanning.selectedAssignment = {} as AssignmentDto;
    this.resourcePlanning.hasSelectedAssignment = false;
    this.resourcePlanning.selectedActivity = {} as SubjectDto;
    this.resourcePlanning.hasSelectedActivity = false;
    this.resourcePlanning.selectedSubproject = {} as SubjectDto;
    this.resourcePlanning.hasSelectedSubproject = false;
    this.resourcePlanning.selectedProject = {} as SubjectDto;
    this.resourcePlanning.hasSelectedProject = false;
    this.showProjectDetails = false;
    this.showSubprojectDetails = false;
    this.showActivityDetails = false;
    this.showAssignmentDetails = false;

    if (e) {
      this.resourcePlanning.ganttModel.selectedGanttTask = {} as GanttTaskDto;
      this.resourcePlanning.ganttModel.hasGanttDataSelection = false;
    }
  }

  public onHideFilterDialog(): void {
    this.resourcePlanning.showFilterDialog = false;
  }

  public async onFilterChanged(): Promise<void> {
    await this.resourcePlanning.ganttModel.reloadGanttData();
  }

  private searchSelectedProjects(child: GanttTaskDto) {
    let root: GanttTaskDto | undefined = child;
    let activityFound = false;
    let subprojectFound = false;
    do {
      root = this.resourcePlanning.ganttModel.getGanttDataTask(root.parentId);
      if (!subprojectFound && root?.subjectType === 2) {
        this.selectedSubprojectTask = root;
        this.showSubprojectDetails = true;
        subprojectFound = true;
      }
      if (!activityFound && root?.subjectType === 3) {
        this.selectedActivityTask = root;
        this.showActivityDetails = true;
        activityFound = true;
      }
    } while (root != undefined && root.parentId != undefined);
    if (root != undefined) {
      this.selectedProjectTask = root;
      this.showProjectDetails = true;
    }
    if (!subprojectFound) {
      this.assignmentParentProjectTask = this.selectedProjectTask;
    } else {
      this.assignmentParentProjectTask = this.selectedSubprojectTask;
    }
  }

  public async mounted(): Promise<void> {
    this.$model.resourcePlanning.ganttModel.eventGanttTaskSelectionChanged.addObserver(
      this.selectionChangedEventHandler
    );
  }

  public beforeDestroy(): void {
    this.resourcePlanning.ganttModel.eventGanttTaskSelectionChanged.removeObserver(
      this.selectionChangedEventHandler
    );
  }
}
</script>

<style lang="scss" scoped>
.collapse-overlay {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
}
</style>
<style lang="scss">
.expandIcon {
  position: absolute;
  top: 11px;
  right: 60px;
  cursor: pointer;
  em {
    font-size: 25px;
    color: #fff;
  }
}

.ressource-detail-card {
  .card-body {
    max-height: 1000px;
    &.collapsed {
      max-height: 0px;
      padding: 0px;
      overflow: hidden;
    }
  }
}
</style>
