import { IToolkitMenuModel } from '@/components/buttons/IToolkitMenuModel';
import ResourcePlanning from '../ResourcePlanningModel';

export default class FilterMenu {
  public readonly resourcePlanning: ResourcePlanning;

  public model: IToolkitMenuModel = {
    command: {
      id: 'Filter',
      name: 'Filter',
      icon: 'fas fa-filter',
      group: 'root',
      execute: () => {
        this.resourcePlanning.showFilterDialog = true;
      }
    },
    getEntries: () => undefined
    //getEntries: () => Promise.resolve([])
  };

  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }
}
