import { AxiosInstance } from 'axios';
import ResourcePlanning from './ResourcePlanningModel';
import { WebStorageService } from './services/WebStorageService';

export default class ModelRoot {
  public readonly resourcePlanning: ResourcePlanning;
  constructor(axios: AxiosInstance, webStorageService: WebStorageService) {
    this.resourcePlanning = new ResourcePlanning(axios, webStorageService);
  }
}
