/**
 * Performs deep clone. Not able to deep clone closures, enclosed values
 * still point to original reference! The result will therefore not be a
 * full deep clone in all situations!
 * It is intended to be uses for Data Transfer Objects (Value Objects).
 * Properties starting with a _ ar not cloned per default!
 * @param ingorePropertyPrefix Prefix of properties to be ignored (Default: _ )
 */
export default function clone<T>(x: T, ingorePropertyPrefix?: string): T {
  const ignorePrefix = ingorePropertyPrefix == undefined ? '_' : ingorePropertyPrefix;
  return doClone(x, [], [], ignorePrefix) as T;
}

function doClone(
  x: unknown,
  sources: Array<unknown>,
  clones: Array<unknown>,
  ignorePrefix: string
): unknown {
  if (isArray(x)) {
    return cloneArray(x as unknown as Array<unknown>, sources, clones, ignorePrefix);
  } else if (isFunction(x)) {
    return x;
  } else if (isObject(x)) {
    return cloneObject(x, sources, clones, ignorePrefix);
  } else {
    return x;
  }
}

/**
   * objects constructed by other functions than Object, because those cannot be
   simply copied as simple object
   */
function isObject(o: unknown): boolean {
  //typeof o === "object" inclues arrays and everything else, we want to target only object-objects here
  return Object.prototype.toString.call(o) === '[object Object]';
}

function isArray(a: unknown): boolean {
  return Object.prototype.toString.call(a) === '[object Array]';
}

/**
 * detects just pure functions
 */
function isFunction(f: unknown): boolean {
  if (f instanceof Function) return true;
  return false;
}

function cloneArray(
  arr: Array<unknown>,
  sources: Array<unknown>,
  clones: Array<unknown>,
  ignorePrefix: string
): unknown {
  return arr.map(function (member) {
    return doClone(member, sources, clones, ignorePrefix);
  });
}

/**
 * @param obj object to clone
 * @param sources if recursive, allready cloned objects in order to avoid circular references
 * @param clones allready cloned source objects, for avoiding circular references
 */
function cloneObject(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectToBeCloned: any,
  sources: Array<unknown>,
  clones: Array<unknown>,
  ignorePrefix: string
) {
  if (!objectToBeCloned) {
    return objectToBeCloned;
  }

  if (objectToBeCloned.clone && isFunction(objectToBeCloned.clone)) {
    return objectToBeCloned.clone();
  }

  if (objectToBeCloned instanceof Date) {
    //date is built in type and can be created only by invoking new Date()
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
    //there are more such objects as Error, but they are ignored here
    return new Date(objectToBeCloned.getTime());
  }

  sources = sources || [];
  clones = clones || [];
  const target = Object.create(objectToBeCloned);
  const propertyNames: string[] = Object.getOwnPropertyNames(objectToBeCloned);
  propertyNames.forEach((propertyName: string) => {
    if (ignorePrefix != null && propertyName.startsWith(ignorePrefix)) {
      return;
    }
    const sourceDescriptor = Object.getOwnPropertyDescriptor(objectToBeCloned, propertyName);
    if (sourceDescriptor) {
      let sourceValue: unknown;
      if (sourceDescriptor.get) {
        sourceValue = sourceDescriptor.get();
      } else {
        sourceValue = sourceDescriptor.value;
      }
      const targetValue = getValue2Assign(
        sourceValue,
        objectToBeCloned,
        target,
        sources,
        clones,
        ignorePrefix
      );
      const targetPropertyDescriptor: PropertyDescriptor = {
        value: targetValue,
        writable: true,
        configurable: sourceDescriptor.configurable,
        enumerable: sourceDescriptor.enumerable
      };

      Object.defineProperty(target, propertyName, targetPropertyDescriptor);
    }
  });
  return target;
}

function getValue2Assign(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectBeingCloned: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any,
  sources: Array<unknown>,
  clones: Array<unknown>,
  ignorePrefix: string
) {
  if (!isObject(value)) {
    return clone(value);
  } else if (value.clone) {
    //cloneable
    const cloneTarget = value.clone();
    // eslint-disable-next-line no-prototype-builtins
    if (cloneTarget && value.constructor.prototype.isPrototypeOf(clone.constructor.prototype)) {
      return cloneTarget;
    }
    return getObjectValue2Assign(
      value,
      objectBeingCloned,
      cloneTarget,
      sources,
      clones,
      ignorePrefix
    );
  }
  //else we deal with new object
  return getObjectValue2Assign(value, objectBeingCloned, target, sources, clones, ignorePrefix);
}

function getObjectValue2Assign(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  objectBeingCloned: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any,
  sources: Array<unknown>,
  clones: Array<unknown>,
  ignorePrefix: string
) {
  const allreadyClonedIndex = sources.indexOf(value);
  if (allreadyClonedIndex !== -1) {
    return clones[allreadyClonedIndex];
  } else {
    sources.push(objectBeingCloned);
    clones.push(target);
    return cloneObject(value, sources, clones, ignorePrefix);
  }
}
