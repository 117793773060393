import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import GanttTaskDto from '../gantt/GanttTaskDto';
import ResourcePlanning from '../ResourcePlanningModel';
import IDialog from './IDialog';

export default class DeleteActivityDialog implements IDialog {
  public readonly resourcePlanning: ResourcePlanning;
  private comment = '???';

  public model: IGenericFormModel = {
    options: {
      title: 'Task löschen?'
    },
    getData: () => this.resourcePlanning.ganttModel.getSelectedGanttTask(),
    entries: [
      {
        type: 'comment',
        getComment: (): string => this.comment
      }
    ],
    onYes: (m) => this.onYes(m),
    onNo: () => this.hide()
  };
  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }

  private onYes(model: IGenericFormModel): void | Promise<void> {
    const activityToBeDeleted = model.result as GanttTaskDto;
    return this.resourcePlanning.subjectService.deleteTask(activityToBeDeleted).then(() => {
      this.hide();
    });
  }

  public setComment(comment: string): DeleteActivityDialog {
    this.comment = comment;
    return this;
  }

  public show(): void {
    if (!this.resourcePlanning.ganttModel.selectedGanttTask) {
      return;
    }
    const comment = `Task: ${this.resourcePlanning.ganttModel.selectedGanttTask?.title}`;
    this.setComment(comment);
    this.resourcePlanning.dialogModel = this.model;
    this.resourcePlanning.showDialog = true;
  }

  public hide(): void {
    this.comment = '???';
    this.resourcePlanning.showDialog = false;
  }
}
