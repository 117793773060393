<template>
  <div class="label-container">
    <div class="resource-label" :style="'color:' + resource.color">
      <img
        v-if="resource.data.resouceAvatarUrl != undefined"
        :src="resource.data.resouceAvatarUrl"
        height="30"
      />
      <div>
        {{ resource.data.text }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    resource: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {}
    }
  }
};
</script>
<style>
.avatar {
  width: 250px;
  float: left;
  overflow: hidden;
  position: relative;
}

.label-container {
  margin-top: auto;
  margin-bottom: auto;
}

.resource-label {
  min-width: 550px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: flex-start;
  align-items: center;
  gap: 10px;
}

.info {
  width: auto;
  text-align: left;
  height: 100%;
  font-size: 11pt;
  font-weight: normal;
  padding: 25px 20px;
  color: #707070;
}

.dx-color-scheme-contrast .info {
  color: #fff;
}
</style>
