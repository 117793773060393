export class WebStorageService {
  private readonly keyPrefix = 'wss-';
  private readonly nullValue = '__NULL__';

  public hasWebStorage(): boolean {
    return typeof Storage !== 'undefined';
  }

  public put(key: string, value: string | null): boolean {
    if (typeof Storage === 'undefined') {
      return false;
    }
    const storeKey = `${this.keyPrefix}${key}`;
    if (value == null) {
      localStorage.setItem(storeKey, this.nullValue);
    } else {
      localStorage.setItem(storeKey, value);
    }

    return true;
  }

  public get(key: string, defaultValue: string | undefined | null = null): string | null {
    if (typeof Storage === 'undefined') {
      if (defaultValue == undefined) {
        return null;
      }
      return defaultValue;
    }
    const storeKey = `${this.keyPrefix}${key}`;
    const value = localStorage.getItem(storeKey);
    if (value == null || value === this.nullValue) {
      if (defaultValue == null) {
        return null;
      }
      return defaultValue;
    }
    return value;
  }

  public getString(key: string, defaultValue: string | undefined | null = null): string {
    const value = this.get(key, defaultValue);
    if (value == null) {
      if (defaultValue == null) {
        return '';
      }
      return defaultValue;
    }
    return value;
  }
}
