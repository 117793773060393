<template>
  <div>
    <DxCheckBox
      :disabled="model.disabled"
      :value.sync="content.value"
      :text="model.label.name"
      @value-changed="onValueChanged()"
    />
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IValueContainer } from '../genericForm/ValueContainer';
import { getString } from '../genericForm/OptionsAccessors';
import DxCheckBox from 'devextreme-vue/check-box';
import IGenericFormItem from '../genericForm/IGenericFormItem';
import IItemChangeEvent from '@/models/resourcePlanning/events/IItemChangeEvent';
import clone from '@/models/resourcePlanning/util/Clone';

@Component({
  components: {
    DxCheckBox
  }
})
export default class CheckBox extends Vue {
  @Prop()
  public model!: IGenericFormItem;
  @Prop()
  public content!: IValueContainer;

  private oldValue = false;

  private mounted(): void {
    this.oldValue = this.content.value as boolean;
  }

  private onValueChanged(): void {
    this.emitValueChange({
      item: this.model,
      from: clone(this.oldValue),
      to: clone(this.content.value)
    });
    this.oldValue = this.content.value as boolean;
  }

  @Emit('itemChange')
  public emitValueChange(event: IItemChangeEvent): IItemChangeEvent {
    return event;
  }

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    return getString(options, name, defaultValue);
  }
}
</script>
