<template>
  <div>
    <button
      v-if="!action.hidden"
      :disabled="isDisabled"
      type="button"
      :class="{ btn: true, 'btn-primary': isPrimary, 'btn-secondary': isSecondary }"
      @click="onClick()"
    >
      <div class="d-flex justify-content-center align-items-center">
        <span v-if="hasLeftIcon">
          <i v-if="isSpinning" class="fas fa-cog fa-spin"></i>
          <div v-if="!isSpinning">
            <em v-if="isFasIcon" :class="iconClasses" /><span v-if="!isFasIcon">{{
              this.action.icon
            }}</span>
          </div>
        </span>

        <div
          v-if="isSpinning && !hasLeftIcon && !hasRightIcon && !hasIconPosition"
          class="d-flex .flex-column justify-content-center align-items-center text-spinner"
        >
          <!-- <div
            :class="{
              'text-spinner-background-primary': isPrimary,
              'text-spinner-background-secondary': isSecondary
            }"
          > -->
          <div>
            <i class="fas fa-cog fa-spin"></i>
          </div>
        </div>

        <span v-if="hasName" :class="{ 'left-icon': hasLeftIcon, 'right-icon': hasRightIcon }">{{
          action.name
        }}</span>

        <span v-if="hasRightIcon">
          <i v-if="isSpinning" class="fas fa-cog fa-spin"></i>
          <div v-if="!isSpinning">
            <em v-if="isFasIcon" :class="iconClasses" /><span v-if="!isFasIcon">{{
              action.icon
            }}</span>
          </div>
        </span>
      </div>
    </button>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IToolkitCommand } from './IToolkitCommand';
import { ToolkitCommandResultType } from '../sharedModels/ICommand';
import LockNode from '../sharedModels/LockNode';
@Component({})
export default class ToolkitButton extends Vue {
  @Prop() action!: IToolkitCommand;
  @Prop() iconClass: string | undefined;

  private actionInProgress = false;
  public isSpinning = false;
  public iconClasses = [this.action?.icon];

  public onClick(): void {
    let group: LockNode | null = null;
    if (this.action.group) {
      group = this.$toolkitModel.commandLockRoot.resolve(this.action.group);
      if (group != null && group.isLocked) return;
    }
    if (group != null) group.lock();
    let ret: ToolkitCommandResultType;
    try {
      if (this.action.execute) {
        ret = this.action.execute();
      }
    } catch (error) {
      if (group != null) group.unlock();
    }
    if (ret != null && ret.finally != null) {
      this.actionInProgress = true;
      if (!this.action.doNotSpin) {
        this.isSpinning = true;
      }

      ret.finally(() => {
        this.actionInProgress = false;
        if (!this.action.doNotSpin) {
          this.isSpinning = false;
        }
        if (group != null) group.unlock();
      });
    } else {
      if (group != null) group.unlock();
    }
  }

  public mounted(): void {
    this.iconClasses = [this.action.icon, this.iconClass];
  }

  public get isDisabled(): boolean {
    return this.actionInProgress || this.isActionDisabled;
  }

  public get isActionDisabled(): boolean {
    if (!this.action.disabled) {
      return false;
    }
    return this.action.disabled();
  }

  public get buttonIcon(): string | undefined {
    return this.action.icon;
  }

  public get hasLeftIcon(): boolean {
    return this.action.iconPosition === 'left' || (!this.hasRightIcon && this.action.icon != null);
  }

  public get hasRightIcon(): boolean {
    return this.action.iconPosition === 'right';
  }

  public get hasIconPosition(): boolean {
    return this.action.iconPosition != null;
  }

  public get isFasIcon(): boolean {
    return this.action.icon != null && this.action.icon.startsWith('fas ');
  }

  public get isPrimary(): boolean {
    return this.action.style == null || this.action.style === 'primary';
  }

  public get isSecondary(): boolean {
    return this.action.style != null || this.action.style === 'secondary';
  }

  public get hasName(): boolean {
    return this.action.name != null;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.left-icon {
  padding-left: 10px !important;
}
.right-icon {
  padding-right: 10px !important;
}

.text-spinner {
  position: absolute;
}

.text-spinner-background-primary {
  //background-color: lighten($primary, 10%);
  background-color: palevioletred;
  padding-left: 5px;
  padding-right: 5px;
}

.text-spinner-background-secondary {
  background-color: deepskyblue;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
