<template>
  <div>
    <div v-if="resourcePlanning.hasSelectedSubproject">
      <GenericForm :model="formModel"></GenericForm>
      <ToolkitButton :action="saveButtonModel"></ToolkitButton>
    </div>
  </div>
</template>
<script lang="ts">
import { IToolkitCommand } from '@/components/buttons/IToolkitCommand';
import ToolkitButton from '@/components/buttons/ToolkitButton.vue';
import DropDownBox from '@/components/input/DropDownBox.vue';
import GenericForm from '@/components/genericForm/GenericForm.vue';
import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import { ISingleSelectionModel } from '@/components/genericForm/ISingleSelectionModel';
import { ToolkitCommandResultType } from '@/components/sharedModels/ICommand';
import { SubjectDto } from '@/models/resourcePlanning/dtos/SubjectDto';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
import ResourcePlanningModel from '@/models/resourcePlanning/ResourcePlanningModel';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxTextBox from 'devextreme-vue/text-box';
import { Component, Vue } from 'vue-property-decorator';
import ITextValuePair from '@/components/genericForm/ITextValuePair';
@Component({
  components: { DxTextBox, DxDateBox, DxTextArea, DropDownBox, ToolkitButton, GenericForm }
})
export default class SubprojectDetails extends Vue {
  public model: ModelRoot = this.$model;
  public resourcePlanning: ResourcePlanningModel = this.$model.resourcePlanning;

  public formModel: IGenericFormModel = {
    getData: () => this.getFormData(),
    entries: [
      {
        type: 'text',
        propertyName: 'name',
        label: {
          name: 'Projekt'
        },
        options: {
          width: '100%'
        }
      },
      {
        type: 'date',
        propertyName: 'beginDate',
        label: {
          name: 'Start'
        }
      },
      {
        type: 'date',
        propertyName: 'endDate',
        label: {
          name: 'Ende'
        }
      },
      {
        type: 'singleSelection',
        propertyName: 'projectLeader.name',
        label: {
          name: 'Projektleiter'
        },
        options: {
          items: () => this.getEmployeeOptions(),
          width: '100%'
        }
      },
      {
        type: 'text',
        propertyName: 'comment',
        label: {
          name: 'Kommentar'
        },
        options: {
          multiline: true,
          height: 60,
          width: '100%'
        }
      }
    ]
  };

  public saveButtonModel: IToolkitCommand = {
    name: 'Speichern',
    group: 'root',
    execute: () => this.onSave()
  };

  getFormData(): Record<string, unknown> | Promise<Record<string, unknown>> | undefined {
    if (!this.resourcePlanning.selectedSubproject.projectLeader) {
      this.resourcePlanning.selectedSubproject.projectLeader = {
        employeeId: 0,
        name: '',
        shortName: '',
        email: ''
      };
    }
    return this.resourcePlanning.selectedSubproject;
  }

  public createOwnerSelectionModel(): ISingleSelectionModel {
    return {
      value: this.resourcePlanning.selectedSubproject.projectLeader?.name ?? '',
      item: {
        type: 'singleSelection',
        options: {
          items: () => this.getEmployeeOptions(),
          width: '100%'
        }
      }
    };
  }

  public onSave(): ToolkitCommandResultType {
    if (!this.formModel.result) {
      return;
    }

    let resultSubject: SubjectDto = this.formModel.result as SubjectDto;

    const plainResult: any = this.formModel.result;
    const mappedTask: { id: string; title: string; start: Date; end: Date; treeId: string } = {
      id: plainResult.subjectId,
      title: plainResult.name,
      start: new Date(plainResult.beginDate),
      end: new Date(plainResult.endDate),
      treeId: plainResult.treeId
    };
    this.$emit('onProjectSaved', mappedTask);

    return this.resourcePlanning.subjectService.saveSubject({
      origin: this.resourcePlanning.selectedSubproject,
      update: resultSubject
    });
  }

  private getEmployeeOptions() {
    return this.resourcePlanning.employees.map((e) => {
      return { value: e.name, text: e.name } as ITextValuePair;
    });
  }
}
</script>
<style lang="scss" scoped>
.readonly-field {
  color: #000000;
  background-color: #eeeeee;
  border: solid 1px #dddddd;
  border-radius: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
