var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('GenericFormDialog',{attrs:{"visible":_vm.resourcePlanning.showDialog,"model":_vm.resourcePlanning.dialogModel}}),(_vm.resourcePlanning.showFilterDialog)?_c('div',[_c('FilterDialog',{on:{"hide":function($event){return _vm.onHideFilterDialog()},"filter-changed":function($event){return _vm.onFilterChanged()}}})],1):_vm._e(),_c('GenericWizardDialog',{attrs:{"visible":_vm.resourcePlanning.showWizard,"model":_vm.resourcePlanning.wizardModel}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{class:_vm.showAssignmentDetails ||
          _vm.showActivityDetails ||
          _vm.showSubprojectDetails ||
          _vm.showProjectDetails
            ? 'col-9'
            : 'col-12'},[_c('content-card',{attrs:{"title":"Timeline","cardIcon":"fas fa-user-clock"},scopedSlots:_vm._u([{key:"body",fn:function(){return [(
                _vm.showAssignmentDetails ||
                _vm.showActivityDetails ||
                _vm.showSubprojectDetails ||
                _vm.showProjectDetails
              )?_c('div',{staticClass:"expandIcon",on:{"click":_vm.onTimelineWidgetTaskChanged}},[_c('em',{staticClass:"fas fa-angle-double-right"})]):_vm._e(),_c('div',[_c('ToolkitMenubar',[_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuFilter.model}}),_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuProject.model}}),_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuCreateAssignment.model}}),_c('ToolkitMenu',{attrs:{"model":_vm.resourcePlanning.menuFavro.model}})],1),_c('TimelineWidget',{ref:"timelineWidget",attrs:{"widgetId":"timelineWidget","selectedTask":_vm.selectedTask,"timelineModel":_vm.resourcePlanning.timelineModel.timelineData,"timelineConfiguration":_vm.resourcePlanning.timelineModel.timelineConfiguration,"appointmentContextMenuItems":_vm.appointmentContextMenuItems},on:{"task-changed":function($event){return _vm.onTimelineWidgetTaskChanged($event)},"task-selected":function($event){return _vm.onTimelineWidgetTaskSelected($event)}}})],1)]},proxy:true}])})],1),(
          _vm.showAssignmentDetails ||
          _vm.showActivityDetails ||
          _vm.showSubprojectDetails ||
          _vm.showProjectDetails
        )?_c('div',{staticClass:"col-3"},[(_vm.showAssignmentDetails)?_c('content-card',{attrs:{"title":"Buchung","cardIcon":"fas fa-list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AssignmentDetails')]},proxy:true}],null,false,2629822153)}):_vm._e(),(_vm.showActivityDetails)?_c('content-card',{attrs:{"title":"Abteilung","cardIcon":"fas fa-list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ActivityDetails')]},proxy:true}],null,false,2118692535)}):_vm._e(),(_vm.showSubprojectDetails)?_c('content-card',{attrs:{"title":"Teilprojekt","cardIcon":"fas fa-list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('SubprojectDetails')]},proxy:true}],null,false,3664533259)}):_vm._e(),(_vm.showProjectDetails)?_c('content-card',{attrs:{"title":"Projekt","cardIcon":"fas fa-list"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ProjectDetails')]},proxy:true}],null,false,4104893359)}):_vm._e()],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }