<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <slot></slot>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script lang="ts">
// https://getbootstrap.com/docs/4.0/components/dropdowns/
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ToolkitMenubar extends Vue {}
</script>
