<template>
  <div>
    <DxTextArea
      :disabled="model.disabled"
      :width="getString(model.options, 'width', '100%')"
      :value.sync="content.value"
      :height="getNumber(model.options, 'height', 4 * 22)"
    ></DxTextArea>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GenericFormItem } from '../genericForm/GenericFormItem';
import { IValueContainer } from '../genericForm/ValueContainer';
import DxTextArea from 'devextreme-vue/text-area';
import { getString, getNumber } from '../genericForm/OptionsAccessors';

@Component({
  components: {
    DxTextArea
  }
})
export default class TextArea extends Vue {
  @Prop()
  public model!: GenericFormItem;
  @Prop()
  public content!: IValueContainer;

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    return getString(options, name, defaultValue);
  }

  public getNumber(options: Record<string, unknown>, name: string, defaultValue: number): number {
    return getNumber(options, name, defaultValue);
  }
}
</script>
