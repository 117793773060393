import { AuthenticationContext } from 'vue-adal';
import Logger from './Logger';

export class AdalTokenProvider {
  public static getCachedToken(resource: string): string | undefined {
    return AuthenticationContext.adalContext.getCachedToken(resource);
  }

  public static getTokenAsync(resource: string): Promise<string> {
    return new Promise((resolve, reject) =>
      AdalTokenProvider.getToken(resource, (err, token) => {
        if (err) {
          reject(err);
        } else {
          resolve(token as string);
        }
      })
    );
  }

  private static getToken(resource: string, callback: (err: Error | null, token?: string) => void) {
    let retries = 5;
    do {
      try {
        AuthenticationContext.acquireToken(resource, (err: string | null, token?: string) => {
          if (err) {
            const errCode = err.split(':')[0];
            switch (errCode) {
              case 'AADSTS50058': // Need to prompt for user sign in
                AuthenticationContext.login();
                break;
              case 'AADSTS65001': // Token is invalid; grab a new one
                AuthenticationContext.acquireTokenRedirect(resource);
                break;
              case 'AADSTS16000': // No Access
              default:
                // Need a pop-up forcing a login
                AuthenticationContext.login();
                break;
            }
            Logger.warning(err);
            callback(new Error('Failed to acquire token'));
          } else {
            callback(null, token);
          }
        });
        retries = 0;
      } catch (error) {
        retries--;
        if (retries <= 0) {
          throw error;
        }
      }
    } while (retries > 0);
  }
}

// tslint:disable-next-line: max-classes-per-file
export class AdalConfig {
  public tenant: string;
  public clientId: string;
  public redirectUri?: string;

  constructor(tenant: string, clientId: string, redirectUri?: string) {
    this.tenant = tenant;
    this.clientId = clientId;
    this.redirectUri = redirectUri;
  }
}

export interface AdalAuthenticationContext {
  login: () => void;
  logout: () => void;
  acquireToken: (
    resource: string,
    callback: (err: string | null, token: string | undefined) => void
  ) => void;
  isAuthenticated: () => boolean;
}
