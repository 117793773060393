import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import { EmployeeDto } from '../dtos/EmployeeDto';
import GanttTaskDto from '../gantt/GanttTaskDto';
import ResourcePlanning from '../ResourcePlanningModel';
import IDialog from './IDialog';

export default class DeleteAssignmentDialog implements IDialog {
  public readonly resourcePlanning: ResourcePlanning;
  private comment = '???';
  private employee: EmployeeDto = {} as EmployeeDto;

  public model: IGenericFormModel = {
    options: {
      title: 'Buchung löschen?'
    },
    getData: () => this.employee,
    entries: [
      {
        type: 'comment',
        getComment: (): string => this.comment
      }
    ],
    onYes: (m) => this.onYes(m),
    onNo: () => this.hide()
  };
  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }

  private onYes(model: IGenericFormModel): void | Promise<void> {
    const assignmentId = this.resourcePlanning.employeeService.getAssignmentId(
      this.resourcePlanning.ganttModel.selectedGanttTask
    );
    return this.resourcePlanning.subjectService
      .deleteAssignment(assignmentId)
      .then(() => {
        this.hide();
      })
      .catch((error) => {
        console.warn(`Delete assignment {assignmentId} failed!`, error);
      });
  }

  public setComment(comment: string): DeleteAssignmentDialog {
    this.comment = comment;
    return this;
  }

  public show(): void {
    if (!this.resourcePlanning.ganttModel.selectedGanttTask) {
      return;
    }
    if (this.resourcePlanning.ganttModel.selectedGanttTask.subjectType !== 1000) {
      return;
    }

    this.resourcePlanning.employeeService
      .getAssignment(
        this.resourcePlanning.employeeService.getAssignmentId(
          this.resourcePlanning.ganttModel.selectedGanttTask
        )
      )
      .then((assignment) => {
        this.employee = assignment.employee as EmployeeDto;
        const comment = `Teammitglied: ${assignment.employee?.name}`;
        this.setComment(comment);
        this.resourcePlanning.dialogModel = this.model;
        this.resourcePlanning.showDialog = true;
      });
  }

  public hide(): void {
    this.comment = '???';
    this.resourcePlanning.showDialog = false;
  }
}
