<template>
  <content-card title="Pikettplanung Software Service" cardIcon="fas fa-file-code">
    <template v-slot:body>
      <standby-duty-table
        ref="standbyDutySoftwareService"
        department="Software Services"
        v-model="selected"
      >
        <template v-slot:slot1>
          <button
            type="button"
            class="btn btn-success mx-1"
            @click="accept()"
            v-if="
              selected !== undefined &&
              (selected.status === 1 || selected.status === 3) &&
              isRelevant
            "
          >
            <em class="fas fa-check" />&nbsp;Akzeptieren
          </button>
        </template>
        <template v-slot:slot2>
          <button
            type="button"
            v-if="
              selected !== undefined &&
              (selected.status === 1 || selected.status === 3) &&
              isRelevant
            "
            class="btn btn-danger mx-1"
            @click="decline()"
          >
            <em class="fas fa-times" />&nbsp;Ablehnen
          </button>
        </template>
      </standby-duty-table>
    </template>
  </content-card>
</template>
<script lang="ts">
import Vue from 'vue';
import { ContentCard } from 'rey-smartmes-ui';
import StandbyDutyTable from '@/components/StandbyDutyTable.vue';
import StandbyDutyAssignment from '@/models/StandbyDutyAssignment';
import Logger from '@/services/Logger';

export default Vue.extend({
  components: { ContentCard, StandbyDutyTable },
  data() {
    return {
      selected: undefined as StandbyDutyAssignment | undefined,
      isRelevant: true as boolean
    };
  },
  mounted() {
    if (this.$router.currentRoute.query.id !== undefined) {
      this.$reyDipApi
        .get('/api/standbydutyassignment/' + this.$router.currentRoute.query.id)
        .then((res) => {
          this.selected = res.data;
          this.isRelevant = this.stillRelevant();
          if (!this.isRelevant) {
            alert('Dieser Pikettdienst betrifft dich nicht mehr.');
          }
        });
    }
  },
  methods: {
    stillRelevant(): boolean {
      return (
        this.selected !== undefined &&
        this.selected.requestedEmployeeId === this.isAuthenticated.profile.oid
      );
    },
    accept() {
      this.$reyDipApi
        .post('/api/standbydutyassignment/accept', this.selected)
        .then(() => this.reloadData())
        .catch((e: Error) => Logger.error(e));
    },
    decline() {
      this.$reyDipApi
        .post('/api/standbydutyassignment/decline', this.selected)
        .then(() => this.reloadData())
        .catch((e: Error) => Logger.error(e));
    },
    reloadData() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const componentRef = this.$refs.standbyDutySoftwareService as any;
      componentRef.$refs.standbydutySmartTable.$_SmartTable_loadElements();
      this.selected = undefined;
    }
  }
});
</script>
