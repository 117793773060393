import Subject from './Subject';

export default class Observable<TValue> extends Subject<TValue> {
  private theValue?: TValue;

  constructor(value: TValue) {
    super();
    this.theValue = value;
  }

  public get value(): TValue | undefined {
    return this.theValue;
  }

  public set value(value: TValue | undefined) {
    const oldValue = this.theValue;
    if (oldValue !== value) {
      this.theValue = value;
      this.notify(this.theValue);
    }
  }

  public notifyValue(): void {
    this.notify(this.value);
  }
}
