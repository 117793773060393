import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap';
import Adal from 'vue-adal';
import { AuthenticatedAxios, CreateAuthenticatedAxios } from './plugins/AuthenticatedAxios';
import { AdalConfig, AdalTokenProvider } from './services/Adal';
import Logger from '@/services/Logger';

import { locale, loadMessages } from 'devextreme/localization';

import deMessages from 'devextreme/localization/messages/de.json';
loadMessages(deMessages);
locale('de');

Vue.config.productionTip = false;

const adalConfig = new AdalConfig(
  process.env.VUE_APP_AAD_TENANTID,
  process.env.VUE_APP_AAD_CLIENTID,
  window.location.origin
);

const authenticatedAxios = CreateAuthenticatedAxios(adalConfig, router, (error: Error) =>
  Logger.error(error)
);

import { ToolkitModelRootPlugin } from '@/components/sharedModels/ToolkitModelRootPlugin';
import ToolkitModelRoot from '@/components/sharedModels/ToolkitModelRoot';
const toolkitModel = new ToolkitModelRoot();
Vue.use(ToolkitModelRootPlugin, toolkitModel);

import { ModelRootPlugin } from '@/models/resourcePlanning/ModelRootPlugin';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
const model = new ModelRoot(authenticatedAxios, toolkitModel.webStorageService);
Vue.use(ModelRootPlugin, model);

Vue.use(Adal, {
  config: adalConfig,
  requireAuthOnInitialize: true,
  router
});

Vue.use(AuthenticatedAxios, {
  axios: authenticatedAxios
});

AdalTokenProvider.getTokenAsync(adalConfig.clientId).then(() => {
  new Vue({
    router,
    render: (h) => h(App)
  }).$mount('#app');
});
