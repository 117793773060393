<template>
  <content-card title="Grafische Übersicht" cardIcon="fas fa-chart-bar">
    <template v-slot:body>
      <div class="text-right">
        <label for="department"><strong>Team</strong></label
        >&nbsp;
        <select
          class="form-control"
          style="width: auto; display: inline-block"
          id="department"
          v-model="department"
        >
          <option :value="'066bf939-d667-458a-9352-a5b318b7f04b'">Administration</option>
          <option :value="'7f050ecd-9853-412a-8bb5-84c3fdb332b0'">Informatik</option>
          <option :value="'1f32b6de-71b0-4cbb-b1c2-e7cd20192a76'">Service</option>
          <option :value="'9147b5b1-b631-4710-8bb4-59da81b9f008'">Software Engineering</option>
          <option :value="'4af3de27-07cc-41d4-a8b1-5a72ca685cdb'">Rey Digital</option>
          <option :value="'52892227-6a54-45b7-b2cb-51fd1664b281'">Technische Unterlagen</option>
          <option :value="'d864e61d-175b-41b0-9152-a1fd00a255eb'">Verkauf</option>
          <option :value="'8cb21de5-4ff9-4dad-a667-85e6a6de8aa9'">Verkauf Innendienst</option>
          <option :value="'vorgesetzte'">Management &amp; Administration</option>
          <option :value="'f318a49b-067f-452b-921a-ed82230db45c'">Werkstatt</option>
        </select>
      </div>
      <div
        :style="'height: ' + (100 + 15 * getRowCount() * 3) + 'px'"
        id="chartdiv"
        v-if="ganttDataRd.hasData() && getRowCount() > 0"
      ></div>
    </template>
  </content-card>
</template>
<script lang="ts">
import Vue from 'vue';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ContentCard } from 'rey-smartmes-ui';
import { RemoteData } from 'rey-frontend-fp';
import { AxiosError } from 'axios'; // NOSONAR
import GanttDataDto from '@/models/GanttDataDto'; // NOSONAR
import { now } from '@amcharts/amcharts4/.internal/core/utils/Time';

export default Vue.extend({
  name: 'gantt-chart',
  props: { departmentId: String },
  components: { ContentCard },
  data() {
    return {
      ganttDataRd: RemoteData.loading<GanttDataDto[], AxiosError>(),
      holidaysRd: RemoteData.loading<Date[], AxiosError>(),
      department: ''
    };
  },
  watch: {
    async department() {
      await this.loadData();
    }
  },
  created() {
    this.department = this.departmentId;
  },
  methods: {
    getRowCount(): number {
      return new Set(this.ganttDataRd.getData().map((x) => x.username)).size;
    },
    async loadData() {
      this.ganttDataRd = await this.$reyDipApi.getRd(
        '/api/gantt/overview?userId=' + this.department
      );

      this.holidaysRd = await this.$reyDipApi.getRd('/api/gantt/holiday');

      if (this.ganttDataRd.hasData() && this.ganttDataRd.getData().length > 0) {
        this.createChart();
      }
    },
    createChart() {
      this.$nextTick(() => {
        am4core.useTheme(am4themes_animated);

        const colorSet = new am4core.ColorSet();
        colorSet.saturation = 0.4;
        const chart = am4core.create('chartdiv', am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0;

        chart.paddingRight = 30;
        chart.dateFormatter.inputDateFormat = 'dd.MM.YYYY';

        this.ganttDataRd.getData().forEach((gd) => {
          const tempDate = new Date(gd.end);
          const toDate = tempDate.setDate(tempDate.getDate() + 1);
          chart.data.push({
            name: gd.username,
            fromDate: gd.begin,
            toDate: toDate,
            color: gd.color,
            tooltip: gd.tooltip
          });
        });

        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.dateFormatter.dateFormat = 'w dd.MM.YYYY';
        dateAxis.baseInterval = { count: 1, timeUnit: 'day' };
        dateAxis.min = now().getTime();
        dateAxis.max = new Date(
          now().getFullYear() + 1,
          now().getMonth(),
          now().getDay()
        ).getTime();
        dateAxis.strictMinMax = true;

        const holidays = this.holidaysRd.getData();
        dateAxis.events.on('datavalidated', function (ev) {
          const axis = ev.target;

          holidays.forEach((holiday) => {
            const range = axis.axisRanges.create();

            const holidayDate = new Date(holiday);
            range.date = new Date(
              holidayDate.getFullYear(),
              holidayDate.getMonth(),
              holidayDate.getDate(),
              0,
              0,
              0
            );
            range.endDate = new Date(
              holidayDate.getFullYear(),
              holidayDate.getMonth(),
              holidayDate.getDate(),
              23,
              59,
              59
            );
            range.axisFill.fill = am4core.color('#ddd');
            range.axisFill.fillOpacity = 0.5;
            range.axisFill.strokeOpacity = 0;
          });
        });

        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'name';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 0;
        categoryAxis.renderer.inversed = true;

        const series1 = chart.series.push(new am4charts.ColumnSeries());
        series1.columns.template.width = am4core.percent(80);
        series1.columns.template.tooltipText = '{name}: KW {openDateX} - KW {dateX}\r\n{tooltip}';

        series1.dataFields.openDateX = 'fromDate';
        series1.dataFields.dateX = 'toDate';
        series1.dataFields.categoryY = 'name';
        series1.columns.template.propertyFields.fill = 'color';
        series1.columns.template.propertyFields.stroke = 'color';
        series1.columns.template.fillOpacity = 0.5;
        series1.columns.template.strokeOpacity = 0.5;

        dateAxis.axisRanges.template.grid.strokeWidth = 0;
        dateAxis.renderer.grid.template.strokeOpacity = 0.5;
        chart.scrollbarX = new am4core.Scrollbar();
      });
    }
  }
});
</script>
