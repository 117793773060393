import _Vue from 'vue';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { AxiosAuthHttp } from 'vue-adal';
import { AdalConfig, AdalTokenProvider } from '@/services/Adal';
import { RemoteData, UpdatableRemoteData } from 'rey-frontend-fp';
import IPagedResult from '@/models/IPagedResult';
import VueRouter from 'vue-router';

export function CreateAuthenticatedAxios(
  config: AdalConfig,
  router: VueRouter,
  errorHandler: (error: Error) => void
): AxiosInstance {
  const http: AxiosInstance = AxiosAuthHttp.createNewClient({
    // Required Params
    axios,
    resourceId: config.clientId,

    // Optional Params
    router: router,
    onTokenFailure: errorHandler
  });

  http.interceptors.response.use((response) => response, errorHandler);

  http.interceptors.request.use((request: AxiosRequestConfig) => {
    request.url = request.url ? encodeURI(request.url) : request.url;

    request.headers['Cache-Control'] = 'no-cache';

    // Get cached token for first calls when starting up
    // every routing action and 401 will call getToken() (which does acquireToken()) in vue-adal
    const token = AdalTokenProvider.getCachedToken(config.clientId);
    if (token) {
      request.headers.Authorization = `BEARER ${token}`;
    }
    return request;
  });

  http.getRd = <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<RemoteData<T, E>> => {
    return http
      .get(url, requestConfig)
      .then((response) => response.data)
      .then((data: T) => RemoteData.success<T, E>(data))
      .catch((error: E) => RemoteData.failure<T, E>(error));
  };

  http.getPagedRd = <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<RemoteData<T[], E>> => {
    return http
      .getRd<IPagedResult<T>, E>(url, requestConfig)
      .then((result) => result.map((x) => x.entities));
  };

  http.getUrd = <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<UpdatableRemoteData<T, E>> => {
    return http
      .get(url, requestConfig)
      .then((response) => response.data)
      .then((data: T) => UpdatableRemoteData.success<T, E>(data))
      .catch((error: E) => UpdatableRemoteData.failure<T, E>(error));
  };

  http.getPagedUrd = <T, E>(
    url: string,
    requestConfig?: AxiosRequestConfig
  ): Promise<UpdatableRemoteData<T[], E>> => {
    return http
      .getUrd<IPagedResult<T>, E>(url, requestConfig)
      .then((result) => result.map((x) => x.entities));
  };
  return http;
}

export function AuthenticatedAxios(
  Vue: typeof _Vue,
  config: {
    axios: AxiosInstance;
  }
): void {
  Vue.prototype.$reyDipApi = config.axios;
}
