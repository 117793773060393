import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import { EmployeeDto } from '../dtos/EmployeeDto';
import { AssignmentDto } from '../dtos/AssignmentDto';
import ResourcePlanning from '../ResourcePlanningModel';
import IDialog from './IDialog';
import ITextValuePair from '@/components/genericForm/ITextValuePair';

export default class AssignEmployeeDialog implements IDialog {
  public readonly resourcePlanning: ResourcePlanning;
  public availableEmployees: EmployeeDto[] = [] as EmployeeDto[];
  public model: IGenericFormModel = {
    options: {
      title: 'Mitarbeiter hinzufügen'
    },
    getData: () => this.getData(),
    entries: [
      {
        type: 'singleSelection',
        propertyName: 'name',
        label: {
          name: 'Name'
        },
        options: {
          items: () =>
            this.availableEmployees.map((e) => {
              return { value: e.name, text: e.name } as ITextValuePair;
            })
        }
      },
      {
        propertyName: 'beginDate',
        type: 'date',
        label: {
          name: 'Start'
        }
      },
      {
        propertyName: 'endDate',
        type: 'date',
        label: {
          name: 'Ende'
        }
      },
      {
        propertyName: 'comment',
        type: 'text',
        label: {
          name: 'Kommentar'
        },
        options: {
          multiline: true,
          width: '400'
        }
      },
      {
        propertyName: 'capacityPercentage',
        type: 'integer',
        label: {
          name: 'Auslastung'
        },
        options: {
          unit: '%',
          min: 0,
          max: 100
        }
      }
    ],
    onSave: (m) => this.onSave(m),
    onCancel: () => this.hide()
  };

  private employees: EmployeeDto[] = [] as EmployeeDto[];
  private currentAssignments: AssignmentDto[] = [] as AssignmentDto[];

  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }

  public async show(): Promise<void> {
    if (!this.resourcePlanning.ganttModel.selectedGanttTask) {
      return;
    }
    this.employees = await this.resourcePlanning.employeeService.getEmployees();
    this.currentAssignments = await this.resourcePlanning.employeeService.getAssignmentsBySubjectId(
      this.resourcePlanning.ganttModel.selectedGanttTask.id
    );
    this.availableEmployees = this.employees;
    this.resourcePlanning.dialogModel = this.model;
    this.resourcePlanning.showDialog = true;
  }

  getData(): Record<string, unknown> {
    const dataRecord = {} as Record<string, unknown>;
    dataRecord['name'] = '';
    dataRecord['beginDate'] = this.resourcePlanning.ganttModel.selectedGanttTask.start;
    dataRecord['endDate'] = this.resourcePlanning.ganttModel.selectedGanttTask.end;
    dataRecord['deliveryDate'] = this.resourcePlanning.ganttModel.selectedGanttTask.end;
    dataRecord['comment'] = '';
    dataRecord['capacityPercentage'] = 100;
    return dataRecord;
  }

  private async onSave(model: IGenericFormModel): Promise<void> {
    const formData = model.result as Record<string, unknown>;

    const assignment: AssignmentDto = {
      beginDate: new Date(formData.beginDate as string),
      endDate: new Date(formData.endDate as string),
      employeeId: this.employees.find((e) => e.name === formData.name)?.employeeId ?? 0,
      assignmentId: 0,
      subjectId: parseInt(this.resourcePlanning.ganttModel.selectedGanttTask.id ?? '0'),
      comment: formData.comment as string,
      pensum: formData.capacityPercentage as number,
      isInvalid: false
    };

    const addedAssignment: AssignmentDto =
      await this.resourcePlanning.employeeService.postAssignment(assignment);
    this.resourcePlanning.employeeService.addAssignmentToGannt(addedAssignment);
    this.hide();
    this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);
  }

  public hide(): void {
    this.resourcePlanning.showDialog = false;
  }
}
