<template>
  <li v-if="!model.hidden" class="nav-item" :class="{ dropdown: hasEntries }">
    <a
      v-if="hasEntries"
      class="nav-link dropdown-toggle"
      href="#"
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span v-if="hasMenuLeftIcon">
        <i v-if="isSpinning" class="fas fa-cog fa-spin"></i>
        <span v-if="!isSpinning">
          <em v-if="isSelectionFasIcon" :class="iconClasses" /><span v-if="!isSelectionFasIcon">{{
            model.command.icon
          }}</span>
        </span>
      </span>

      <span v-if="hasMenuName" :class="{ 'left-icon': hasLeftIcon, 'right-icon': hasRightIcon }">
        <span class="text-spinner-container">
          <span
            v-if="isSpinning && !hasMenuLeftIcon && !hasMenuRightIcon && !hasMenuPosition"
            class="text-spinner"
          >
            <div class="text-spinner-background">
              <i class="fas fa-cog fa-spin"></i>
            </div>
          </span>
        </span>
        <span>
          {{ model.command.name }}
        </span>
      </span>

      <span v-if="hasMenuRightIcon" class="right-icon">
        <i v-if="isSpinning" class="fas fa-cog fa-spin"></i>
        <span v-if="!isSpinning">
          <em v-if="isSelectionFasIcon" :class="iconClasses" /><span v-if="!isSelectionFasIcon">{{
            model.command.icon
          }}</span>
        </span>
      </span>
    </a>

    <div v-if="hasEntries" class="dropdown-menu" aria-labelledby="navbarDropdown">
      <div v-for="entry in dropDownEntries" :key="entry.id">
        <a
          v-if="isAction(entry)"
          :disabled="isDisabled()"
          class="dropdown-item"
          :class="{ disabled: isCommandDisabled(entry) }"
          href="#"
          @click="executeEntry(entry)"
          ><em v-if="hasLeftIcon(entry)" :class="entry.icon" /><span
            v-if="hasName(entry)"
            :class="{ 'left-icon': hasLeftIcon(entry), 'right-icon': hasRightIcon(entry) }"
          >
            {{ entry.name }} </span
          ><em v-if="hasRightIcon(entry)" :class="entry.icon"
        /></a>
        <hr v-if="isDivider(entry)" class="dropdown-divider" />
      </div>
    </div>

    <a
      v-if="!hasEntries"
      class="nav-link"
      :class="{ disabled: isCommandDisabled(model.command) }"
      href="#"
      @click="executeEntry(model.command)"
      ><em v-if="hasLeftIcon(model.command)" :class="model.command.icon" /><span
        v-if="hasName(model.command)"
        :class="{
          'left-icon': hasLeftIcon(model.command),
          'right-icon': hasRightIcon(model.command)
        }"
      >
        {{ model.command.name }} </span
      ><em v-if="hasRightIcon(model.command)" :class="model.command.icon"
    /></a>
  </li>
</template>

<script lang="ts">
// https://getbootstrap.com/docs/4.0/components/dropdowns/
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { IToolkitMenuModel } from './IToolkitMenuModel';
import { IToolkitCommand, ToolkitCommandIdType } from './IToolkitCommand';
import { ToolkitCommandResultType } from '../sharedModels/ICommand';
import { IToolkitDropdownDivider } from './IToolkitDropdownDivider';
import LockNode from '../sharedModels/LockNode';
import { ToolkitDropdownEntryList } from './ToolkitDropdownEntryList';
import { ToolkitDropdownEntry } from './ToolkitDropdownEntry';

@Component({})
export default class ToolkitMenu extends Vue {
  @Prop() model!: IToolkitMenuModel;
  @Prop() iconClass: string | undefined;
  @Prop() buttonClass: string | undefined;
  @Prop() containerClasses: string[] | undefined;

  public dropDownEntries: ToolkitDropdownEntryList | undefined = [];
  public selection: IToolkitCommand | undefined = {} as IToolkitCommand;

  private actionInProgress = false;
  public isSpinning = false;
  public iconClasses: string[] = [];

  public get theButtonClass(): string {
    return this.buttonClass ?? '';
  }

  public get theContainerClasses(): string[] {
    return this.containerClasses ?? [];
  }

  public mounted(): void {
    this.iconClasses = [this.model.command.icon ?? '', this.iconClass ?? ''];

    let entriesPromise = this.model.getEntries();
    if (entriesPromise != undefined) {
      entriesPromise.then((entries) => {
        this.dropDownEntries = entries;
      });
    }
  }

  public isCommandDisabled(command: IToolkitCommand): boolean {
    if (!command.disabled) {
      return false;
    }
    return command.disabled();
  }

  private getEntryById(
    entries: ToolkitDropdownEntryList,
    id: ToolkitCommandIdType | undefined
  ): IToolkitCommand | undefined {
    if (id == undefined) return undefined;
    for (let entry of entries) {
      const command: IToolkitCommand = entry as IToolkitCommand;
      if (command.id === id) {
        return entry as IToolkitCommand;
      }
    }
    return undefined;
  }

  public executeEntry(entry: IToolkitCommand): void {
    let group: LockNode | null = null;
    if (entry.group) {
      group = this.$toolkitModel.commandLockRoot.resolve(entry.group);
      if (group != null && group.isLocked) return;
    }
    if (group != null) group.lock();
    let ret: ToolkitCommandResultType = this.doExecuteEntry(entry, group);
    if (ret != null && ret.finally != null) {
      this.handleExecutionPromise(ret, group);
    } else {
      if (group != null) group.unlock();
    }
  }

  private handleExecutionPromise(ret: Promise<unknown>, group: LockNode | null) {
    this.actionInProgress = true;
    this.isSpinning = true;
    ret.finally(() => {
      this.actionInProgress = false;
      this.isSpinning = false;
      if (group != null) group.unlock();
    });
  }

  private doExecuteEntry(entry: IToolkitCommand, group: LockNode | null): ToolkitCommandResultType {
    let ret: ToolkitCommandResultType;
    try {
      if (entry && entry.execute) {
        ret = entry.execute();
      }
    } catch (error) {
      if (group != null) group.unlock();
    }
    return ret;
  }

  public getSelectionName(): string {
    return '';
  }

  public isAction(entry: ToolkitDropdownEntry): boolean {
    return !this.isDivider(entry);
  }

  public isDivider(entry: ToolkitDropdownEntry): boolean {
    const dividerEntry = entry as IToolkitDropdownDivider;
    return dividerEntry.divider != undefined;
  }

  public hasLeftIcon(entry: ToolkitDropdownEntry): boolean {
    if (!entry) return false;
    const command = entry as IToolkitCommand;
    return (
      (command.iconPosition === 'left' || command.iconPosition == undefined) && command.icon != null
    );
  }

  public hasRightIcon(entry: ToolkitDropdownEntry): boolean {
    if (!entry) return false;
    const command = entry as IToolkitCommand;
    return command.iconPosition === 'right' && command.icon != null;
  }

  public hasIconPosition(entry: ToolkitDropdownEntry): boolean {
    if (!entry) return false;
    const command = entry as IToolkitCommand;
    return command.iconPosition != null;
  }

  public hasName(entry: ToolkitDropdownEntry): boolean {
    if (!entry) return false;
    const command = entry as IToolkitCommand;
    return command.name != null;
  }

  public get hasEntries(): boolean {
    return this.dropDownEntries != undefined && this.dropDownEntries.length > 0;
  }

  public isDisabled(): boolean {
    return this.actionInProgress || this.isActionDisabled;
  }

  public get isActionDisabled(): boolean {
    return this.isCommandDisabled(this.model.command);
  }

  public get hasMenuLeftIcon(): boolean {
    return (
      this.model.command.iconPosition === 'left' ||
      (!this.hasMenuRightIcon && this.model.command.icon != undefined)
    );
  }

  public get hasMenuRightIcon(): boolean {
    return this.model.command.iconPosition === 'right';
  }

  public get hasMenuPosition(): boolean {
    return this.model.command.iconPosition != undefined;
  }

  public get isSelectionFasIcon(): boolean {
    return this.model.command.icon != undefined && this.model.command.icon.startsWith('fas ');
  }

  public get hasMenuName(): boolean {
    if (this.model.command.name) {
      return this.model.command.name != undefined;
    }
    return false;
  }
}
</script>
<style scoped lang="scss">
.left-icon {
  padding-left: 10px !important;
}
.right-icon {
  padding-right: 10px !important;
}

.left-spinner {
  padding-left: 8px;
  padding-right: 8px;
}

.right-spinner {
  padding-left: 8px;
  padding-right: 8px;
}

.text-spinner-container {
  position: absolute;
  text-align: center;
  width: 80%;
}

.text-spinner {
  position: absolute;
}

.text-spinner-background {
  //TODO mani: die korrekte farbe wählen bzw. diese klasse abschaffen
  background-color: gray;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
