import { IGenericWizardModel } from '@/components/genericForm/IGenericWizardModel';
import ITextValuePair from '@/components/genericForm/ITextValuePair';
import GanttDataDto from '@/models/GanttDataDto';
import GanttTaskDto from '../gantt/GanttTaskDto';
import ResourcePlanning from '../ResourcePlanningModel';
import IDialog from './IDialog';

interface TaskBucket {
  task?: GanttTaskDto;
}

export default class NewProjectWizard implements IDialog {
  public readonly resourcePlanning: ResourcePlanning;
  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }

  public model: IGenericWizardModel = {
    options: {
      title: 'New Project Wizard'
    },
    getData: () => this.createNewProject(),
    pages: [
      {
        name: 'Projekt',
        entries: [
          {
            propertyName: 'title',
            type: 'text',
            label: {
              name: 'Titel'
            },
            options: {
              width: '500'
            }
          },
          {
            propertyName: 'departmentId',
            type: 'singleSelection',
            label: {
              name: 'Projekt Owner'
            },
            options: {
              items: (): ITextValuePair[] =>
                this.resourcePlanning.departments.map((d) => {
                  return { value: d.departmentId, text: d.name } as ITextValuePair;
                }),
              width: '100%'
            }
          },
          {
            propertyName: 'start',
            type: 'date',
            label: {
              name: 'Start'
            }
          },
          {
            propertyName: 'end',
            type: 'date',
            label: {
              name: 'Ende'
            }
          }
        ],
        onNext: (): number | Promise<number> => 1,
        onCancel: (): void | Promise<void> => this.hide()
      },
      {
        name: 'Involvierte Abteilungen',
        entries: [
          {
            propertyName: 'hasHW',
            type: 'boolean',
            label: {
              name: 'Hardware Engineering'
            }
          },
          {
            propertyName: 'hasSW',
            type: 'boolean',
            label: {
              name: 'Software Engineering'
            }
          },
          {
            propertyName: 'hasWE',
            type: 'boolean',
            label: {
              name: 'Werkstatt'
            }
          },
          {
            propertyName: 'hasIBS',
            type: 'boolean',
            label: {
              name: 'Inbetriebsetzung'
            }
          },
          {
            propertyName: 'hasSER',
            type: 'boolean',
            label: {
              name: 'Service'
            }
          },
          {
            propertyName: 'hasDIG',
            type: 'boolean',
            label: {
              name: 'Rey Digital'
            }
          }
        ],
        onPrevious: (): number | Promise<number> => 0,
        onSave: (m): void | Promise<void> => this.onSave(m),
        onCancel: (): void | Promise<void> => this.hide()
      }
    ]
  };

  private createNewProject(): Record<string, unknown> {
    const newProjectRecord: Record<string, unknown> =
      this.resourcePlanning.subjectService.newPoject() as Record<string, unknown>;
    return newProjectRecord;
  }

  private async onSave(model: IGenericWizardModel): Promise<void> {
    const newProject = model.result as GanttTaskDto;
    const newRecord = model.result as Record<string, unknown>;
    this.resourcePlanning.subjectService.convertTimeStringToDate(newProject);

    const taskBucket: TaskBucket = {};

    let promise = this.resourcePlanning.subjectService
      .postTask(newProject)
      .then((ganttTask) => {
        taskBucket.task = ganttTask;
        this.resourcePlanning.ganttModel.ganttData?.tasks.push(ganttTask);
        this.hide();
      })
      .catch((error) => {
        // TODO mani: Fehlermeldung als Toast visualisieren!
        console.warn('nidi(error9b) Create Project failed!', error);
      });

    if (newRecord['hasHW'] === true) {
      promise = this.createActivity('HW', promise, taskBucket);
    }
    if (newRecord['hasSW'] === true) {
      promise = this.createActivity('SW', promise, taskBucket);
    }
    if (newRecord['hasWE'] === true) {
      promise = this.createActivity('WE', promise, taskBucket);
    }
    if (newRecord['hasIBS'] === true) {
      promise = this.createActivity('IBS', promise, taskBucket);
    }
    if (newRecord['hasSER'] === true) {
      promise = this.createActivity('SER', promise, taskBucket);
    }
    if (newRecord['hasDIG'] === true) {
      promise = this.createActivity('DIG', promise, taskBucket);
    }
    promise = promise.then(() => {
      this.resourcePlanning.ganttModel.eventRefereshGantt.notify(true);
    });

    return promise;
  }

  private createActivity(
    activityName: string,
    promise: Promise<void>,
    taskBucket: TaskBucket
  ): Promise<void> {
    let activity: GanttTaskDto;
    const myPromise = promise
      .then(() => {
        activity = {
          id: '0',
          title: activityName,
          start: taskBucket.task!.start,
          end: taskBucket.task!.end,
          subjectType: 3,
          progress: 0,
          parentId: taskBucket.task!.id
        };
      })
      .then(() => {
        this.resourcePlanning.subjectService
          .postTask(activity)
          .then((ganttTask) => {
            this.resourcePlanning.ganttModel.ganttData?.tasks.push(ganttTask);
            this.hide();
          })
          .catch((error) => {
            // TODO mani: Fehlermeldung als Toast visualisieren!
            console.warn('nidi(error9c) Create Project failed!', error);
          });
      });
    return myPromise;
  }

  public show(): void {
    this.resourcePlanning.wizardModel = this.model;
    this.resourcePlanning.showWizard = true;
  }

  public hide(): void {
    this.resourcePlanning.showWizard = false;
  }
}
