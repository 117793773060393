<template>
  <div>
    <gantt-chart :departmentId="managerId" />
    <content-card title="Ferienanträge freigeben" cardIcon="fas fa-file-signature">
      <template v-slot:body>
        <vacation-table
          ref="vacationDecisionsTable"
          pageSection="VacationDecisions"
          v-model="selected"
        >
          <template v-slot:slot1>
            <button
              type="button"
              v-if="isExecutive"
              class="btn btn-success mx-1"
              @click="accept()"
              :disabled="selected === undefined || selected.status !== 0"
            >
              <em class="fas fa-check" />&nbsp;Akzeptieren
            </button>
          </template>
          <template v-slot:slot2>
            <button
              type="button"
              v-if="isExecutive"
              class="btn btn-danger mx-1"
              @click="decline()"
              :disabled="selected === undefined || selected.status !== 0"
            >
              <em class="fas fa-times" />&nbsp;Ablehnen
            </button>
          </template>
        </vacation-table>
      </template>
    </content-card>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { ContentCard } from 'rey-smartmes-ui';
import VacationTable from '@/components/VacationTable.vue';
import Vacation from '@/models/Vacation';
import Logger from '@/services/Logger';
import GanttChart from '@/components/GanttChart.vue';

export default Vue.extend({
  name: 'vacation-decisions',
  components: { ContentCard, VacationTable, GanttChart },
  data() {
    return { selected: undefined as Vacation | undefined };
  },
  mounted() {
    if (this.$router.currentRoute.query.selectedId !== undefined) {
      this.$reyDipApi
        .get('/api/vacationdecisions/' + this.$router.currentRoute.query.selectedId)
        .then((res) => {
          this.selected = res.data;
        });
    }
  },
  computed: {
    managerId() {
      return this.isAuthenticated.profile.oid;
    },
    isExecutive(): boolean {
      return (
        this.isAuthenticated.profile.roles !== undefined &&
        this.isAuthenticated.profile.roles.length === 1 &&
        this.isAuthenticated.profile.roles[0] === 'Executive'
      );
    }
  },
  methods: {
    accept() {
      this.$reyDipApi
        .post('/api/vacationdecisions/accept', this.selected)
        .then(() => this.reloadData())
        .catch((e: Error) => Logger.error(e));
    },
    decline() {
      if (this.selected == undefined || !this.selected.remarkManager) {
        alert(
          'Bitte Begründung für die Ablehnung in die Spalte "Bemerkung Vorgesetzter" eintragen!'
        );
      }

      this.$reyDipApi
        .post('/api/vacationdecisions/decline', this.selected)
        .then(() => this.reloadData())
        .catch((e: Error) => Logger.error(e));
    },
    reloadData() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.vacationDecisionsTable as any).$refs.smartTable.$_SmartTable_loadElements();
      this.selected = undefined;
    }
  }
});
</script>
