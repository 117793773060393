import { IGenericFormModel } from '@/components/genericForm/IGenericFormModel';
import GanttTaskDto from '../gantt/GanttTaskDto';
import ResourcePlanning from '../ResourcePlanningModel';
import IDialog from './IDialog';

export default class EditProjectDialog implements IDialog {
  public readonly resourcePlanning: ResourcePlanning;

  public model: IGenericFormModel = {
    options: {
      title: 'Projekt bearbeiten'
    },
    getData: () => this.getData(),
    entries: [
      {
        propertyName: 'title',
        type: 'text',
        label: {
          name: 'Titel'
        },
        options: {
          width: '500'
        }
      },
      {
        propertyName: 'start',
        type: 'date',
        label: {
          name: 'Start'
        }
      },
      {
        propertyName: 'end',
        type: 'date',
        label: {
          name: 'Ende'
        }
      }
    ],
    onSave: (m) => this.onSave(m),
    onCancel: () => this.hide()
  };

  constructor(resourcePlanning: ResourcePlanning) {
    this.resourcePlanning = resourcePlanning;
  }

  private getData(): Promise<Record<string, unknown>> | undefined {
    return this.resourcePlanning.ganttModel.getSelectedGanttTask();
  }

  private onSave(model: IGenericFormModel): void | Promise<void> {
    const update = model.result as GanttTaskDto;
    this.resourcePlanning.subjectService.convertTimeStringToDate(update);
    this.resourcePlanning.ganttModel.eventUpdateGanttTask.notify({ task: update });
    this.hide();
  }

  public show(): void {
    if (!this.resourcePlanning.ganttModel.selectedGanttTask) {
      return;
    }
    this.resourcePlanning.dialogModel = this.model;
    this.resourcePlanning.showDialog = true;
  }

  public hide(): void {
    this.resourcePlanning.showDialog = false;
  }
}
