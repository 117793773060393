<template>
  <div>
    <gantt-chart v-if="department !== ''" :departmentId="department" />
    <content-card title="Meine Anträge" cardIcon="fas fa-file-medical">
      <template v-slot:body>
        <vacation-table
          ref="vacationRequestTable"
          pageSection="VacationsOverview"
          v-model="selectedRequest"
        >
          <template v-slot:slot1>
            <button
              type="button"
              class="btn btn-primary mx-1"
              @click="$refs.vacationRequestTable.$refs.smartTable.startAdding()"
            >
              <em class="fas fa-plus" />&nbsp;Hinzufügen
            </button>
          </template>
          <template v-slot:slot2>
            <button
              type="button"
              class="btn btn-danger mx-1"
              @click="deleteObjectInOverview"
              :disabled="selectedRequest === undefined"
            >
              <em class="fas fa-trash" />&nbsp;Löschen
            </button>
          </template>
        </vacation-table>
      </template>
    </content-card>
    <content-card title="Meine Ferien" cardIcon="fas fa-file-medical">
      <template v-slot:body>
        <vacation-table
          ref="vacationTableRef"
          pageSection="ApprovedVacations"
          @rowupdated="reload"
          v-model="selected"
          ><template v-slot:slot2>
            <button
              type="button"
              class="btn btn-danger mx-1"
              @click="deleteObject"
              :disabled="selected === undefined"
            >
              <em class="fas fa-trash" />&nbsp;Löschen
            </button>
          </template></vacation-table
        >
      </template>
    </content-card>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { ContentCard } from 'rey-smartmes-ui';
import VacationTable from '@/components/VacationTable.vue';
import Vacation from '@/models/Vacation';
import GanttChart from '@/components/GanttChart.vue';

export default Vue.extend({
  name: 'vacation-planning',
  components: { ContentCard, VacationTable, GanttChart },
  data() {
    return {
      selected: undefined as Vacation | undefined,
      selectedRequest: undefined as Vacation | undefined,
      department: '' as string
    };
  },
  async created() {
    const requestResult = await this.$reyDipApi.get(
      '/api/gantt/manager?username=' + this.isAuthenticated.userName
    );

    const managerId = requestResult.data;
    this.department =
      managerId === '066bf939-d667-458a-9352-a5b318b7f04b' ||
      managerId === '76026029-00f6-453e-9d25-dc54d5f7381c'
        ? 'vorgesetzte'
        : managerId;
  },
  methods: {
    reload() {
      this.selected = undefined;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.vacationRequestTable as any).$refs.smartTable.$_SmartTable_loadElements();
    },
    async deleteObjectInOverview() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vacationTableRef: any = this.$refs.vacationRequestTable;
      await vacationTableRef.$refs.smartTable.deleteSelected();
      this.selectedRequest = undefined;
    },
    async deleteObject() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vacationTableRef: any = this.$refs.vacationTableRef;
      await vacationTableRef.$refs.smartTable.deleteSelected();
      this.selected = undefined;
    }
  }
});
</script>
