<template>
  <div>
    <GenericFormDialog :visible="showTaskDialog" :model="taskDialogModel"></GenericFormDialog>

    <div v-if="!initialized">loading...</div>
    <div v-if="initialized">
      <DxGantt
        ref="resourcePlanningGantt"
        :task-list-width="200"
        :on-custom-command="onCustomCommandClick"
        :show-dependencies="false"
        scale-type="weeks"
        @task-updating="onTaskUpdating"
        @task-edit-dialog-showing="onTaskEditDialogShowing"
        @selection-changed="onSelectionChanged"
        @content-ready="onGanttReady"
      >
        <DxStripLine :start="currentTime" title="Current Time" css-class="current-time" />
        <DxTasks :data-source="data.tasks" />
        <DxDependencies :data-source="data.dependencies" />
        <DxResources :data-source="data.resources" />
        <DxResourceAssignments :data-source="data.resourceAssignments" />

        <DxContextMenu :items="contextMenuItems" />

        <DxToolbar>
          <DxItem name="collapseAll" />
          <DxItem name="expandAll" />
          <DxItem name="separator" />
          <DxItem name="zoomIn" />
          <DxItem name="zoomOut" />
        </DxToolbar>

        <DxEditing :enabled="true" />

        <DxValidation :auto-update-parent-tasks="false" />

        <DxColumn :width="200" data-field="title" caption="Subject" />
      </DxGantt>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ResourcePlanningGanttDto } from '@/models/resourcePlanning/gantt/ResourcePlanningGanttDto';
import Gantt, { ContextMenuItem } from 'devextreme/ui/gantt';
import {
  DxGantt,
  DxEditing,
  DxTasks,
  DxDependencies,
  DxResources,
  DxResourceAssignments,
  DxColumn,
  DxValidation,
  DxToolbar,
  DxItem,
  DxContextMenu,
  DxFormat,
  DxStripLine
} from 'devextreme-vue/gantt';
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';

import { IGenericFormModel } from '../../components/genericForm/IGenericFormModel';
import GenericForm from '../../components/genericForm/GenericForm.vue';
import GenericFormDialog from '@/components/genericForm/GenericFormDialog.vue';
import ModelRoot from '@/models/resourcePlanning/ModelRoot';
import ResourcePlanningModel from '@/models/resourcePlanning/ResourcePlanningModel';
import GanttTaskDto from '@/models/resourcePlanning/gantt/GanttTaskDto';
import clone from '@/models/resourcePlanning/util/Clone';
import {
  mapGanttTaskToAssignment,
  mapGanttTaskToSubject
} from '@/models/resourcePlanning/util/Mapper';
import { SubjectDto } from '@/models/resourcePlanning/dtos/SubjectDto';
import { UpdateAssignmentDto } from '@/models/resourcePlanning/dtos/UpdateAssignmentDto';
import { UpdateSubjectDto } from '@/models/resourcePlanning/dtos/UpdateSubjectDto';
import { AssignmentDto } from '@/models/resourcePlanning/dtos/AssignmentDto';

@Component({
  components: {
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxColumn,
    DxFormat,
    DxEditing,
    DxValidation,
    DxToolbar,
    DxItem,
    DxContextMenu,
    DxPopup,
    DxToolbarItem,
    DxPosition,
    DxStripLine,
    GenericFormDialog,
    GenericForm
  }
})
export default class ResourcePlanningGantt extends Vue {
  @Prop() data!: ResourcePlanningGanttDto;

  public model: ModelRoot = this.$model;
  public resourcePlanning: ResourcePlanningModel = this.$model.resourcePlanning;
  public showTaskDialog = false;
  public taskDialogModel: IGenericFormModel = {} as IGenericFormModel;
  public initialized = false;
  public currentTime = new Date();

  public onGanttReady(event: { component: { scrollToDate: any } }) {
    setTimeout(() => {
      const startTimeForGantt = this.subtractDays(this.currentTime, 21);
      event.component.scrollToDate(startTimeForGantt);
    }, 100);
  }

  private subtractDays(date: any, days: number) {
    const dayInMs = 86400000;
    return new Date(date - dayInMs * days);
  }

  public created() {
    setTimeout(() => {
      const ganttRef: any = this.$refs['resourcePlanningGantt'];
      if (ganttRef) {
        const ganttInstance: Gantt = ganttRef.instance;
        ganttInstance.collapseAll();
      }
    }, 1000);
  }

  public async mounted() {
    await this.resourcePlanning.ganttModel.loadGanttData();
    await this.resourcePlanning.loadEmployees();

    await this.resourcePlanning.loadDepartments().catch((e) => console.error(e));

    this.initialized = true;
  }

  public get contextMenuItems(): Array<ContextMenuItem | string> {
    if (this.isProjectSelected) {
      return [
        // 'addTask',
        // 'taskdetails',
        // 'deleteTask',
        {
          name: 'EditProject',
          text: 'Projekt editieren',
          icon: 'edit'
        },
        {
          name: 'DeleteProject',
          text: 'Projekt löschen',
          icon: 'trash'
        }
      ];
    } else if (this.isActivitySelected) {
      return [
        {
          name: 'EditActivity',
          text: 'Task editieren',
          icon: 'edit'
        },
        {
          name: 'DeleteActivity',
          text: 'Task löschen',
          icon: 'trash'
        },
        {
          name: 'AssignEmployee',
          text: 'Teammitglied zuweisen',
          icon: 'card'
        }
      ];
    } else if (this.isAssignmentSelected) {
      return [
        {
          name: 'DeleteAssignment',
          text: 'Buchung löschen',
          icon: 'trash'
        }
      ];
    } else {
      return [];
    }
  }

  public get isProjectSelected(): boolean {
    return (
      this.resourcePlanning.ganttModel.selectedGanttTask.subjectType === 1 ||
      this.resourcePlanning.ganttModel.selectedGanttTask.subjectType === 2
    );
  }

  public get isActivitySelected(): boolean {
    return this.resourcePlanning.ganttModel.selectedGanttTask.subjectType === 3;
  }

  public get isAssignmentSelected(): boolean {
    return this.resourcePlanning.ganttModel.selectedGanttTask.subjectType === 1000;
  }

  public onCustomCommandClick(e: any): void {
    if (e.name === 'EditProject') {
      this.resourcePlanning.dialogEditProject.show();
    } else if (e.name === 'DeleteProject') {
      this.resourcePlanning.dialogDeleteProject.show();
    } else if (e.name === 'EditActivity') {
      this.resourcePlanning.dialogEditActivity.show();
    } else if (e.name === 'DeleteActivity') {
      this.resourcePlanning.dialogDeleteActivity.show();
    } else if (e.name === 'AssignEmployee') {
      this.resourcePlanning.dialogAssignEmployee.show();
    } else if (e.name === 'DeleteAssignment') {
      this.resourcePlanning.dialogDeleteAssignment.show();
    }
  }

  public onTaskEditDialogShowing(e: any): void {
    e.cancel = true;
    let taskKey: string = e.key;
    let ganttTask: GanttTaskDto = this.resourcePlanning.ganttModel.getGanttDataTask(
      taskKey
    ) as GanttTaskDto;
    if (!ganttTask) {
      return;
    }

    switch (ganttTask.subjectType) {
      case 1:
      case 2: {
        this.resourcePlanning.dialogEditProject.show();
        break;
      }
      case 3: {
        this.resourcePlanning.dialogEditActivity.show();
        break;
      }
    }
  }

  public onSelectionChanged(e: any): void {
    const taskId = e.selectedRowKey;

    this.resourcePlanning.ganttModel.setGanttDataSelection(
      this.resourcePlanning.ganttModel.getGanttDataTask(taskId)
    );
  }

  public onTaskUpdating(event: any): void {
    if (event.newValues == undefined) {
      return;
    }

    const originGanttTask: GanttTaskDto = {
      ...this.resourcePlanning.ganttModel.getGanttDataTask(event.key)
    } as GanttTaskDto;
    const updateGanttTask = { ...originGanttTask } as GanttTaskDto;
    Object.entries(event.newValues).forEach(([key, value]) => {
      if (value !== undefined) {
        updateGanttTask[key] = value;
      }
    });

    switch (originGanttTask.subjectType) {
      case 1000: {
        // Assignment
        this.getAssignmentUpdate(updateGanttTask).then((updateRequest) => {
          this.resourcePlanning.employeeService.saveAssignment(updateRequest).catch((error) => {
            console.error(
              `nidi(error 10) Write assignment ${updateGanttTask.id} to backend failed!`,
              error
            );
            this.resourcePlanning.ganttModel.setGanttDataTask(originGanttTask as GanttTaskDto);
          });
        });
        break;
      }
      case 1: {
        // Project
        this.getProjectUpdate(updateGanttTask).then((updateRequest) => {
          this.resourcePlanning.subjectService.saveSubject(updateRequest).catch((error) => {
            console.error(
              `nidi(error 11) Write project ${updateGanttTask.id} to backend failed!`,
              error
            );
            this.resourcePlanning.ganttModel.setGanttDataTask(originGanttTask as GanttTaskDto);
          });
        });
        break;
      }
      case 2: {
        // Subroject
        this.getSubprojectUpdate(updateGanttTask).then((subproject) => {
          this.resourcePlanning.subjectService
            .saveSubject({
              origin: this.resourcePlanning.selectedSubproject,
              update: subproject
            })
            .catch((error) => {
              console.error(
                `nidi(error 12) Write subproject ${updateGanttTask.id} to backend failed!`,
                error
              );
              this.resourcePlanning.ganttModel.setGanttDataTask(originGanttTask as GanttTaskDto);
            });
        });
        break;
      }
      case 3: {
        // Activity
        this.getActivityUpdate(updateGanttTask).then((activity) => {
          this.resourcePlanning.subjectService.saveSubject(activity).catch((error) => {
            console.error(
              `nidi(error 13) Write activity ${updateGanttTask.id} to backend failed!`,
              error
            );
            this.resourcePlanning.ganttModel.setGanttDataTask(originGanttTask as GanttTaskDto);
          });
        });
        break;
      }
    }
  }

  private async getAssignmentUpdate(ganttTask: GanttTaskDto): Promise<UpdateAssignmentDto> {
    const assignmentId = this.resourcePlanning.employeeService.getAssignmentId(ganttTask);
    if (
      this.resourcePlanning.hasSelectedAssignment &&
      this.resourcePlanning.selectedAssignment.projectId === assignmentId
    ) {
      const selectedAssignment = this.resourcePlanning.selectedAssignment;
      const selectedAssignmentClone = clone(selectedAssignment);
      mapGanttTaskToAssignment(ganttTask, selectedAssignmentClone);

      return {
        origin: selectedAssignment,
        update: selectedAssignmentClone
      };
    }

    const originAssignment: AssignmentDto =
      await this.resourcePlanning.employeeService.getAssignment(assignmentId);
    const updateAssignment = clone(originAssignment);
    mapGanttTaskToAssignment(ganttTask, updateAssignment);
    return {
      origin: originAssignment,
      update: updateAssignment
    };
  }

  private async getProjectUpdate(ganttTask: GanttTaskDto): Promise<UpdateSubjectDto> {
    const subjectId = parseInt(ganttTask.id);
    if (
      this.resourcePlanning.hasSelectedProject &&
      this.resourcePlanning.selectedProject.projectId === subjectId
    ) {
      const originSubject = this.resourcePlanning.selectedProject;
      const updateSubject = clone(originSubject);
      mapGanttTaskToSubject(ganttTask, updateSubject);
      return Promise.resolve({
        origin: originSubject,
        update: updateSubject
      });
    }
    return this.resourcePlanning.subjectService.getSubject(subjectId).then((originSubject) => {
      const updateSubject = clone(originSubject);
      mapGanttTaskToSubject(ganttTask, updateSubject);
      return {
        origin: originSubject,
        update: updateSubject
      };
    });
  }

  private async getSubprojectUpdate(ganttTask: GanttTaskDto): Promise<SubjectDto> {
    const subjectId = parseInt(ganttTask.id);
    if (
      this.resourcePlanning.hasSelectedSubproject &&
      this.resourcePlanning.selectedSubproject.projectId === subjectId
    ) {
      const originSubject = this.resourcePlanning.selectedSubproject;
      const updateSubject = clone(originSubject);
      mapGanttTaskToSubject(ganttTask, updateSubject);
      return Promise.resolve(updateSubject);
    }
    return this.resourcePlanning.subjectService.getSubject(subjectId).then((originSubject) => {
      const updateSubject = clone(originSubject);
      mapGanttTaskToSubject(ganttTask, updateSubject);
      return updateSubject;
    });
  }

  private async getActivityUpdate(ganttTask: GanttTaskDto): Promise<UpdateSubjectDto> {
    const subjectId = parseInt(ganttTask.id);
    if (
      this.resourcePlanning.hasSelectedActivity &&
      this.resourcePlanning.selectedActivity.projectId === subjectId
    ) {
      const originSubject = this.resourcePlanning.selectedActivity;
      const updateSubject = clone(originSubject);
      mapGanttTaskToSubject(ganttTask, updateSubject);
      return Promise.resolve({
        origin: originSubject,
        update: updateSubject
      });
    }
    return this.resourcePlanning.subjectService.getSubject(subjectId).then((originSubject) => {
      const updateSubject = clone(originSubject);
      mapGanttTaskToSubject(ganttTask, updateSubject);
      return {
        origin: originSubject,
        update: updateSubject
      };
    });
  }
}
</script>
