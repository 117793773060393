<template>
  <div>
    <DxSelectBox
      :disabled="model.disabled"
      :search-enabled="true"
      :data-source="selectionItems"
      display-expr="text"
      search-mode="contains"
      search-expr="text"
      value-expr="value"
      :value.sync="content.value"
      @selection-changed="changeDropDownBoxValue($event)"
    >
    </DxSelectBox>
  </div>
</template>
<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { ISelectionEvent } from '../genericForm/ISelectionEvent';
import ISingleSelectionOptions from '../genericForm/ISingleSelectionOptions';
import { getString, getNumber } from '../genericForm/OptionsAccessors';
import { IValueContainer } from '../genericForm/ValueContainer';
import IGenericFormItem from '../genericForm/IGenericFormItem';
import IKeyValue from '../genericForm/ITextValuePair';

@Component({
  components: {
    DxSelectBox
  }
})
export default class DropDownBox extends Vue {
  @Prop()
  public model!: IGenericFormItem;

  @Prop()
  public content!: IValueContainer;

  public selectionItems: IKeyValue[] = [];

  private mounted(): void {
    this.updateProperties();
  }

  @Watch('model')
  public onModelChange(): void {
    this.updateProperties();
  }

  private updateProperties() {
    const options: ISingleSelectionOptions = this.model.options as ISingleSelectionOptions;
    if (options) {
      this.selectionItems = options.items();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public changeDropDownBoxValue(event: any): void {
    this.content.value = event.selectedItem?.value;
    this.emitSelectionChange({
      isSingleSelection: true,
      items: [this.content.value]
    });
  }

  @Emit('selectionChanged')
  public emitSelectionChange<T>(event: ISelectionEvent<T>): ISelectionEvent<T> {
    return event;
  }

  public getString(options: Record<string, unknown>, name: string, defaultValue: string): string {
    return getString(options, name, defaultValue);
  }

  public getNumber(options: Record<string, unknown>, name: string, defaultValue: number): number {
    return getNumber(options, name, defaultValue);
  }
}
</script>
