import Observer from './Observer';

export default class Subject<TEvent> {
  private observers: Observer<TEvent>[] = [];

  public addObserver(observer: Observer<TEvent>): void {
    this.observers.push(observer);
  }

  public removeObserver(observer: Observer<TEvent>): void {
    const removeIndex = this.observers.findIndex((obs) => {
      return observer === obs;
    });

    if (removeIndex !== -1) {
      this.observers = this.observers.slice(removeIndex, 1);
    }
  }

  public notify(data: TEvent | undefined): void {
    if (this.observers.length > 0) {
      this.observers.forEach((observer) => {
        try {
          observer.update(data);
        } catch (error) {
          console.warn('Uncatched exception in Observer!', error);
        }
      });
    }
  }
}
